import React, { useEffect, useState } from "react";
import classes from "./UpgradeAccount.module.css"
import AppHeader from "../Components/AppHeader";
import AppFooter from '../Components/AppFooter';
import { Link } from "react-router-dom/cjs/react-router-dom.min";


export default function UpgradeAccount(props) {
    const [subscription, setSubscription] = useState('')
    const [mobile, setMobile] = useState(false)
    const [comeFrom, setComeFrom] = useState(window.localStorage.getItem("comeFrom"))

    useEffect(() => {
        if (window.location.pathname.startsWith('/upgradeAccount')) {
            document.getElementById("body").style.overflowY = "hidden";  
        } else {
            document.getElementById("body").style.overflowY = "scroll";
        }
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/user/` + window.localStorage.getItem("id"),{method: 'GET' })
            .then(data => data.json())
            .then(data => {
                if(data.user.account == 0) {
                    setSubscription('basic')
                } else if(data.user.account == 2) {
                    setSubscription('book')
                } 
            })
    }, [])

    useEffect(() => {
        window.innerWidth < 900 ? setMobile(true) : setMobile(false)
    }, [])
    let id = window.localStorage.getItem("id")
    return(
        <>
        <AppHeader 
            currentPathName={props.location.pathname}
            appLanguage={props.language}
            mobile = {mobile}
            comeFrom={comeFrom}
        />
        
        <section className={classes.upgradeAccountContainer}>
            
            <div style={{maxWidth:'1400px', margin:'auto', paddingBottom:'50px'}} className={classes.upgradeAccount}>
            <Link
                className={'none'}
                to={`/profile/${id}`}
            >
                <i style={{position:'absolute', transform: 'rotateY(180deg)'}} class="icon icon-arrow"></i>
            </Link>
            

                <h1 className={classes.upgradeAccountTitle}>
                    {
                        props.language === "de"
                        ?
                        <>Ändern Sie Ihr Abonnement</>
                        :
                        <>Change your subscription</>
                    }
                </h1>
                <p className={classes.upgradeAccountText}>
                    {
                        props.language === "de"
                        ?
                        <>Sie haben ein {subscription}</>
                        :
                        <>You have a {subscription} account</>
                    }
                </p>
           
                <div className={classes.gridContainer}>
                    <div id="1" className={classes.gridItem}>Feature</div>
                    <div id="2" className={classes.gridItem}>
                        &nbsp; 
                        &nbsp; 
                        &nbsp; 
                        &nbsp; 
                        <svg width="22.1" height="20.8" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.29116 15.405C3.22449 15.405 3.15116 15.385 3.09783 15.3383C2.97783 15.2517 2.93116 15.105 2.97783 14.965L4.73783 9.55833L0.137826 6.21833C0.0178259 6.13166 -0.0288409 5.98499 0.0178257 5.84499C0.0644924 5.70499 0.191161 5.61166 0.337828 5.61166H6.02449L7.77782 0.205C7.86449 -0.0683333 8.32449 -0.0683333 8.41116 0.205L10.1645 5.61166H15.8512C15.9978 5.61166 16.1245 5.70499 16.1712 5.84499C16.2178 5.98499 16.1712 6.13166 16.0512 6.21833L11.4512 9.55833L13.2045 14.965C13.2512 15.105 13.2045 15.2517 13.0845 15.3383C12.9712 15.425 12.8112 15.425 12.6912 15.3383L8.09116 11.9983L3.49116 15.3383C3.43116 15.3783 3.3645 15.405 3.29783 15.405H3.29116ZM8.0845 11.2583C8.15116 11.2583 8.22449 11.2783 8.27782 11.325L12.2445 14.205L10.7312 9.54501C10.6845 9.40501 10.7312 9.25832 10.8512 9.17165L14.8178 6.29167H9.91783C9.77116 6.29167 9.64449 6.19833 9.59783 6.05833L8.0845 1.39834L6.57116 6.05833C6.52449 6.19833 6.39783 6.29167 6.25116 6.29167H1.35116L5.31783 9.17165C5.43783 9.25832 5.4845 9.40501 5.43783 9.54501L3.92449 14.205L7.89116 11.325C7.95116 11.285 8.01783 11.2583 8.0845 11.2583V11.2583Z" fill="#068B77"/>
                        </svg>
                        <span style={{marginLeft:'5px'}}>
                            
                            {
                                props.language === "de"
                                ?
                                <>Kostenlos</>
                                :
                                <>Free</>
                            }
                        </span>
                        &nbsp; 
                        &nbsp; 
                        &nbsp; 
                        &nbsp; 
                    </div>
                    <div id="3" className={classes.gridItem}>
                        <svg width="22.1" height="20.8" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.29116 15.405C3.22449 15.405 3.15116 15.385 3.09783 15.3383C2.97783 15.2517 2.93116 15.105 2.97783 14.965L4.73783 9.55833L0.137826 6.21833C0.0178259 6.13166 -0.0288409 5.98499 0.0178257 5.84499C0.0644924 5.70499 0.191161 5.61166 0.337828 5.61166H6.02449L7.77782 0.205C7.86449 -0.0683333 8.32449 -0.0683333 8.41116 0.205L10.1645 5.61166H15.8512C15.9978 5.61166 16.1245 5.70499 16.1712 5.84499C16.2178 5.98499 16.1712 6.13166 16.0512 6.21833L11.4512 9.55833L13.2045 14.965C13.2512 15.105 13.2045 15.2517 13.0845 15.3383C12.9712 15.425 12.8112 15.425 12.6912 15.3383L8.09116 11.9983L3.49116 15.3383C3.43116 15.3783 3.3645 15.405 3.29783 15.405H3.29116ZM8.0845 11.2583C8.15116 11.2583 8.22449 11.2783 8.27782 11.325L12.2445 14.205L10.7312 9.54501C10.6845 9.40501 10.7312 9.25832 10.8512 9.17165L14.8178 6.29167H9.91783C9.77116 6.29167 9.64449 6.19833 9.59783 6.05833L8.0845 1.39834L6.57116 6.05833C6.52449 6.19833 6.39783 6.29167 6.25116 6.29167H1.35116L5.31783 9.17165C5.43783 9.25832 5.4845 9.40501 5.43783 9.54501L3.92449 14.205L7.89116 11.325C7.95116 11.285 8.01783 11.2583 8.0845 11.2583V11.2583Z" fill="#E0F2F0"/>
                        </svg>
                        <span style={{marginLeft:'5px'}}>
                            
                            {
                                props.language === "de"
                                ?
                                <>Basis-Konto</>
                                :
                                <>Basic account</>
                            }
                        </span>
                    </div>  
                    <div id="4" className={classes.gridItem}>
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.666645 13.0848L0.666645 0.334205C0.666645 0.234834 0.71884 0.141677 0.803633 0.085781C0.888426 0.0298849 0.992774 0.0112483 1.09713 0.0423017L9.69385 2.64458C9.83082 2.68805 9.92212 2.80606 9.92212 2.9427L9.92212 15.6932C9.92212 15.7926 9.86994 15.8858 9.78515 15.9417C9.70036 15.9975 9.59601 16.0162 9.49165 15.9851L0.894937 13.3829C0.757964 13.3394 0.666645 13.2214 0.666645 13.0848ZM1.3189 0.756527L1.3189 12.855L9.26336 15.2585L9.26336 3.16008L1.3189 0.756527Z" fill="#E0F2F0"/>
                            <path d="M0.696444 0.310534C0.696444 0.136635 0.83994 -4.87822e-07 1.02257 -4.79839e-07L11.3477 3.17572e-08C12 -4.56897e-07 12 0.310534 12 0.931602L12 2.74364L12 4.96855L12 6.21068L12 13.0611C12 13.235 11.8565 13.3716 11.6739 13.3716C11.4912 13.3716 11.3477 13.235 11.3477 13.0611L11.3477 7.76335L11.3477 5.27908L11.3477 3.72641L11.3477 2.17374L11.3477 0.931602L11.3477 0.621068L1.02257 0.621068C0.83994 0.621068 0.696444 0.484433 0.696444 0.310534Z" fill="#E0F2F0"/>
                        </svg>
                        <span style={{marginLeft:'5px'}}>
                            
                            {
                                props.language === "de"
                                ?
                                <>Konto buchen</>
                                :
                                <>Book account</>
                            }
                        </span>
                    </div>
                    <div id="5" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Suche in deutsch, französisch, italienisch und englisch</>
                                    :
                                    <>Search in german, french, italian and english</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="6" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>  
                    <div id="7" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="8" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="9" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Siehe die Übersetzung auf französisch, italienisch und englisch</>
                                    :
                                    <>See the translation in french, italian and english</>
                                }
                            </li>
                        </ul>
                    </div>  
                    <div id="10" className={classes.gridItem}>
                        <span>{Yes()}</span>
                    </div>
                    <div id="11" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>  
                    </div>  
                    <div id="12" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="13" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Beschreibung der einzelnen Begriffe auf Deutsch verfügbar</>
                                    :
                                    <>Description of each term available in german</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="14" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>  
                    <div id="15" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{No()}</span>
                    </div>
                    <div id="16" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="17" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Begriff kopieren</>
                                    :
                                    <>Copy term</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="18" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>
                    <div id="19" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>  
                    <div id="20" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="21" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Begriff teilen</>
                                    :
                                    <>Share term</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="22" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>  
                    <div id="23" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{Yes()}</span> 
                    </div>
                    <div id="24" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="25" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Interne Links verfügbar (einfache Navigation zu referenzierten Begriffen)</>
                                    :
                                    <>Internal links available (easy navigation to referenced terms)</>
                                }
                            </li>
                        </ul>
                    </div>  
                    <div id="26" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>
                    <div id="27" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{No()}</span>
                    </div>  
                    <div id="28" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="29" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Externe Links verfügbar (Sprung zum rechtlichen Kontext eines Begriffs)</>
                                    :
                                    <>External links available (jump to the legal context of a term)</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="30" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>  
                    <div id="31" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{No()}</span>
                    </div>
                    <div id="32" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="33" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Keine Anzeigen</>
                                    :
                                    <>No ads</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="34" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>
                    <div id="35" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>  
                    <div id="36" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="37" className={classes.gridItem}>
                        <ul>
                            <li>
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>Zugangscode im Buch</>
                                    :
                                    <>Access code within the book</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="38" className={classes.gridItem}>
                        <span>{No()}</span>
                    </div>  
                    <div id="39" className={classes.gridItem}>
                        <span className={subscription === 'basic' ? `${classes.currentSubscription}` : ''}>
                            {Yes()}
                        </span> 
                    </div>
                    <div id="40" className={classes.gridItem}>
                        <span className={subscription === 'book' ? `${classes.currentSubscription}` : ''}>{Yes()}</span>
                    </div>
                    <div id="41" className={classes.gridItem}></div>  
                    <div id="42" className={classes.gridItem}>
                        <span>
                            
                            {
                                props.language === "de"
                                ?
                                <>*DEN FREIEN ZUGANG</>
                                :
                                <>*FREE ACCESS</>
                            }
                        </span>
                    </div>
                    <div id="43" className={classes.gridItem}>
                        {
                            subscription === 'basic'
                            ?
                            <>
                               
                                {
                                    props.language === "de"
                                    ?
                                    <><span>AKTUELLES <br/>-ABONNEMENT</span></>
                                    :
                                    <><span>CURRENT <br/> SUBSCRIPTION</span></>
                                }
                            </>
                            :
                            <></>
                        }
                          
                    </div>  
                    <div id="44" className={classes.gridItem}>
                        {
                            subscription === 'book'
                            ?
                            <>
                            {
                                props.language === "de"
                                ?
                                <><span>AKTUELLES <br/>-ABONNEMENT</span></>
                                :
                                <><span>CURRENT <br/> SUBSCRIPTION</span></>
                            }
                            </>
                            :
                            <>
                            <Link
                                className={`btn ochre ${classes.upgradeBtn}`}
                                to={'/upgradeBookAccount'}
                            >
                                
                                {
                                    props.language === "de"
                                    ?
                                    <>AKTUALISIERUNG</>
                                    :
                                    <>UPGRADE</>
                                }
                            </Link>
                            </>
                        }
                        
                    </div>
                </div>
            </div>
        </section>
        <AppFooter language={props.language} />
        </>
    )
}




const No = () => {
    return(
        <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 44C10.09 44 0 34.1342 0 22C0 9.86578 10.09 0 22.5 0C34.91 0 45 9.86578 45 22C45 34.1342 34.91 44 22.5 44ZM22.5 0.977778C10.65 0.977778 1 10.4133 1 22C1 33.5867 10.64 43.0222 22.5 43.0222C34.36 43.0222 44 33.5964 44 22C44 10.4036 34.36 0.977778 22.5 0.977778Z" fill="#F33757"/>
            <path d="M15.1099 30.2131C14.9799 30.2131 14.8499 30.1643 14.7599 30.0665C14.5599 29.8709 14.5599 29.5678 14.7599 29.3723L30.2299 14.246C30.4299 14.0505 30.7399 14.0505 30.9399 14.246C31.1399 14.4416 31.1399 14.7447 30.9399 14.9403L15.4699 30.0665C15.3699 30.1643 15.2399 30.2131 15.1199 30.2131H15.1099Z" fill="#F33757"/>
            <path d="M30.5799 30.2131C30.4499 30.2131 30.3199 30.1643 30.2299 30.0665L14.7599 14.9403C14.5599 14.7447 14.5599 14.4416 14.7599 14.246C14.9599 14.0505 15.2699 14.0505 15.4699 14.246L30.9399 29.3723C31.1399 29.5678 31.1399 29.8709 30.9399 30.0665C30.8399 30.1643 30.7099 30.2131 30.5899 30.2131H30.5799Z" fill="#F33757"/>
        </svg>
    )
}

const Yes = () => {
    return(
        <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 44C10.09 44 0 34.1342 0 22C0 9.86578 10.09 0 22.5 0C34.91 0 45 9.86578 45 22C45 34.1342 34.91 44 22.5 44ZM22.5 0.977778C10.65 0.977778 1 10.4133 1 22C1 33.5867 10.64 43.0222 22.5 43.0222C34.36 43.0222 44 33.5964 44 22C44 10.4036 34.36 0.977778 22.5 0.977778Z" fill="#8DD037"/>
            <path d="M18.1599 31.1129C18.0199 31.1129 17.8899 31.0542 17.7999 30.9564L11.1699 24.1511C10.9799 23.9556 10.9899 23.6427 11.1899 23.4569C11.3899 23.2711 11.7099 23.2809 11.8999 23.4764L18.1399 29.8809L32.4099 12.8676C32.5899 12.6622 32.8999 12.6231 33.1099 12.7991C33.3199 12.9653 33.3499 13.2782 33.1799 13.4836L18.5499 30.9173C18.4599 31.0249 18.3199 31.0933 18.1799 31.0933H18.1599V31.1129Z" fill="#8DD037"/>
        </svg>
    )
}