import React, { useEffect } from "react"

const SideAd = () => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", height: "90px" }}
      data-ad-client="ca-pub-2463161711663764"
      data-ad-slot="4381419861"
      data-ad-layout-key="-gz-2+20-23-2s"
      data-ad-format="fluid"
    ></ins>
  )
}

export default SideAd


{/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2463161711663764"
     crossorigin="anonymous"></script>
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-gz-2+20-23-2s"
     data-ad-client="ca-pub-2463161711663764"
     data-ad-slot="4381419861"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script> */}

