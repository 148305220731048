import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import classes from '../Pages/HomePage.module.css'

// import WebHeader from '../Components/WebHeader';
import UserFeedback from '../Components/UserFeedback'
import SvgPatternBackground from '../Components/svgPatternBackground';

import MyInstaller from '../Components/MyInstaller';


export default function HomePage({language}) {

    function createMarkup(str) {
        return {__html: str};
    }
    const [jwt, setJwt] = useState(window.localStorage.getItem("jwt"))
    const [pageContent, setPageContent] = useState({})
    const [isLoadingContent, setIsLoadingContent] = useState(false)
    const [currentTermCount, setCurrentTermCount] = useState()

    const [loadedImg, setLoadedImg] = useState(false);

    useEffect(() => {
        setIsLoadingContent(true)
        if (window.location.pathname.startsWith('/dictionary/') || window.location.pathname.startsWith('/openDictionary/')) {
            document.getElementById("body").style.overflowY = "hidden";  
        } else {
            document.getElementById("body").style.overflowY = "scroll";
        }
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?fields[0]=id&locale=${language}&sort[0]=text%3Aasc`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => setCurrentTermCount(data.meta.pagination.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")))

        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/home-page/?locale=${language}&populate=*`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => {
                setPageContent(data.data.attributes)
                setIsLoadingContent(false)
            })
    }, [language])

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    
    return (
        <>
            <MyInstaller language={language} componentContent={pageContent.download_app_button}>
                <header className="App-header">
                    <a
                        className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer"
                    >
                    </a> 
                </header>
            </MyInstaller>
            <section>
                <SvgPatternBackground scale={window.innerWidth < 600 ? '1.5' : '1'} number={1} height={'100vh'} color={'f9f7eb'} angle={45}/>
                
                <div className={classes.hero}>
                    <div >
                        <div style={{textAlign:'center'}} className='fade-center-top'>
                            {
                                isLoadingContent
                                ?
                                <>
                                <Skeleton 
                                style={{width:'300px', height:'75px',margin:'35px'}}
                                />
                                </>
                                :
                                <>
                                <h1 className={classes.title}>{pageContent.title_1}</h1>
                                </>
                            }
                        </div>
                        <div className={classes.introContent}>
                            <div className='fade-left'>
                                {loadedImg ? null : (
                                    <div
                                    style={{
                                        background: 'trasparent',
                                        height: '595.8px',
                                        width: '401.73px'
                                    }}
                                    />
                                )}
                                <img
                                    style={loadedImg ? {} : { display: 'none' }}
                                    src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.book_image_1}.data}.attributes}.url}`}
                                    onLoad={() => setLoadedImg(true)}
                                    className={classes.IntroImage}
                                />
                          
                            </div>
                            <div  className={`fade-right ${classes.heroText}`}>
                                <div style={{maxWidth:'700px'}} className={classes.heroTextContent}>
                                {
                                    isLoadingContent
                                    ?
                                    <>
                                    <Skeleton 
                                    style={{width:'250px', height:'35px',margin:'35px'}}
                                    />
                                    </>
                                    :
                                    <>
                                   <h3 className={`${classes.heroTextContentTitle}`}>{pageContent.title_2}</h3>
                                    </>
                                }
                                    
                                    <div  className={`body_text`}>
                                        <div dangerouslySetInnerHTML={createMarkup(pageContent.text_1)} />
                                    </div>
                                    <div className={classes.heroCallToAction}>
                                        {(() => {
                                            if (jwt !== null && window.localStorage.getItem("account") === "2") {
                                            return (
                                                <Link
                                                    to={`/dictionary/${window.localStorage.getItem("termId")}`}
                                                    className={'btn ochre'}
                                                >
                                                    {pageContent.go_back_app_button}
                                                </Link>
                                            )
                                            }else if (jwt !== null && window.localStorage.getItem("account") === "0") {
                                                return (
                                                    <Link
                                                        to={`/basicDictionary/${window.localStorage.getItem("termId")}`}
                                                        className={'btn ochre'}
                                                    >
                                                        {pageContent.go_back_app_button}
                                                    </Link>
                                                )
                                            } else if (jwt === null) {
                                                return (
                                                    <Link
                                                        to={`/login`}
                                                        className={'btn ochre'}
                                                        onClick={() => {topFunction()}}
                                                    >
                                                        {pageContent.button_text_1}
                                                    </Link>
                                                )
                                            } 
                                        })()}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div className={classes.bookSection}>
                    <div className={classes.bookSectionContent}>
                        <div>
                            <h3 className={classes.title}>{pageContent.title_3}</h3>
                        </div>
                        <div className={classes.bookContent}>
                            <div className={classes.bookFeature}>
                                <div className={`${classes.bookFeatureContent} reveal fade-bottom active`}>
                                    <div className={`${classes.bookFeatureLogo}`} >
                                        <i className='icon-benefits icon-search'></i>
                                    </div>
                                    <div className={`body_text ${classes.bookFeatureText}`}>
                                        {/* <div dangerouslySetInnerHTML={createMarkup(pageContent.text_2)} />     */}
                                        {
                                            language === 'de'
                                            ?
                                            <>
                                            {currentTermCount} deutsche Begriffe suchen.
                                            <br/>
                                            <Link
                                                style={{textDecoration:'underline'}}
                                                to={'/openDictionary/0'}
                                            >
                                                DAS WÖRTERBUCH AUSPROBIEREN
                                            </Link>
                                            </>
                                            :
                                            <>
                                            Search {currentTermCount} English terms.
                                            <br/>
                                            <Link
                                                style={{textDecoration:'underline'}}
                                                to={'/openDictionary/0'}
                                            >
                                                TRY THE DICTIONARY
                                            </Link>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className={`${classes.bookFeatureContent} reveal fade-bottom active`}>
                                    <div className={`${classes.bookFeatureLogo}`} >
                                        <i className='icon-benefits icon-search'></i>
                                    </div>
                                    <div className={`body_text ${classes.bookFeatureText}`}>
                                        <div dangerouslySetInnerHTML={createMarkup(pageContent.text_2)} />    
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'33%', display:'flex', alignItems:'center', justifyContent:'center'}} >
                                <img src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.book_image_2}.data}.attributes}.url}`} alt="" className={classes.bookImage} />
                            </div>
                            <div className={classes.bookFeature}>
                                <div className={`${classes.bookFeatureContent2} fade-bottom active`}>
                                    <div className={`body_text ${classes.bookFeatureText}`}>
                                        <div dangerouslySetInnerHTML={createMarkup(pageContent.text_3)} /> 
                                    </div>
                                    <div className={`${classes.bookFeatureLogo}`} >
                                        <i className='icon-benefits icon-addwatchlist-c'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.bookFeatureCallToAction}  >
                            <Link
                                to={`/registration`}
                                className={'btn ochre'}
                            >
                                {pageContent.button_text_knowmore}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section>   
                <div className={classes.authorSection}>
                    <div className={classes.authorSectionContent} >
                        <div className={classes.authorSectionText} > 
                            <div className={classes.authorSectionTextContent} >
                                <h3 className={classes.authorSectionTextTitle} >
                                    {pageContent.aldo_name}
                                </h3>
                                <div className='body_text'>
                                    <div dangerouslySetInnerHTML={createMarkup(pageContent.aldo_text)} />
                                </div>
                                <div className={classes.authorSectionTextCallToAction} >
                                    <Link
                                        to={`/registration`}
                                        className={'btn ochre'}
                                    >
                                        {pageContent.button_text_knowmore}
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className={classes.authorSectionImageContainer} >
                            <img className={classes.authorSectionImage} src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.aldo_portrait_image}.data}.attributes}.url}`} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <SvgPatternBackground scale={window.innerWidth < 600 ? '1.5' : '1'} number={3} color={'e5f0ef'} angle={'0'}  />                                    */}
            <UserFeedback title={pageContent.comments_title} />

            <section>
                {<SvgPatternBackground scale={window.innerWidth < 600 ? '1.5' : '1'} number={2} height={window.innerWidth < 991 && window.innerHeight > 667  ? '1434px' : window.innerHeight <= 667 ? '1434px' : '120vh'} color={'f5f7dd'} angle={-45}/>}
                <div className={classes.bookUserSection}>
                    <div className={classes.bookUserSectionContent} >
                        <div>
                            <h3 >{pageContent.digitalbook_title}</h3>
                        </div>
                        <div className={`${classes.bookUserText} subtitle text-center`}>
                            <div style={{maxWidth:'1200px'}} dangerouslySetInnerHTML={createMarkup(pageContent.digitalbook_text)} />
                        </div>
                        <div >
                        {/* {
                            jwt !== null
                            ?
                            <Link
                                to={`/dictionary/${window.localStorage.getItem("termId")}`}
                                className={'btn ochre'}
                            >
                                Go back to App
                            </Link>
                            :
                            <Link
                                to={`/registration`}
                                className={'btn ochre'}
                            >
                                Register
                            </Link>
                        }  */}
                        {(() => {
                            if (jwt !== null && window.localStorage.getItem("account") === "2") {
                            return (
                                <Link
                                    to={`/dictionary/${window.localStorage.getItem("termId")}`}
                                    className={'btn ochre'}
                                >
                                    {pageContent.go_back_app_button}
                                </Link>
                            )
                            }else if (jwt !== null && window.localStorage.getItem("account") === "0") {
                                return (
                                    <Link
                                        to={`/basicDictionary/${window.localStorage.getItem("termId")}`}
                                        className={'btn ochre'}
                                    >
                                        {pageContent.go_back_app_button}
                                    </Link>
                                )
                            } else if (jwt === null) {
                                return (
                                    <Link
                                        to={`/registration`}
                                        className={'btn ochre'}
                                    >
                                        {pageContent.register_button}
                                    </Link>
                                )
                            } 
                        })()}
                        </div>
                        <div className='reveal fade-bottom active'>
                            <img src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.book_image_3}.data}.attributes}.url}`} alt="" className={classes.bookUserImage} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const LookingGlassLogo  = () => {
    return(
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 60C13.4533 60 0 46.5467 0 30C0 13.4533 13.4533 0 30 0C46.5467 0 60 13.4533 60 30C60 46.5467 46.5467 60 30 60ZM30 1.33333C14.2 1.33333 1.33333 14.1867 1.33333 30C1.33333 45.8133 14.2 58.6667 30 58.6667C45.8 58.6667 58.6667 45.8133 58.6667 30C58.6667 14.1867 45.8133 1.33333 30 1.33333Z" fill="#4FB8A8"/>
            <path d="M28.9333 41.2908C21.9733 41.2908 16.32 35.6241 16.3066 28.6774C16.3066 21.7174 21.9733 16.0508 28.9333 16.0508C35.8933 16.0508 41.56 21.7174 41.56 28.6774C41.56 35.6374 35.8933 41.3041 28.9333 41.3041V41.2908ZM28.9333 17.3708C22.7066 17.3708 17.64 22.4375 17.64 28.6641C17.64 34.8908 22.7066 39.9441 28.9333 39.9441C35.16 39.9441 40.2266 34.8775 40.2266 28.6508C40.2266 22.4241 35.16 17.3575 28.9333 17.3575V17.3708Z" fill="#4FB8A8"/>
            <path d="M21.4671 28.6649H20.1338C20.1338 23.8116 24.0805 19.8516 28.9471 19.8516V21.1849C24.8271 21.1849 21.4671 24.5316 21.4671 28.6649Z" fill="#4FB8A8"/>
            <path d="M44.774 45.6796C44.6007 45.6796 44.4273 45.6129 44.3073 45.4796L36.8807 38.0529C36.614 37.7862 36.614 37.3729 36.8807 37.1063C37.1473 36.8396 37.5607 36.8396 37.8273 37.1063L45.254 44.5329C45.5207 44.7996 45.5207 45.2129 45.254 45.4796C45.1207 45.6129 44.9473 45.6796 44.7873 45.6796H44.774Z" fill="#4FB8A8"/>
        </svg>
    )
}

const WWWLogo  = () => {
    return(
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.5 59C13.2291 59 0 45.7709 0 29.5C0 13.2291 13.2291 0 29.5 0C45.7709 0 59 13.2291 59 29.5C59 45.7709 45.7709 59 29.5 59ZM29.5 1.31111C13.9633 1.31111 1.31111 13.9633 1.31111 29.5C1.31111 45.0367 13.9502 57.6889 29.5 57.6889C45.0498 57.6889 57.6889 45.0498 57.6889 29.5C57.6889 13.9502 45.0498 1.31111 29.5 1.31111Z" fill="#4FB8A8"/>
            <path d="M29.329 45.5037C24.0657 45.5037 21.3271 37.459 21.3271 29.4999C21.3271 21.5408 24.0657 13.4961 29.329 13.4961C34.5923 13.4961 37.3309 21.5408 37.3309 29.4999C37.3309 37.459 34.5923 45.5037 29.329 45.5037ZM29.329 14.4221C25.3507 14.4221 22.7184 21.8831 22.7184 29.4999C22.7184 37.1166 25.5441 44.5777 29.329 44.5777C33.114 44.5777 35.7165 37.1166 35.7165 29.4999C35.4124 18.7623 31.7605 14.4221 29.329 14.4221Z" fill="#4FB8A8"/>
            <path d="M29.5 45.8699C20.4662 45.8699 13.1284 38.5147 13.1111 29.4983C13.1111 20.4645 20.4662 13.1094 29.5 13.1094C38.5338 13.1094 45.8889 20.4645 45.8889 29.4983C45.8889 38.5321 38.5338 45.8872 29.5 45.8872V45.8699ZM29.5 14.8227C21.418 14.8227 14.8417 21.399 14.8417 29.481C14.8417 37.5629 21.418 44.1219 29.5 44.1219C37.5819 44.1219 44.1583 37.5456 44.1583 29.4637C44.1583 21.3817 37.5819 14.8054 29.5 14.8054V14.8227Z" fill="#4FB8A8"/>
            <path d="M43.9221 26.2213H15.0777C13.8769 26.2213 14.4211 25.9328 14.4211 25.5657C14.4211 25.1986 13.8769 24.9102 15.0777 24.9102L43.9221 24.9102C45.1229 24.9102 44.398 25.1986 44.398 25.5657C44.398 25.9328 45.1229 26.2213 43.9221 26.2213Z" fill="#4FB8A8"/>
            <path d="M43.0087 35.7162H15.7332C14.5181 35.7162 14.2413 35.4675 14.2413 35.1511C14.2413 34.8346 13.8625 34.5859 15.0777 34.5859H43.2666C44.4818 34.5859 44.5777 34.8346 44.5777 35.1511C44.5777 35.4675 44.2239 35.7162 43.0087 35.7162Z" fill="#4FB8A8"/>
        </svg>
    )
}


const LegalDocsLogo  = () => {
    return(
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.5 59C13.2291 59 0 45.7709 0 29.5C0 13.2291 13.2291 0 29.5 0C45.7709 0 59 13.2291 59 29.5C59 45.7709 45.7709 59 29.5 59ZM29.5 1.31111C13.9633 1.31111 1.31111 13.9633 1.31111 29.5C1.31111 45.0367 13.9502 57.6889 29.5 57.6889C45.0498 57.6889 57.6889 45.0498 57.6889 29.5C57.6889 13.9502 45.0498 1.31111 29.5 1.31111Z" fill="#4FB8A8"/>
            <path d="M35.1509 27.1002H21.3974C21.0303 27.1002 20.7418 26.8117 20.7418 26.4446C20.7418 26.0775 21.0303 25.7891 21.3974 25.7891H35.1509C35.518 25.7891 35.8065 26.0775 35.8065 26.4446C35.8065 26.8117 35.518 27.1002 35.1509 27.1002Z" fill="#4FB8A8"/>
            <path d="M32.2009 29.6978H21.3974C21.0303 29.6978 20.7418 29.4094 20.7418 29.0423C20.7418 28.6752 21.0303 28.3867 21.3974 28.3867H32.2009C32.568 28.3867 32.8565 28.6752 32.8565 29.0423C32.8565 29.4094 32.568 29.6978 32.2009 29.6978Z" fill="#4FB8A8"/>
            <path d="M27.3366 35.858H21.3842C21.0171 35.858 20.7286 35.5695 20.7286 35.2024C20.7286 34.8353 21.0171 34.5469 21.3842 34.5469H27.3366C27.7037 34.5469 27.9922 34.8353 27.9922 35.2024C27.9922 35.5695 27.7037 35.858 27.3366 35.858Z" fill="#4FB8A8"/>
            <path d="M27.3366 38.3775H21.3842C21.0171 38.3775 20.7286 38.0891 20.7286 37.722C20.7286 37.3549 21.0171 37.0664 21.3842 37.0664H27.3366C27.7037 37.0664 27.9922 37.3549 27.9922 37.722C27.9922 38.0891 27.7037 38.3775 27.3366 38.3775Z" fill="#4FB8A8"/>
            <path d="M32.3582 38.6514C30.4177 38.6514 28.8313 37.065 28.8313 35.1245C28.8313 33.1841 30.4177 31.5977 32.3582 31.5977C34.2986 31.5977 35.8851 33.1841 35.8851 35.1245C35.8851 37.065 34.2986 38.6514 32.3582 38.6514ZM32.3582 32.9219C31.1389 32.9219 30.1424 33.9183 30.1424 35.1377C30.1424 36.357 31.1389 37.3534 32.3582 37.3534C33.5775 37.3534 34.574 36.357 34.574 35.1377C34.574 33.9183 33.5775 32.9219 32.3582 32.9219Z" fill="#4FB8A8"/>
            <path d="M33.3152 38.7189C33.3152 38.7189 33.1973 38.7189 33.1317 38.6927L32.3581 38.4698L31.5845 38.6927C31.3223 38.7583 31.047 38.6796 30.8766 38.4698L30.3915 37.8405L29.631 37.5652C29.3819 37.4734 29.2115 37.2374 29.1984 36.9752L29.1721 36.1754L28.7263 35.5067C28.5821 35.2838 28.5821 34.9954 28.7263 34.7725L29.1721 34.1038L29.1984 33.3041C29.1984 33.0287 29.3819 32.8058 29.631 32.7141L30.3915 32.4387L30.8766 31.8094C31.047 31.5996 31.3223 31.5078 31.5845 31.5865L32.3581 31.8094L33.1317 31.5865C33.3808 31.5078 33.6693 31.5996 33.8397 31.8094L34.3248 32.4387L35.0853 32.7141C35.3344 32.8058 35.5048 33.0418 35.5179 33.3172L35.5441 34.1169L35.9899 34.7856C36.1342 35.0085 36.1342 35.2969 35.9899 35.5198L35.5441 36.1885L35.5179 36.9883C35.5179 37.2505 35.3344 37.4865 35.0853 37.5783L34.3379 37.8536L33.8528 38.4829C33.7217 38.6403 33.5381 38.7321 33.3283 38.7321L33.3152 38.7189ZM30.5095 36.4769L31.0208 36.6605C31.1388 36.6998 31.2437 36.7785 31.3224 36.8703L31.6502 37.3029L32.1746 37.1456C32.2926 37.1063 32.4237 37.1063 32.5417 37.1456L33.0661 37.3029L33.3939 36.8703C33.4726 36.7785 33.5775 36.6998 33.6955 36.6605L34.2068 36.4769V35.9263C34.233 35.8083 34.2724 35.6772 34.3379 35.5854L34.6395 35.1265L34.3379 34.6676C34.2724 34.5627 34.233 34.4447 34.2199 34.3267V33.7761L33.6955 33.5925C33.5775 33.5532 33.4726 33.4745 33.3939 33.3827L33.0661 32.9501L32.5417 33.1074C32.4237 33.1467 32.2926 33.1467 32.1746 33.1074L31.6502 32.9501L31.3224 33.3827C31.2437 33.4876 31.1388 33.5532 31.0208 33.5925L30.5095 33.7761V34.3267C30.4833 34.4447 30.4439 34.5758 30.3783 34.6676L30.0768 35.1265L30.3783 35.5854C30.4439 35.6903 30.4833 35.8083 30.4964 35.9263V36.4769H30.5095Z" fill="#4FB8A8"/>
            <path d="M34.5478 40.4092C34.3511 40.4092 34.1545 40.3174 34.0234 40.147L32.9351 38.6916C32.7122 38.4032 32.7778 37.9967 33.0662 37.7739C33.3546 37.551 33.7742 37.6165 33.984 37.905L35.0722 39.3603C35.2951 39.6487 35.2296 40.0552 34.9412 40.2781C34.8232 40.3698 34.6789 40.4092 34.5478 40.4092Z" fill="#4FB8A8"/>
            <path d="M30.1817 40.4092C30.0506 40.4092 29.9064 40.3698 29.7884 40.2781C29.4999 40.0552 29.4475 39.6487 29.6573 39.3603L30.7455 37.905C30.9553 37.6165 31.3749 37.551 31.6633 37.7739C31.9518 37.9967 32.0042 38.4032 31.7944 38.6916L30.7061 40.147C30.575 40.3174 30.3784 40.4092 30.1817 40.4092Z" fill="#4FB8A8"/>
            <path d="M25.5667 24.9122H17.7C17.3329 24.9122 17.0444 24.6237 17.0444 24.2566C17.0444 23.8895 17.3329 23.601 17.7 23.601H24.9111V16.3899C24.9111 16.0228 25.1995 15.7344 25.5667 15.7344C25.9338 15.7344 26.2222 16.0228 26.2222 16.3899V24.2566C26.2222 24.6237 25.9338 24.9122 25.5667 24.9122Z" fill="#4FB8A8"/>
            <path d="M39.1236 43.9626H17.7132C17.3461 43.9626 17.0576 43.6742 17.0576 43.307V24.2566C17.0576 24.0862 17.1231 23.9157 17.2542 23.7977L25.1209 15.9311C25.2389 15.8131 25.4094 15.7344 25.5798 15.7344H39.1236C39.4907 15.7344 39.7791 16.0228 39.7791 16.3899V43.307C39.7791 43.6742 39.4907 43.9626 39.1236 43.9626ZM18.3687 42.6515H38.468V17.0455H25.8551L18.3687 24.5319V42.6515Z" fill="#4FB8A8"/>
        </svg>
    )
}







