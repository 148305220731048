import React, { useEffect, useState } from 'react';

import SvgPatternBackground from '../Components/svgPatternBackground';

import classes from '../Pages/AboutBookPage.module.css'

export default function AboutBookPage({language}){

    function createMarkup(str) {
        return {__html: str};
    }

    const [pageContent, setPageContent] = useState({})

    useEffect(()=>{
        if (window.location.pathname.startsWith('/dictionary/') || window.location.pathname.startsWith('/openDictionary/')) {
            document.getElementById("body").style.overflowY = "hidden";  
        } else {
            document.getElementById("body").style.overflowY = "scroll";
        }
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/about-book/?locale=${language}&populate=*`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => setPageContent(data.data.attributes))
    }, [language])
    
    return(
        <>
            <section>
                <div className={classes.BookIntro}>
                    <div>
                        <div className='fade-center-top'>
                            <h1 className={classes.BookIntroTitle}>{pageContent.title_1}</h1>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}} className='fade-bottom'>
                            <img src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.book_image_1}.data}.attributes}.url}`} alt="" className={classes.BookIntroImage} />
                        </div>
                        <div className={`${classes.BookIntroCallToAction} fade-bottom`}>
                            <a 
                                href='https://www.schellenberg-consulting.com/' 
                                target='_blank'
                                rel="noopener noreferrer"
                                className='btn ochre'
                            >
                                {pageContent.preview_text_button}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
  
            <section>
                <div className={classes.BookDescription}>
                    <div className={classes.BookDescriptionContent}>
                        <div className={classes.BookDescriptionText}>
                            <div dangerouslySetInnerHTML={createMarkup(pageContent.text_1)} />
                        </div>
                        <div className={classes.BookInfo}>
                            <div className={classes.BookInfoContainer}>
                                <div className={classes.BookInfoElement}  >
                                    <div className='subtitle_bold primary_dark'>
                                        Language
                                    </div>
                                    <div className=''>
                                        <i className='icon-aboutBook icon-language'></i>
                                    </div>
                                    <div className='body_text'>
                                        German
                                    </div>
                                </div>
                                <div className={classes.BookInfoElement}  >
                                    <div className='subtitle_bold primary_dark'>
                                        ISBN-10
                                    </div>
                                    <div className=''>
                                        <i className='icon-aboutBook icon-barcode'></i>
                                    </div>
                                    <div className='body_text'>
                                        303909047X
                                    </div>
                                </div>
                                <div className={classes.BookInfoElement}  >
                                    <div className='subtitle_bold primary_dark '>
                                        ISBN-30
                                    </div>
                                    <div className=''>
                                        <i className='icon-aboutBook icon-barcode'></i>
                                    </div>
                                    <div className='body_text'>
                                        978-3039090471
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <SvgPatternBackground 
                    scale={window.innerWidth < 600 ? '1.5' : '1'} 
                    number={1} 
                    // height={'85vh'} 
                    height={window.innerWidth < 991 ? '700px' : '85vh'} 
                    // height={window.innerWidth < 991 && window.innerHeight < 667  ? '85vh' : window.innerHeight <= 667 ? '700px' : '700px'}
                    color={'f2f2db'} 
                    angle={45} 
                    backgroundColor={'#f6f9e5'}
                />
                <div className={classes.BookBuy}>
                    <div className={classes.BookBuyContent}>
                        <div>
                            <h3 className={classes.BookBuyTitle}>{pageContent.title_2}</h3>
                        </div>
                        <div className={classes.BookBuyText} >
                            <div dangerouslySetInnerHTML={createMarkup(pageContent.text_2)} />
                        </div>
                        <div >
                            <div className={classes.BookBuyEditorial}>
                                <img src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.versus_image}.data}.attributes}.url}`} alt="" className={classes.BookBuyEditorialImage} />
                                <div>
                                    <a className='link' href={`${pageContent.versus_link}`} target="__blank">
                                        Versus verlag
                                      
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
    
}