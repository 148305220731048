import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import classes from './Terms.module.css'

import AdComponent from '../Components/AdComponentTermsList';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'




export default function Terms({
    basicDictionary = false,
    openDictionary = false,
    termNotSelected,
    setTermNotSelected = f => f,
    scrolledCount = f => f,
    scroll,
    reloaded,
    currentSearch,
    newFilterSearch,
    expandCurrentSearch = f => f,
    termsMeta,
    setLoader = f => f,
    loaderLimit,
    loading,
    termNotFound,
    terms, 
    onClick2 = f => f, 
    currentTerm, 
    currentTermId,
    isLoading, 
    clipboard , 
    setClipboard = f => f, 
    toastVisible,
    setToastVisible = f => f, 
    setClipboardTitle = f => f, 
    setLink= f =>f, 
    setShareId = f => f, 
    mobile,
    setMobileMeaningStyle = f => f, 
    mobileMeaningStyle,
    setSkeletonWidth = f => f,
    searchLanguage,
    sendConsultedTermMetric = f => f,
    timeToFetch,
    pageContent
}) {

    function createMarkup(str) {
        return {__html: str};
    }
    useEffect(() => {
        // alert("Copied to clipboard.");
        navigator.clipboard.writeText(clipboard)
        
        setToastVisible(toastVisible)
        
        
    }, [clipboard, toastVisible, setToastVisible])

    // const handleClick = async () => {
    //     await navigator.clipboard.writeText(clipboard);
    //     // alert("Text copied!");
    //  };

    var count = 1;
    
    const ahhh = () => {
        
        if (loaderLimit === termsMeta.pagination.pageCount + 1) {
            setLoader(false);
        } else {
            setLoader(true);
        }

        // if () {}

        setTimeout(() => {
            fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?fields[0]=id&fields[1]=text&&fields[2]=locale&fields[3]=free&populate[synonyms][fields][0]=id&populate[synonyms][fields][1]=text&populate[synonyms][fields][4]=locale&populate[localizations][fields][0]=id&populate[localizations][fields][1]=text&populate[localizations][fields][4]=locale&populate[localizations][populate][synonyms][fields][0]=id&populate[localizations][populate][synonyms][fields][1]=text&populate[localizations][populate][synonyms][fields][4]=locale&locale=${searchLanguage}&sort[0]=text%3Aasc&filters[text][$startsWith]=${currentSearch}&pagination[page]=${scroll}`, {method: 'GET'})
            .then(response => 
                response.json()
            )
            .then(json => {
                expandCurrentSearch(json.data)
            })
            .then(() => setLoader(false))
        }, 1000);
            
    }

    useEffect(() => {
        var someTerms = document.getElementsByClassName('someTerm')
        
        if(reloaded && !termNotSelected) {
            Object.values(someTerms).forEach(element => {
                console.log(element.innerText.toLowerCase() === currentTerm.toLowerCase())
                if(element.innerText.toLowerCase() === currentTerm.toLowerCase()) {
                    document.getElementById(currentTerm).parentElement.parentElement.parentElement.scrollIntoView()
                }
                 
            });
        }

        

    }, [])


    // const blockInternalLinks = () => {
    //     if(openDictionary) {
    //         setTimeout(() => {
    //             let dangerouslySetInnerHTML = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("a")
    //             Object.values(dangerouslySetInnerHTML).map(a => {
    //                 a.classList.add('tooltipTop')
    //                 a.href="javascript:void(0)"
    //             })
    //             Object.values(document.getElementsByClassName('tooltipTop')).map(a => a.appendChild(document.createElement("span")) )
    //             let tooltipContainer = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("span")
    //             Object.values(tooltipContainer).map(a=>a.classList.add('tooltipTopText'))
    //             Object.values(tooltipContainer).map(a=>a.innerHTML =  `<a class="link" style="color:#B66A00 !important;cursor: pointer;" href="/registration">Register</a> to use link`)

    //         }, timeToFetch + 1000);
    //     }
    // }
      
    
    
    return(
        <>
            <div>
                <div>
                {
                    termNotFound
                    ?
                    <div className={classes.termNotFound}>
                        {pageContent.no_matches}
                    </div>
                    :
                    null
                }
                    <div 
                        onScroll={() => {
                            // console.log(document.getElementById("container_term").scrollTop*1.1)
                            // console.log(document.getElementById("container_term").scrollHeight - document.getElementById("container_term").clientHeight)
                            if(Math.floor( (document.getElementById("container_term").scrollTop)*1.1) > (document.getElementById("container_term").scrollHeight - document.getElementById("container_term").clientHeight)  && loading === false) {
                                scrolledCount(count);
                                console.log("SCROLL TO BOTTOM")
                                console.log(document.getElementById("container_term").scrollTop)
                                console.log(document.getElementById("container_term").scrollHeight - document.getElementById("container_term").clientHeight)
                                ahhh() 
                            }  
                            
                        }} 
                        id='container_term' 
                        className={classes.termsContainer}
                    >
                    
                        {terms.map((t) => (                             
                            <div className={classes.termBox} id={t.id} key={t.id}>
                               
                                {
                                    t.id % 5 === 0 && openDictionary
                                    ?
                                    <><AdComponent/></>
                                    :
                                    <></>
                                }
                                <div 
                                    style={{backgroundColor: t.attributes.text === currentTerm && currentTermId==t.id ? 'rgba(243,191,76,0.25)': 'white', width: mobile ?'100%':'100%', padding: mobile ?'5px':'15px'}} 
                                >
                                    <div>
                                        {
                                            isLoading
                                            ?
                                            <Skeleton height={10} width={70} />
                                            :
                                         
                                            <>
                                                <div className={classes.termTitleAndActions} >
                                                <div>
                                                    <Link
                                                        key={t.id} 
                                                        onClick={() =>{
                                                            // console.log(t.attributes.locale)
                                                            sendConsultedTermMetric(t.id)
                                                            onClick2(t.id); 
                                                            setLink([document.getElementById(t.attributes.text).href.replace('https://accounting-a-z.ch', ''), document.getElementById(t.attributes.text).innerText, t.attributes.locale]); 
                                                            setMobileMeaningStyle(true);
                                                            setTermNotSelected(false)
                                                            // if (t.attributes.free === true){
                                                            //     blockInternalLinks()
                                                            // }
                                                           
                                                        }}
                                                        // to={openDictionary ? `/openDictionary/${t.id}`: `/dictionary/${t.id}`}
                                                        to={openDictionary ? `/openDictionary/${t.id}`: basicDictionary ? `/basicDictionary/${t.id}` : `/dictionary/${t.id}` }
                                                        className={'link someTerm'}
                                                        id={t.attributes.text}    
                                                    >
                                                        {t.attributes.text}
                                                    </Link>
                                                    {
                                                        t.attributes.free === true && (openDictionary || basicDictionary)
                                                        ?
                                                        <span className={classes.openTerm}>book preview</span>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                                <div className={classes.termActionsContainer} >
                                                    <button 
                                                        disabled={openDictionary?true:false}
                                                        onClick={() => 
                                                            {  
                                                                setClipboard(document.getElementById(t.id).innerText.replace(/([.]\n)/gm,". ").replace(/(\n[a-z\u00C0-\u024F])/g, x => x.charAt(1)), () => {
                                                                    // navigator.clipboard.writeText(clipboard)
                                                                })

                                                                setToastVisible(true);
                                                                setTimeout(() => {
                                                                    setToastVisible(false)
                                                                }, 50000)
                                                                setClipboardTitle(document.getElementById(t.attributes.text).innerText)  
                                                                // navigator.clipboard.writeText(clipboard)
                                                            }
                                                        } 
                                                        className='none'
                                                    >
                                                        <i
                                                            className='tooltip icon icon-copy'
                                                            style={{color: openDictionary ? 'grey': '#B66A00', cursor: openDictionary ? 'not-allowed' : 'pointer'}}
                                                        >
                                                            {
                                                                openDictionary
                                                                ?
                                                                <>
                                                                <span  class="tooltiptext">
                                                                    <span dangerouslySetInnerHTML={createMarkup(pageContent.register_to_copy)}/>
                                                                </span>
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                            
                                                        </i>
                                                    </button>  
                                                    <button  
                                                        disabled={openDictionary?true:false}
                                                        onClick={() => {
                                                            setShareId(t.id, document.getElementById(t.id).innerText.replace(/([.]\n)/gm,". ").replace(/(\n[a-z\u00C0-\u024F])/g, x => x.charAt(1)))
                                                        }} 
                                                        className='none'
                                                    >
                                                        <i 
                                                            className='tooltip icon icon-share'
                                                            style={{color: openDictionary ? 'grey': '#B66A00', cursor: openDictionary?'not-allowed':'pointer'}}
                                                        >
                                                            {
                                                                openDictionary
                                                                ?
                                                                <>
                                                                <span  class="tooltiptext">
                                                                    <span dangerouslySetInnerHTML={createMarkup(pageContent.register_to_share)}/>
                                                                </span>
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                           
                                                        </i>
                                                    </button>
                                                </div>
                                                
                                                </div>   
                                                <div>
                                                    {
                                                        t.attributes.synonyms.data.length === 0
                                                        ?
                                                        <></>
                                                        :
                                                        <>
                                                            <span>
                                                                {
                                                                    t.attributes.synonyms.data.length === 1
                                                                    ?
                                                                    <span className={classes.synonymTitle}>{pageContent.Synonym}: </span>
                                                                    :
                                                                    <span className={classes.synonymTitle}>{pageContent.Synonyms}: </span>
                                                                }    
                                                            </span>
                                                            
                                                            {(() => {
                                                                if (t.attributes.synonyms.data.length === 1) {
                                                                return (
                                                                    <span className={classes.synonym}>{t.attributes.synonyms.data[0].attributes.text}</span>
                                                                )
                                                                } else if (t.attributes.synonyms.data.length === 2) {
                                                                return (
                                                                    <span  className={classes.synonym}>{t.attributes.synonyms.data[0].attributes.text}; {t.attributes.synonyms.data[1].attributes.text}</span>
                                                                )
                                                                } else if (t.attributes.synonyms.data.length === 3){
                                                                return (
                                                                    <span  className={classes.synonym}>{t.attributes.synonyms.data[0].attributes.text}; {t.attributes.synonyms.data[1].attributes.text}; {t.attributes.synonyms.data[2].attributes.text}</span>
                                                                )
                                                                } else if (t.attributes.synonyms.data.length === 4){
                                                                    return (
                                                                        <span  className={classes.synonym}>{t.attributes.synonyms.data[0].attributes.text}; {t.attributes.synonyms.data[1].attributes.text}; {t.attributes.synonyms.data[2].attributes.text}; {t.attributes.synonyms.data[3].attributes.text}</span>
                                                                    )
                                                                } else if (t.attributes.synonyms.data.length === 5){
                                                                    return (
                                                                        <span  className={classes.synonym}>{t.attributes.synonyms.data[0].attributes.text}; {t.attributes.synonyms.data[1].attributes.text}; {t.attributes.synonyms.data[2].attributes.text}; {t.attributes.synonyms.data[3].attributes.text}; {t.attributes.synonyms.data[4].attributes.text}</span>
                                                                    )
                                                                } else{
                                                                    return (
                                                                        <></>
                                                                    )
                                                                }
                                                            })()}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        }
                                        
                                        <div 
                                            id={t.id}
                                        >
                                        {
                                            searchLanguage === 'de' 
                                            ?
                                            
                                            <>
                                            {/* {console.log(t.attributes.localizations.data[0].attributes.locale)}
                                            {console.log(t.attributes)} */}
                                            {(() => {
                                                if(t.attributes.localizations.data[0] !== undefined ) {
                                                    if ( t.attributes.localizations.data[0].attributes.locale === 'en' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                        
                                                        return (
                                                            
                                                            <>
                                                            
                                                                {(() => {
                                                                    if (t.attributes.localizations.data[1].attributes.locale === 'it') {
                                                                    return (
                                                                        <>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>
                                                                                {t.attributes.localizations.data[2].attributes.text}
                                                                                {(() => {
                                                                                    if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 1) {
                                                                                        return (
                                                                                            <>;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text}</>
                                                                                        )
                                                                                    } else if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 2 ) {
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 3){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 4){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 5){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[4].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>
                                                                                {t.attributes.localizations.data[1].attributes.text}
                                                                                {(() => {
                                                                                    if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 1) {
                                                                                        return (
                                                                                            <>;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text}</>
                                                                                        )
                                                                                    } else if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 2 ) {
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 3){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 4){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 5){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[4].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[0].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                    } else if (t.attributes.localizations.data[2].attributes.locale === 'it') {
                                                                        return (
                                                                            <>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}. </div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[1].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[2].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[0].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[0].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                                
                                                            </>
                                                        )
                                                    } 
                                                    else if (t.attributes.localizations.data[0].attributes.locale === 'fr' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                        
                                                        return (
                                                            <>
                                                            
                                                                <div className={classes.termInLanguageContainer}  >
                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                    <div className={classes.termText}>
                                                                        {t.attributes.localizations.data[0].attributes.text}
                                                                        {(() => {
                                                                            if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 1) {
                                                                                return (
                                                                                    <>;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text}</>
                                                                                )
                                                                            } else if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 2 ) {
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp; 
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 3){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 4){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 5){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;<br/>
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[4].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                                {(() => {
                                                                    if (t.attributes.localizations.data[1].attributes.locale === 'en') {
                                                                    return (
                                                                        <>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                    } else if (t.attributes.localizations.data[2].attributes.locale === 'en') {
                                                                        return (
                                                                            <>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[1].attributes.text} 
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>
                                                                                {t.attributes.localizations.data[2].attributes.text}
                                                                                {(() => {
                                                                                    if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 1) {
                                                                                        return (
                                                                                            <>;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text}</>
                                                                                        )
                                                                                    } else if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 2 ) {
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 3){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 4){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 5){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[4].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                                
                                                            </>
                                                        )
                                                    } 
                                                    else if (t.attributes.localizations.data[0].attributes.locale === "it" && Object.keys(t.attributes.localizations.data).length === 3) {
                                                        
                                                        return (
                                                            <>
                                                            
                                                                {(() => {
                                                                    if (t.attributes.localizations.data[1].attributes.locale === 'en') {
                                                                    return (
                                                                        <>
                                                                        
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text} </div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text} </div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text} </div>
                                                                        </div>
                                                                        </>
                                                                    )
                                                                    } else if (t.attributes.localizations.data[2].attributes.locale === 'en') {
                                                                        return (
                                                                            <>
                                                                            
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text} </div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text} </div>
                                                                            </div>
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        return(<></>)
                                                                    }
                                                                })()}
                                                            
                                                            </>
                                                        )
                                                    }
                                            } else {
                                                
                                                    return(<></>)
                                                }
                                                
                                            })()}
                                            </>
                                            :
                                            console.log("PLEASE!!!")
                                        }
                                        {
                                            searchLanguage === 'fr' 
                                            ?
                                            <>
                                            {(() => {
                                                if(t.attributes.localizations.data[0] !== undefined) {
                                                    if (t.attributes.localizations.data[0].attributes.locale === 'de' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                        return (
                                                            <>
                                                                <div className={classes.termInLanguageContainer}  >
                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                    <div className={classes.termText}>
                                                                        {t.attributes.localizations.data[0].attributes.text}
                                                                        {(() => {
                                                                            if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 1) {
                                                                                return (
                                                                                    <>;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text}</>
                                                                                )
                                                                            } else if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 2 ) {
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 3){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 4){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 5){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[4].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                                {(() => {
                                                                    if (t.attributes.localizations.data[1].attributes.locale === 'it') {
                                                                    return (
                                                                        <>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>
                                                                                {t.attributes.localizations.data[1].attributes.text}
                                                                                {(() => {
                                                                                    if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 1) {
                                                                                        return (
                                                                                            <>;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text}</>
                                                                                        )
                                                                                    } else if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 2 ) {
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 3){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 4){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 5){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[4].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[2].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                    } else if (t.attributes.localizations.data[2].attributes.locale === 'it') {
                                                                        return (
                                                                            <>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                        </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                                
                                                            </>
                                                        )
                                                        } else if (t.attributes.localizations.data[0].attributes.locale === 'it' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                            return (
                                                                <>
                                                                    {(() => {
                                                                        if (t.attributes.localizations.data[1].attributes.locale === 'en') {
                                                                        return (
                                                                            <>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        } else if (t.attributes.localizations.data[2].attributes.locale === 'en') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                                </div>
                                                                                    <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                            </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    
                                                                </>
                                                            )
                                                        } else if (t.attributes.localizations.data[0].attributes.locale === 'en' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                            return (
                                                                <>
                                                                    
                                                                    {(() => {
                                                                        if (t.attributes.localizations.data[1].attributes.locale === 'de') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                                </>
                                                                            )
                                                                        } else if (t.attributes.localizations.data[2].attributes.locale === 'de') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                               
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                     <div className={classes.termInLanguageContainer}  >
                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                        <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                    </div>
                                                                   
                                                                </>
                                                            )
                                                        }
                                                }else {
                                                    return(<></>)
                                                }
                                            })()}
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            searchLanguage === 'it' 
                                            ?
                                            <>
                                            {(() => {
                                                if(t.attributes.localizations.data[0] !== undefined) {
                                                    if (t.attributes.localizations.data[0].attributes.locale === 'de' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                        return (
                                                            <>
                                                                <div className={classes.termInLanguageContainer}  >
                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                    <div className={classes.termText}>
                                                                        {t.attributes.localizations.data[0].attributes.text}
                                                                        {(() => {
                                                                            if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 1) {
                                                                                return (
                                                                                    <>;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text}</>
                                                                                )
                                                                            } else if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 2 ) {
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 3){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 4){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 5){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[4].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                                {(() => {
                                                                    if (t.attributes.localizations.data[1].attributes.locale === 'en') {
                                                                    return (
                                                                        <>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                        </div>
                                                                        </>
                                                                    )
                                                                    } else if (t.attributes.localizations.data[2].attributes.locale === 'en') {
                                                                        return (
                                                                            <>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[1].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[1].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>
                                                                                    {t.attributes.localizations.data[2].attributes.text}
                                                                                    {(() => {
                                                                                        if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 1) {
                                                                                            return (
                                                                                                <>;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text}</>
                                                                                            )
                                                                                        } else if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 2 ) {
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 3){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 4){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 5){
                                                                                            return (
                                                                                                <>
                                                                                                    ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                    {t.attributes.localizations.data[2].attributes.synonyms.data[4].attributes.text}
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                                
                                                            </>
                                                        )
                                                        } else if (t.attributes.localizations.data[0].attributes.locale === 'en' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                            return (
                                                                <>
                                                                    {(() => {
                                                                        if (t.attributes.localizations.data[1].attributes.locale === 'fr') {
                                                                        return (
                                                                            <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        } else if (t.attributes.localizations.data[2].attributes.locale === 'fr') {
                                                                            return (
                                                                                <>
                                                                                    <div className={classes.termInLanguageContainer}  >
                                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                        <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                    </div>
                                                                                    
                                                                                    <div className={classes.termInLanguageContainer}  >
                                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                        <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    <div className={classes.termInLanguageContainer}  >
                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                        <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                    </div>
                                                                </>
                                                            )
                                                        } else if (t.attributes.localizations.data[0].attributes.locale === 'fr' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                            return (
                                                                <>
                                                                    
                                                                    {(() => {
                                                                        if (t.attributes.localizations.data[1].attributes.locale === 'de') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                                </>
                                                                            )
                                                                        } else if (t.attributes.localizations.data[2].attributes.locale === 'de') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                     
                                                                   
                                                                </>
                                                            )
                                                        }
                                                }else {
                                                    return(<></>)
                                                }
                                            
                                            })()}
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            searchLanguage === 'en' 
                                            ?
                                            <>
                                            {(() => {
                                                if(t.attributes.localizations.data[0] !== undefined) {
                                                    if (t.attributes.localizations.data[0].attributes.locale === 'de' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                        return (
                                                            <>
                                                                <div className={classes.termInLanguageContainer}  >
                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                    <div className={classes.termText}>
                                                                        {t.attributes.localizations.data[0].attributes.text}
                                                                        {(() => {
                                                                            if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 1) {
                                                                                return (
                                                                                    <>;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text}</>
                                                                                )
                                                                            } else if (t.attributes.localizations.data[0].attributes.synonyms.data.length === 2 ) {
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 3){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 4){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text}
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else if(t.attributes.localizations.data[0].attributes.synonyms.data.length === 5){
                                                                                return (
                                                                                    <>
                                                                                        ;&nbsp;{t.attributes.localizations.data[0].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                        {t.attributes.localizations.data[0].attributes.synonyms.data[4].attributes.text}
                                                                                    </>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                                {(() => {
                                                                    if (t.attributes.localizations.data[1].attributes.locale === 'fr') {
                                                                    return (
                                                                        <>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>
                                                                                {t.attributes.localizations.data[1].attributes.text}
                                                                                {(() => {
                                                                                    if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 1) {
                                                                                        return (
                                                                                            <>;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text}</>
                                                                                        )
                                                                                    } else if (t.attributes.localizations.data[1].attributes.synonyms.data.length === 2 ) {
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 3){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 4){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    else if(t.attributes.localizations.data[1].attributes.synonyms.data.length === 5){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[1].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[1].attributes.synonyms.data[4].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                        <div className={classes.termInLanguageContainer}  >
                                                                            <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                            <div className={classes.termText}>
                                                                                {t.attributes.localizations.data[2].attributes.text} 
                                                                                {(() => {
                                                                                    if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 1) {
                                                                                        return (
                                                                                            <>;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text}</>
                                                                                        )
                                                                                    } else if (t.attributes.localizations.data[2].attributes.synonyms.data.length === 2 ) {
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 3){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 4){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    else if(t.attributes.localizations.data[2].attributes.synonyms.data.length === 5){
                                                                                        return (
                                                                                            <>
                                                                                                ;&nbsp;{t.attributes.localizations.data[2].attributes.synonyms.data[0].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[1].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[2].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[3].attributes.text};&nbsp;
                                                                                                {t.attributes.localizations.data[2].attributes.synonyms.data[4].attributes.text}
                                                                                            </>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        </div>
                                                                        </>
                                                                    )
                                                                    } else if (t.attributes.localizations.data[2].attributes.locale === 'fr') {
                                                                        return (
                                                                            <>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                            </div>
                                                                            <div className={classes.termInLanguageContainer}  >
                                                                                <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                            </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                                
                                                            </>
                                                        )
                                                        } else if (t.attributes.localizations.data[0].attributes.locale === 'fr' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                            return (
                                                                <>
                                                                    {(() => {
                                                                        if (t.attributes.localizations.data[1].attributes.locale === 'it') {
                                                                        return (
                                                                            <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                                </div>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        } else if (t.attributes.localizations.data[2].attributes.locale === 'it') {
                                                                            return (
                                                                                <>
                                                                                    <div className={classes.termInLanguageContainer}  >
                                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                        <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                    </div>
                                                                                    <div className={classes.termInLanguageContainer}  >
                                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                                        <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                                    </div>
                                                                                    <div className={classes.termInLanguageContainer}  >
                                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                        <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </>
                                                            )
                                                        } else if (t.attributes.localizations.data[0].attributes.locale === 'it' && Object.keys(t.attributes.localizations.data).length === 3) {
                                                            return (
                                                                <>
                                                                    
                                                                    {(() => {
                                                                        if (t.attributes.localizations.data[1].attributes.locale === 'de') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                               
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                                </>
                                                                            )
                                                                        } else if (t.attributes.localizations.data[2].attributes.locale === 'de') {
                                                                            return (
                                                                                <>
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[2].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[2].attributes.text}</div>
                                                                                </div>
                                                                               
                                                                                <div className={classes.termInLanguageContainer}  >
                                                                                    <div className={classes.termLocale}>{t.attributes.localizations.data[1].attributes.locale}.</div>&nbsp;
                                                                                    <div className={classes.termText}>{t.attributes.localizations.data[1].attributes.text}</div>
                                                                                </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    <div className={classes.termInLanguageContainer}  >
                                                                        <div className={classes.termLocale}>{t.attributes.localizations.data[0].attributes.locale}.</div>&nbsp;
                                                                        <div className={classes.termText}>{t.attributes.localizations.data[0].attributes.text}</div>
                                                                    </div>
                                                                   
                                                                </>
                                                            )
                                                        }
                                                }else {
                                                    return(<></>)
                                                }
                                            
                                            })()}
                                            </>
                                            :
                                            <></>
                                        }
                                        
                                        </div>
                                        
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                        ))}
                        {
                            loading 
                            ?
                            <>
                            <div className={classes.loaderContainer}>
                                <div className="loader"></div>
                            </div>
                            </>
                            :
                            <></>
                        }
                        {
                            {...{...termsMeta}.pagination}.pageCount === loaderLimit - 1 && loading === false
                            ?
                            <>
                            <div className={classes.termsEnd}>{pageContent.end_of_terms}</div>
                            </> 
                            : 
                            <></>
                        }
                    </div>
                    
                </div>
                
            </div>
            {/* <AdComponent/> */}
        </>
    );
}
