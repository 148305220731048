import React from 'react';
import { Link } from 'react-router-dom';
import AppHeader from '../Components/AppHeader';
import AppFooter from '../Components/AppFooter';
import Input from '../Components/Input';
import TextArea from '../Components/TextArea';
import RadioInput from '../Components/RadioInput';
import styles from "./Modal.module.css";

import Toast from "../Components/Toast";
import FeedbackToast from '../Components/FeedbackToast';
import CheckboxInput from '../Components/CheckboxInput'
import StarsInput from '../Components/StarsInput';
import Select from '../Components/Select';

// const LANGUAGES = {
//     "German": "de",
//     // "fr": "fr",
//     // "it": "it",
//     "English": "en",
// };



import classes from './Profile.module.css'


// REACT COFNRM ALERT 
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import Compressor from 'compressorjs';

import { useState, useEffect, useRef } from 'react'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'







export default function Profile({setLanguage = f=>f, ...props} ) {
    const [isLoadingUser, setIsLoadingUser] = useState(false) //1
    const [isLoadingUserFeedback, setIsLoadingUserFeedback] = useState(false) //2
    const [user, setUser] = useState(() => (
        
        {
            id: 0,
            username: "",
            name: "",
            lastname: "",
            email: "",
            picture: null,
        }
)) //3
    const [alert, setAlert] = useState({
        type:"d-none",
        message: "",
    }) //4
    const [jwt, setJwt] = useState("") //5
    const [editName, setEditName] = useState(false) //6
    const [editLastName, setEditLastName] = useState(false) //7
    const [editEmail, setEditEmail] = useState(false) //8
    const [editUsername, setEditUsername] = useState(false) //9
    const [lastEdited ,setLastEdited] =useState('none') //10
    const [toastVisible, setToastVisible] = useState(false) //11
    const [feedbackToastVisible, setFeedbackToastVisible] = useState(false) //12
    const [credentials, setCredentials] = useState({
        pwd: '',
        txt: ''
    }) //13
    const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false); //14
    const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false); //15
    const [isOpenModalChangeEmail, setIsOpenModalChangeEmail] = useState(false); //16
    const [toastProperties, setToastProperties] = useState([]) //17
    const [isOpenModalDeleteAccount, setIsOpenModalDeleteAccount] = useState(false); //18
    const [passwordShown, setPasswordShown] = useState(false); //19
    const [enteredPwdTouched, setEnteredPwdTouched] = useState(false); //20
    const initError = {
        exists: false,
        helperText: null,
    };
    const [pwdError, setPwdError] = useState(initError); //21
    const [enteredNameTouched, setEnteredNameTouched] = useState(false); //22
    const [enteredLastNameTouched, setEnteredLastNameTouched] = useState(false); //23
    const [enteredUserNameTouched, setEnteredUserNameTouched] = useState(false); //24
    const [enteredEmailTouched, setEnteredEmailTouched] = useState(false); //25
    const [nameError, setNameError] = useState(initError); //26
    const [lastNameError, setLastNameError] = useState(initError); //27
    const [userNameError, setUserNameError] = useState(initError); //28
    const [emailError, setEmailError] = useState(initError); //29
    const [deleteReasonError, setDeleteReasonError] = useState(initError) //30
    const [searchBarDisplayed, setSearchBarDisplayed] = useState(false) //31
    const [selected, setSelected] = useState(""); //32
    const [mobile, setMobile] = useState(false) //33
    const [feedback, setFeedback] = useState({
        iduser: window.localStorage.getItem("id"),
        comment: "", 
        rate: "0",
        consent: "1",
    }) //34
    const [consent, setConsent] = useState(true) //35
    // const [enteredFeedbackTouched, setEnteredFeedbackTouched] = useState(false);
    const [feedbackError, setFeedbackError] = useState(initError); //36
    const [starsInputError, setStarsInputError] = useState(initError); //37
    const [allUserFeedback, setAllUserFeedback] = useState([])
    const [residual , setResidual] = useState()
    const [comeFrom, setComeFrom] = useState(window.localStorage.getItem("comeFrom"))
    const [pageContent, setPageContent] = useState({})
    const [appsLanguages, setAppsLanguages] = useState({})

    const [compressedImg, setCompressedImg] = useState(null)
    const [changedImg, setChangedImg] = useState(false)

    // const [resp, setResp] = useState()

    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/profile/?locale=${props.language}`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => {
                setPageContent(data.data.attributes)
            })
    }, [props.language])

    useEffect(() => {
        setIsLoadingUserFeedback(true)
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/commentsCMS/`, { 
                method: 'GET' 
            })
            .then(data => data.json())
            .then(data => {
                
                Object.values(data.users).map(val => {
                    if(val.id_user == window.localStorage.getItem("id")) {
                        setAllUserFeedback((prevState) => {
                            return ([
                                ...prevState,
                                val
                            ])
                        })
                    }
                })
                setIsLoadingUserFeedback(false)
                
                
            })
            
        window.innerWidth < 900 ? setMobile(true) : setMobile(false)
        let t = window.localStorage.getItem("jwt");
        // console.log(allUserFeedback)
        setIsLoadingUser(true)
        if(t === null){
            props.history.push({
                pathname: "/",
            });
            return;
        } else {
            try {
                
                const getInfo = async () => {
                    await fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/user/` + window.localStorage.getItem("id"))
                        .then((response) => {
                            return response.json();
                        })
                        .then((json) => {
                            setUser({
                                id: window.localStorage.getItem("id"), 
                                username: json.user.username,
                                name: json.user.name,
                                lastname: json.user.lastname,
                                email: json.user.email,
                                picture: json.user.picture,
                                account: json.user.account.toString(),
                                token: window.localStorage.getItem("jwt")
                            })
                            setCredentials({
                                pwd: '',
                                txt: json.user.email,
                            })
                            window.localStorage.setItem("userName", json.user.username);
                            window.localStorage.setItem("name", json.user.name);
                            window.localStorage.setItem("lastname", json.user.lastname);
                            window.localStorage.setItem("email", json.user.email);
                            window.localStorage.setItem("picture", json.user.picture)
                            window.localStorage.setItem("account", json.user.account)
                            setIsLoadingUser(false)
                            

                        })
                }
                getInfo()
                // 
            } catch (error) {
                console.log(error)
            }
        } 
    
        

    }, []);

    useEffect(() => {
        window.localStorage.getItem("lastname")
        if (!user.name && enteredNameTouched) {
            setNameError({
              exists: true,
            //   helperText: "Write your name",
            helperText:`${pageContent.void_name_validation_text}`
            });
        } else {
            setNameError({
              exists: false,
              helperText: null,
            });
        }
        if (!user.lastname && enteredLastNameTouched) {
            setLastNameError({
              exists: true,
            //   helperText: "Write your last name",
            helperText: `${pageContent.void_last_name_validation_text}`
            });
        } else {
            setLastNameError({
              exists: false,
              helperText: null,
            });
        }
        if (!user.username && enteredUserNameTouched) {
            setUserNameError({
              exists: true,
            //   helperText: "Write your user name",
            helperText: `${pageContent.void_username_validation_text}`
            });
        } else {
            setUserNameError({
              exists: false,
              helperText: null,
            });
        }
        
        if (!user.email ) {
            setEmailError({
              exists: true,
            //   helperText: "Write your email",
            helperText: `${pageContent.void_email_validation_text}`
            });
        } 
        else if(user.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) === null && user.email !== '') {
            setEmailError({
                exists: true,
                // helperText: "Write a valid email",
                helperText: `${pageContent.valid_email_validation_text}`
              });
        } 
        else {
            setEmailError({
              exists: false,
              helperText: null,
            });
        }


        if (!credentials.pwd && enteredPwdTouched) {
            setPwdError({
              exists: true,
            //   helperText: "Write your password",
            helperText: `${pageContent.void_password_validation_text}`
            });
        } else {
            setPwdError({
              exists: false,
              helperText: null,
            });
        }


        // if (!feedback.comment && enteredFeedbackTouched) {
        //     setFeedbackError({
        //       exists: true,
        //       helperText: "Write your feedback",
        //     });
        // } else {
        //     setFeedbackError({
        //       exists: false,
        //       helperText: null,
        //     });
        // }


    }, [credentials, enteredPwdTouched, user, enteredNameTouched, enteredLastNameTouched, enteredUserNameTouched, enteredEmailTouched, feedback])

    useEffect(() => {
        setResidual(5 - {...allUserFeedback[allUserFeedback.length - 1]}.stars)
        window.localStorage.setItem("residual", 15 - {...allUserFeedback[allUserFeedback.length - 1]}.stars);
    }, [allUserFeedback])

    useEffect(()=>{
        // let name = "picture"
        setUser((prevState) => ({
            ...prevState,
            ["picture"]: compressedImg,
        }));
    }, [compressedImg])

    const LANGUAGES = {
        "de": `${appsLanguages[0]}`,
        // "fr": `${pageContent.lang_name_fr}`,
        // "it": `${pageContent.lang_name_it}`,
        "en": `${appsLanguages[1]}`,
    };

    const pwdBlurHandler = (e) => {
        setEnteredPwdTouched(true);
    };

    // const commentBlurHandler = (e) => {
    //     setEnteredFeedbackTouched(true);
    // };



    function useOutsideAlerter(ref) { 
        useEffect(() => {
            // if (lastEdited === "name") {
            //     setUser((prevState) => ({
            //         ...prevState,
            //         "name": window.localStorage.getItem("name"),
            //     }));
            //     setEditName(false);
            // }
            // if (lastEdited === "lastname") {
            //     setUser((prevState) => ({
            //         ...prevState,
            //         "lastname": window.localStorage.getItem("lastname"),
            //     }));
            //     setEditLastName(false) ;
            // }
            // if (lastEdited === "email") {
            //     setUser((prevState) => ({
            //         ...prevState,
            //         "email": window.localStorage.getItem("email"),
            //     }));
            //     setEditEmail(false); 
            // } 
         
           // Alert if clicked on outside of element
          function handleClickOutside(event) {
            
                
            if (ref.current && !ref.current.contains(event.target) ) {
                setEditName(false);
                setEditLastName(false);
                setEditEmail(false);
                setEditUsername(false);

            }
          
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef= useRef(null);
    useOutsideAlerter(wrapperRef);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleChange = (evt) => {
        let value = evt.target.value;
        let name = evt.target.id;

        console.log(evt.target.name)
        setLastEdited(evt.target.name)
        window.localStorage.setItem("lastEdited", evt.target.name);

        setUser((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePassChange = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;

        // console.log(evt.target.name)

        setCredentials((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        
    };

    
    const handleSubmit = (evt) => {
  
        setIsOpenPhotoModal(false)

        const requestOptions = {
            method: 'POST',
            token: window.localStorage.getItem("token"),
            body: JSON.stringify(user),
        }

        if(lastEdited === 'name' && user.name === '') {
            setEnteredNameTouched(true);
            setEditName(true)
            return
        } else if (lastEdited === 'name' && user.name !== '') {
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/updateuserlog/`, requestOptions)
            .then(data => {
                setToastVisible(true)
                data.json().then(data => {
                    console.log(data.Response)
                    
                })
                setInterval(() => {
                    setToastVisible(false)
                }, 10000)
                if (data.status === 200){
                    window.localStorage.setItem("name", JSON.parse(requestOptions.body).name);
                    setToastProperties({
                        description: props.language === 'de' ? `IHR ${lastEdited.toUpperCase()} WAR ERFOLGREICH GEÄNDERT`: `YOUR ${lastEdited.toUpperCase()} WAS SUCCESSFULLY CHANGED`,
                        borderColor: '#8DD037',
                        icon: 'icon-success'
                    })
                    setEditName(false)
                }else{
                    console.log(data.status)
                    setToastProperties({
                        description: props.language === 'de' ? `IHRE SITZUNG IST ABGELAUFEN. BITTE MELDEN SIE SICH AB UND ERNEUT AN, UM DIE GEWÜNSCHTEN ÄNDERUNGEN VORZUNEHMEN.`: `YOUR SESSION EXPIRED, PLEASE LOGOUT AND LOGIN AGAIN TO MAKE THE DESIRED CHANGES.`,
                        borderColor: '#f33757',
                        icon: 'icon-warning'
                    })
                    setEditName(false)
                }
            })
        }
        if((lastEdited === 'lastname' || lastEdited === 'Nachname') && user.lastname === '') {
            setEnteredLastNameTouched(true)
            setEditLastName(true)
            return
        } else if ((lastEdited === 'lastname' || lastEdited === 'Nachname') && user.lastname !== '') {
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/updateuserlog/`, requestOptions)
            .then(data => {
                console.log(typeof(requestOptions.body))
                console.log(JSON.parse(requestOptions.body).lastname)
                setToastVisible(true)
                setInterval(() => {
                    setToastVisible(false)
                }, 5000)
                if (data.status === 200){
                    window.localStorage.setItem("lastname", JSON.parse(requestOptions.body).lastname);
                    setToastProperties({
                        description: props.language === 'de' ? `IHR ${lastEdited.toUpperCase()} WAR ERFOLGREICH GEÄNDERT`: `YOUR ${lastEdited.toUpperCase()} WAS SUCCESSFULLY CHANGED`,
                        borderColor: '#8DD037',
                        icon: 'icon-success'
                    })
                    setEditLastName(false)
                    
                }else{
                    console.log(data.status)
                    setToastProperties({
                        description: props.language === 'de' ? `IHRE SITZUNG IST ABGELAUFEN. BITTE MELDEN SIE SICH AB UND ERNEUT AN, UM DIE GEWÜNSCHTEN ÄNDERUNGEN VORZUNEHMEN.`: `YOUR SESSION EXPIRED, PLEASE LOGOUT AND LOGIN AGAIN TO MAKE THE DESIRED CHANGES.`,
                        borderColor: '#f33757',
                        icon: 'icon-warning'
                    })
                    setEditLastName(false)
                }
            })
           
        }
        if(lastEdited === 'email' || lastEdited === 'Email') {
            setEnteredEmailTouched(true)
            
            if(user.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) === null){
                setEmailError({
                    exists: true,
                    // helperText: "Write a valid email",
                    helperText: `${pageContent.valid_email_validation_text}`
                  });
                  return
            } else if (user.email === '') {
                setEmailError({
                    exists: true,
                    helperText: `${pageContent.void_email_validation_text}`
                });
                return
            } 
            else {
                console.log('The eamil was edited')
                let newMail = {
                    id: window.localStorage.getItem("id"),
                    email: user.email,
                    token: window.localStorage.getItem("jwt"),
                    lang: props.language
                }
                const mailRequestOptions = {
                    method: 'POST', 
                    body: JSON.stringify(newMail),
                }
                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/changeemail/`, mailRequestOptions)
                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/updateuserlog/`, requestOptions)
                .then(data => {
                    setToastVisible(true)
                    setInterval(() => {
                        setToastVisible(false)
                    }, 5000)
                    if (data.status === 200){
                        window.localStorage.setItem("email", JSON.parse(requestOptions.body).email);
                        
                        setToastProperties({
                            description: props.language === 'de' ? `IHR ${lastEdited.toUpperCase()} WAR ERFOLGREICH GEÄNDERT`: `YOUR ${lastEdited.toUpperCase()} WAS SUCCESSFULLY CHANGED`,
                            borderColor: '#8DD037',
                            icon: 'icon-success'
                        })
                            
                        
                    }else{
                        console.log(data.status)
                        setToastProperties({
                            description: props.language === 'de' ? `IHRE SITZUNG IST ABGELAUFEN. BITTE MELDEN SIE SICH AB UND ERNEUT AN, UM DIE GEWÜNSCHTEN ÄNDERUNGEN VORZUNEHMEN.`: `YOUR SESSION EXPIRED, PLEASE LOGOUT AND LOGIN AGAIN TO MAKE THE DESIRED CHANGES.`,
                            borderColor: '#f33757',
                            icon: 'icon-warning'
                        })
                    }
                })
            }
            

        } else if(lastEdited === 'username' || lastEdited === 'Benutzername') {
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/verifyuser/${user.username}`)
                .then(data => data.json())
                .then(data => {
                    console.log(data.ID)
                    if (data.ID === 0) {
                        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/updateuserlog/`, requestOptions)
                        .then(data => {
                            setToastVisible(true)
                            setInterval(() => {
                                setToastVisible(false)
                            }, 5000)
                            if (data.status === 200){
                                window.localStorage.setItem("userName", JSON.parse(requestOptions.body).username);
                                setToastProperties({
                                    description: props.language === 'de' ? `IHR ${lastEdited.toUpperCase()} WAR ERFOLGREICH GEÄNDERT`: `YOUR ${lastEdited.toUpperCase()} WAS SUCCESSFULLY CHANGED`,
                                    borderColor: '#8DD037',
                                    icon: 'icon-success'
                                })
                                setEditUsername(false);
                            }else{
                                console.log(data.status)
                                setToastProperties({
                                    description: props.language === 'de' ? `IHRE SITZUNG IST ABGELAUFEN. BITTE MELDEN SIE SICH AB UND ERNEUT AN, UM DIE GEWÜNSCHTEN ÄNDERUNGEN VORZUNEHMEN.`: `YOUR SESSION EXPIRED, PLEASE LOGOUT AND LOGIN AGAIN TO MAKE THE DESIRED CHANGES.`,
                                    borderColor: '#f33757',
                                    icon: 'icon-warning'
                                })
                            }
                        })   
                    } else {
                        setUserNameError({
                            exists: true,
                            // helperText: "User name in use, choose another one",
                            helperText: `${pageContent.in_use_username_validation_text}`
                        });



                    }
                })

        } else {
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/updateuserlog/`, requestOptions)
            .then(data => {
                setToastVisible(true)
                setInterval(() => {
                    setToastVisible(false)
                }, 5000)
                if (data.status === 200){
                    setToastProperties({
                        description: props.language === 'de' ? `IHR ${lastEdited.toUpperCase()} WAR ERFOLGREICH GEÄNDERT`: `YOUR ${lastEdited.toUpperCase()} WAS SUCCESSFULLY CHANGED`,
                        borderColor: '#8DD037',
                        icon: 'icon-success'
                    })
                    setChangedImg(true)

                }else{
                    console.log(data.status)
                    setToastProperties({
                        description: props.language === 'de' ? `IHRE SITZUNG IST ABGELAUFEN. BITTE MELDEN SIE SICH AB UND ERNEUT AN, UM DIE GEWÜNSCHTEN ÄNDERUNGEN VORZUNEHMEN.`: `YOUR SESSION EXPIRED, PLEASE LOGOUT AND LOGIN AGAIN TO MAKE THE DESIRED CHANGES.`,
                        borderColor: '#f33757',
                        icon: 'icon-warning'
                    })
                }
            })
        }
           
    }

    const logout = () => {
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/removeactiveuser/${window.localStorage.getItem("id")}`,{
            method: 'GET', 
        })
        setJwt("");
        // REMOVE TOKEN 
        window.localStorage.removeItem("jwt");
    }



    const confirmDelete = () => {
        if (selected === "") {
            setDeleteReasonError({
                exists: true,
                // helperText: "Must choose an option",
                helperText: `${pageContent.delete_account_validation_text}`
            })
            return 

        }
        let deleteData = {
            id: user.id,
            token: window.localStorage.getItem("jwt")
        }
        let saveReasonData = {
            id: user.id,
            reason: selected[0]
        }
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/savereason/`,{
            method: "POST",
            body: JSON.stringify(saveReasonData)
        })

        if(props.language === "de"){
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/deleteaccount/`, 
                {
                    method: "POST",
                    body: JSON.stringify(deleteData)
                })
                    .then(response => response.json)
                    .then(data => {
                        props.history.push({
                            pathname: "/",
                        })
                        window.localStorage.removeItem("jwt");
                    })
        } else {
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/deleteaccountEN/`, 
                {
                    method: "POST",
                    body: JSON.stringify(deleteData)
                })
                    .then(response => response.json)
                    .then(data => {
                        props.history.push({
                            pathname: "/",
                        })
                        window.localStorage.removeItem("jwt");
                    })
        }

      
    }

    

    // const handleImage = (event) => {
    //     event.preventDefault();

    //     const requestOptions = {
    //         method: 'POST', 
    //         // mode:"no-cors",
    //         headers: {
    //             // Content-Type may need to be completely **omitted**
    //             // or you may need something
    //             // "Content-Type": "image/png",
    //             // "enctype": "multipart/form-data"
    //           },
    //         body: JSON.stringify({
    //             "imge": user.picture,
    //         })
    //     }

    //     fetch('${process.env.REACT_APP_API_GOLANG_URL}/v1/porfilepic/', requestOptions)
    //         .then(response => response.json())
    //         // .then(data => {
    //         //     if (data.error){
    //         //         setAlert({
    //         //             type: "alert-danger", 
    //         //             message: data.error.message,
    //         //         })
    //         //     }else{
    //         //         console.log("DONE")
    //         //     }
    //         // })
    // }

    const compressedImage = document.querySelector("#compressedImage");
    const originalImage = document.querySelector("#originalImage"); 

    let compressedImageBlob;
    function fileToDataUri(field) {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            resolve(reader.result);
          });
          reader.readAsDataURL(field);
          
        });
        
    }

    
    
    function compressImage(imgToCompress, quality) {
        // showing the compressed image
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
      
        const originalWidth = imgToCompress.width;
        const originalHeight = imgToCompress.height;

        if(originalWidth <= originalHeight){
            const resizingFactor = originalWidth/96
      
            const canvasWidth = originalWidth / resizingFactor;
            const canvasHeight = originalHeight / resizingFactor;
        
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
        
            context.drawImage(
                imgToCompress,
                0,
                0,
                originalWidth / resizingFactor,
                originalHeight / resizingFactor
            );
        } else {
            const resizingFactor = originalHeight/96
        
            const canvasWidth = originalWidth / resizingFactor;
            const canvasHeight = originalHeight / resizingFactor;
        
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
        
            context.drawImage(
                imgToCompress,
                0,
                0,
                originalWidth / resizingFactor,
                originalHeight / resizingFactor
            );

        }
      
        // reducing the quality of the image
        canvas.toBlob(
          (blob) => {
            if  (blob) {
                compressedImageBlob = blob;
                // console.log(compressedImageBlob)
                compressedImage.src = URL.createObjectURL(compressedImageBlob);
                // console.log(compressedImage.src)
                var reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onloadend = function() {
                    setCompressedImg(reader.result)
                    console.log(reader.result)      
              }
            }
            console.log("ITS NOT A BLOB?")
          },
          "image/jpeg",
          quality
        );
    }

      

      

    const ModalPhoto = ({ setIsOpen }) => {
        return (
            <>
                <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
                <div className={styles.centered}>
                    <div className={styles.modal} style={{width:'275px', height:'325px'}}>
                        <button style={{display:'flex', width:'100%', justifyContent:'flex-end', padding:'5px'}} className='none' onClick={() => setIsOpen(false)}>
                            <i className='icon ms-1 icon-close'></i> 
                        </button>

                        <div style={{height:'calc(100% - 35px)', display:'grid'}}>
                        <div style={{height:'100%', display:'grid', justifyItems:'center'}} className={styles.modalHeader}>
                            <h5  className={styles.heading}>
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Bild hochladen</>
                                    :
                                    <>Upload picture</>
                                } */}
                                {pageContent.modal_photo_title}
                            </h5>
                            <img 
                                src={user.picture}
                                alt="" 
                                style={{borderRadius:'50%', width: '96px', height:'96px', objectFit:'cover'}}
                                id="compressedImage"
                            />
                        </div>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <input 
                                id='picture'
                                className={styles.fileInput}
                                accept="image/png, image/jpeg, image/webp"
                                type={"file"}
                                name={props.language === 'de' ? "Profilbild" : "picture"}
                                
                                onChange={async(event) => {
                                    setLastEdited(event.target.name)
                                    console.log(event.target.name)
                                    const [file] = event.target.files;
                                    // storing the original image
                                    
                                    originalImage.src = await fileToDataUri(file)

                                    originalImage.addEventListener("load", () => {
                                        compressImage(originalImage, 1)
                                    });
                                   
                                    
        
                                    
                                  
                                }}
                            />
                            <label className={styles.fileInputLabel} for="picture">
                                <i className='icon ms-1 icon-upload'></i> 
                                <span>
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>WÄHLEN SIE EIN BILD AUS</>
                                        :
                                        <>SELECT A PICTURE</>
                                    } */}
                                    {pageContent.modal_photo_input_text}
                                </span>
                            </label>
                        </div>
                            
                        
                        <div style={{display:'flex', justifyContent: 'space-around'}} >
                            <button
                                style={{width:'unset'}}
                                className='snow'
                                onClick={() => setIsOpen(false)}
                            >
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Stornieren</>
                                    :
                                    <>Cancel</>
                                } */}
                                {pageContent.modal_photo_cancel_button}
                            </button>
                            <button  
                                style={{width:'unset'}}
                                onClick={handleSubmit} 
                                className="ochre"
                            >
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>HOCHLADEN</>
                                    :
                                    <>UPLOAD</>
                                } */}
                                {pageContent.modal_photo_upload_button}
                            </button>
                        </div>
                        </div>
                        
                        
                    </div>
                </div>
            </>
        );
    };

    const ModalDeleteAccount = ({ setIsOpen }) => {
        return (
            <>
            <div className={styles.darkBG} onClick={() => {setIsOpen(false); setDeleteReasonError(initError)}} />
                <div className={styles.centered}>
                    <div style={{height: '400px', width:'290px', padding:'0', fontFamily:'Roboto', backgroundColor:'#F5F5F6'}} className={styles.modal}>
                        <button style={{display:'flex', width:'100%', justifyContent:'flex-end', padding:'0px'}} className='none' onClick={() => {setIsOpen(false); setDeleteReasonError(initError)}}>
                            <i className='icon ms-1 icon-close'></i> 
                        </button>

                        <div style={{padding:'0 10%'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={styles.modalHeader}>
                                <i style={{color:'#4FB8A8', fontSize:'3rem'}} className='icon ms-1 icon-warning'></i> 
                            </div>
                            <div style={{display:'flex', justifyContent: 'center', marginTop: '10px'}}>
                                <h5 style={{fontSize:'14px', padding:'0', marginBottom:'10px', fontWeight:'600'}} className={styles.heading}> 
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>Sind Sie sicher, dass Sie Ihr Konto löschen möchten?</>
                                        :
                                        <>Are you sure you want to delete your account?</>
                                    } */}
                                    {pageContent.modal_delete_account_text}
                                </h5>
                            </div>
                        
                        </div>
                        <div style={{padding:'0 10%'}}>
                            <h5 style={{fontSize:'14px', padding:'0', marginBottom:'10px', fontWeight:'600'}} className={styles.heading}>
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Warum löschen Sie Ihr Konto?</>
                                    :
                                    <>Why are you deleting your account?</>
                                } */}
                                {pageContent.modal_delete_account_question}
                            </h5>
                            <div style={{fontSize:'14px'}}>
                                <RadioInput 
                                    value={["I don't use the app"]}
                                    selected={selected}
                                    text={pageContent.modal_delete_account_option_1}
                                    onChange={(val) => {setSelected(val)}}
                                />
                                <RadioInput 
                                    value={["You send to many e-mails"]}
                                    selected={selected}
                                    text={pageContent.modal_delete_account_option_2}
                                    onChange={(val) => {setSelected(val)}}
                                />
                                {/* <RadioInput 
                                    value={["I receive too many notifications"]}
                                    selected={selected}
                                    text="I receive too many notifications"
                                    onChange={(val) => {setSelected(val)}}
                                /> */}
                                <RadioInput 
                                    value={["The app is not usefull"]}
                                    selected={selected}
                                    text={pageContent.modal_delete_account_option_3}
                                    onChange={(val) => {setSelected(val)}}
                                />
                                <RadioInput 
                                    value={["Something else"]}
                                    selected={selected}
                                    text={pageContent.modal_delete_account_option_4}
                                    onChange={(val) => {setSelected(val)}}
                                />

                                <div style={{height:'18px'}} className={deleteReasonError.exists ? "text-danger" : "no-danger"}>{deleteReasonError.helperText}</div>
                            </div>
                        </div>
                        <div style={{display:'flex', justifyContent: 'space-around', marginTop:'25px'}} >
                            <button  
                                style={{width:'unset'}}
                                onClick={() => confirmDelete()}
                                className="snow"
                            >
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>ICH BIN SICHER</>
                                    :
                                    <>I'M SURE</>
                                } */}
                                {pageContent.modal_delete_account_confirm}
                            </button>
                            <button
                                style={{width:'unset'}}
                                className='ochre'
                                onClick={() => {setIsOpen(false); setDeleteReasonError(initError)}}
                            >
                                
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Stornieren</>
                                    :
                                    <>Cancel</>
                                } */}
                                {pageContent.modal_delete_account_cancel}
                            </button>
                            
                        </div>
                        
                        
                    </div>
                </div>
            </>
        );
    };

    const ModalChangePass = ({ setIsOpen }) => {
        return (
            <>
            <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
                <div className={styles.centered}>
                    <div className={styles.modal}>
                        <button style={{display:'flex', width:'100%', justifyContent:'flex-end', padding:'5px'}} className='none' onClick={() => setIsOpen(false)}>
                            <i className='icon ms-1 icon-close'></i> 
                        </button>

                        <div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={styles.modalHeader}>
                            <i style={{color:'#4FB8A8', fontSize:'3rem'}} className='icon ms-1 icon-alert'></i> 
                        </div>
                        <div style={{display:'flex', justifyContent: 'center', marginBottom: '25px', marginTop: '10px'}}>
                            <h5 style={{fontFamily:'Roboto'}}  className={styles.heading}>
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Sind Sie sicher, dass Sie Ihr Passwort ändern möchten?</>
                                    :
                                    <>Are you sure you want to change your password?</>
                                } */}
                                {pageContent.modal_change_password_text}
                            </h5>
                        </div>
                            
                        
                        <div style={{display:'flex', justifyContent: 'space-around'}} >
                            <button  
                                style={{width:'unset'}}
                                
                                className="snow"
                            >
                                <Link
                                    to={'/changePassword'}
                                    className='link'
                                    style={{textDecoration: 'none'}}
                                >
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>ICH BIN SICHER</>
                                        :
                                        <>I'M SURE</>
                                    } */}
                                    {pageContent.modal_change_password_confirm}
                                </Link>
                                
                            </button>
                            <button
                                style={{width:'unset'}}
                                className='ochre'
                                onClick={() => setIsOpen(false)}
                            >
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Stornieren</>
                                    :
                                    <>Cancel</>
                                } */}
                                {pageContent.modal_change_password_cancel}
                            </button>
                            
                        </div>
                        </div>
                        
                        
                    </div>
                </div>
            </>
        );
    };

    const handleGetCredentials = () => {
        
        // evt.preventDefault();

        const requestOptions = {
            method: 'POST', 
            body: JSON.stringify(credentials),
        }

        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/validatepwd/`, requestOptions)
            .then((response) => response.json())
            .then(data => {
                if (data.user.id === 0 && credentials.pwd !== ''){
                    setPwdError({
                        exists: true,
                        // helperText: props.language === "de" ? "Falsches Passwort": "Wrong password",
                        helperText: `${pageContent.wrong_password_validation_text}`
                    });    
                }
                else if(credentials.pwd === '') {
                    setPwdError({
                        exists: true,
                        // helperText: props.language === "de" ? "Schreiben Sie Ihr Passwort": "Write your password",
                        helperText: `${pageContent.void_password_validation_text}`
                      });
                }
                else{
                    console.log('Yes')
                    console.log(data.user)
                    setEditEmail(true) 
                    setEditName(false)
                    setEditLastName(false)
                    setEditUsername(false)
                    setIsOpenModalChangeEmail(false)
                }
            })
           
    }

    const ModalChangeEmail = ({ setIsOpen}) => {
        return (
            <>
            <div className={styles.darkBG} />
                <div className={styles.centered}>
                    <div style={{height:'300px'}} className={styles.modal}>
                        <button type='button' style={{display:'flex', width:'100%', justifyContent:'flex-end', padding:'5px'}} className='none' onClick={() => setIsOpenModalChangeEmail(false)}>
                            <i className='icon ms-1 icon-close'></i> 
                        </button>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={styles.modalHeader}>
                            <i style={{color:'#4FB8A8', fontSize:'3rem'}} className='icon ms-1 icon-alert'></i> 
                        </div>
                        <div style={{display:'flex', justifyContent: 'center', marginBottom: '25px', marginTop: '10px'}}>
                            <h6 style={{fontSize:'12px', paddingTop:'0', paddingBottom:'0'}} className={styles.heading}> 
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Um Ihre E-Mail-Adresse zu ändern, benötigen wir aus Sicherheitsgründen die Eingabe Ihres aktuellen Passworts</>
                                    :
                                    <>In order to change your email address, for security reasons we need you to enter your current password</>
                                } */}
                                {pageContent.modal_change_email_text}
                            </h6>
                        </div>
                        <div style={{position:'relative',display:'flex', justifyContent:'center'}}>
                            <Input 
                                title = {"pwd"}
                                type={passwordShown ? "text" : "password"}
                                name = {"pwd"}
                                autoFocus="autoFocus"
                                placeholder = {pageContent.modal_change_email_placeholder}
                                value = {credentials.pwd}
                                handleChange={handlePassChange}
                                // handleBlur={pwdBlurHandler}
                                style={{width:'200px'}}
                                className={pwdError.exists ? "is-invalid": ""}
                                errorDiv = {pwdError.exists ? "text-danger" : "no-danger"}
                                errorMsg = {pwdError.helperText}
                            />
                            <i style={{position: 'absolute', top:'8px', right:'35px', cursor:'pointer'}} onClick={togglePasswordVisiblity}>{passwordShown ? EyeLogo() : EyeLogoClosed()}</i>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-evenly', marginTop:'25px'}}>
                            <button
                                style={{width:'unset'}}
                                className='ochre'
                                type='button'
                                onClick={() => setIsOpenModalChangeEmail(false)}
                            >
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Stornieren</>
                                    :
                                    <>Cancel</>
                                } */}
                                {pageContent.modal_change_email_cancel_button}
                            </button>
                            <button
                                style={{width:'unset'}}
                                className='snow'
                                type='button'
                                onClick={handleGetCredentials}
                            >
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Bestätigen</>
                                    :
                                    <>Confirm</>
                                } */}
                                {pageContent.modal_change_email_confirm_button}
                            </button>
                        </div>
                        

                        
                        
                    </div>
                </div>
            </>
        );
    };

    const handleChangeComment = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;

        setFeedback((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmitComment = () => {

        if (feedback.rate === "0" && feedback.comment.length > 10) {
            setStarsInputError({
                exists: true,
                // helperText: props.language === 'de' ? "Wählen Sie eine Anzahl Sterne" : "Choose a number of stars",
                helperText:`${pageContent.star_count_validation_text}`
            });
            setFeedbackError({
                exists: false,
                helperText: null,
            });
        } 
        else if (feedback.comment.length < 10 && feedback.rate !== "0") {
            setFeedbackError({
                exists: true,
                // helperText: props.language === 'de' ? "Der Kommentar muss mehr als 10 Zeichen lang sein" : "The comment have to be more than 10 characters long",
                helperText: `${pageContent.feedback_validation_text}`
            });
        }
        else if (feedback.comment.length < 10 && feedback.rate === "0") {
            setStarsInputError({
                exists: true,
                // helperText: props.language === 'de' ? "Wählen Sie eine Anzahl Sterne" : "Choose a number of stars",
                helperText:`${pageContent.star_count_validation_text}`
            });
            setFeedbackError({
                exists: true,
                // helperText: props.language === 'de' ? "Der Kommentar muss mehr als 10 Zeichen lang sein" : "The comment have to be more than 10 characters long",
                helperText: `${pageContent.feedback_validation_text}`
            });
        }
        else {
            setStarsInputError({
                exists: false,
                helperText: null,
            });
            setFeedbackError({
                exists: false,
                helperText: null,
            });
            const requestOptions = {
                method: 'POST', 
                body: JSON.stringify(feedback),
            }
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/savecomment/`, requestOptions)
                .then((data) => {
                    console.log(data)
                    
                    if (data.status === 200) {
                        setToastProperties({
                            description: props.language === 'de' ? `IHR KOMMENTAR WURDE GESENDET` : `YOUR COMMENT HAS BEEN SENT`,
                            borderColor: '#8DD037',
                            icon: 'icon-success'
                        })
                        setFeedbackToastVisible(true)
                        setInterval(() => {
                            setFeedbackToastVisible(false)
                            window.location.reload()
                        }, 5000)
                    } else if (data.status !== 200) {
                        setToastProperties({
                            description: props.language === 'de' ? `ES GIBT EIN PROBLEM. VERSUCHEN SIE ES SPÄTER NOCH EINMAL` : `THERE IS A PROBLEM, TRY AGAIN LATER`,
                            borderColor: '#f33757',
                            icon: 'icon-warning'
                        })
                        setFeedbackToastVisible(true)
                        setInterval(() => {
                            setFeedbackToastVisible(false)
                            window.location.reload()
                        }, 5000)
                    }
                })
        }

        
     
    }


    return(
        <>
            <AppHeader 
                currentPathName={props.location.pathname}
                setDictionaryContent={(val) => setAppsLanguages([val.lang_name_de, val.lang_name_en])}
                appLanguage={props.language}
                searchBarDisplayed={searchBarDisplayed}
                mobile = {mobile}
                comeFrom={comeFrom}
                basicDictionary={comeFrom === 'basicDictionary' ? true : false}
            />
            <Toast
                toastList={toastProperties}
                position="top-right" 
                visible={toastVisible}
                language={props.language}
            />
            <FeedbackToast 
                position="top-right" 
                toastList={toastProperties}
                visible={feedbackToastVisible}
            />


            
            <div>
            
            {isOpenPhotoModal && <ModalPhoto setIsOpen={setIsOpenPhotoModal} />}
            {isOpenModalChangePass && <ModalChangePass setIsOpen={setIsOpenModalChangePass} />}
            {isOpenModalChangeEmail && <ModalChangeEmail setIsOpen={setIsOpenModalChangeEmail} />}
            {isOpenModalDeleteAccount && <ModalDeleteAccount setIsOpen={setIsOpenModalDeleteAccount} />}
                <div >
                    <div className={classes.profileContainer}>
                        
                        <div style={{}}>
                            <input 
                                type="hidden"
                                name="id"
                                id="id"
                                value={user.id}
                                onChange={handleChange}
                            />
                            <div>
                            <button className={`${classes.EditPhotoModal}`} onClick={() => setIsOpenPhotoModal(true)}>
                                <i style={{ color: 'white'}} className='icon icon-edit'></i>
                            </button>
                                <div >
                                    <div style={{height:'96px', marginBottom:'25px'}}>
                                        {
                                            isLoadingUser
                                            ?
                                            <Skeleton 
                                                circle={true}
                                                style={{width:'96px', height:'96px',}}
                                                borderRadius="10"
                                            />
                                            :
                                            <>
                                            <img 
                                                src={user.picture}
                                                alt="" 
                                                style={{
                                                    borderRadius:'50%', 
                                                    width: '96px', height:'96px', 
                                                    objectFit:'cover', 
                                                    display:changedImg&&(lastEdited==='Profilbild' || lastEdited==='picture')?'':'none'
                                                }}
                                                id="compressedImage"
                                                
                                            />
                                             <img 
                                                src={window.localStorage.getItem("picture")}
                                                alt="" 
                                                style={{
                                                    borderRadius:'50%', 
                                                    width: '96px', 
                                                    height:'96px',
                                                     objectFit:'cover',
                                                     display:changedImg&&(lastEdited==='Profilbild' || lastEdited==='picture')?'none':''
                                                }}
                                            />
                                            <img style={{display:'none'}} id="originalImage" />
                                            </>
                                        }
                                       
                                    </div>
                                </div>
                              
                                <div ref={wrapperRef}>
                                   
                                    <div>
                                        <div className='body_text_small'>
                                            {pageContent.name}
                                        </div>
                                        {
                                            isLoadingUser && window.localStorage.getItem("name") === null
                                            ?
                                            <>
                                            <Skeleton 
                                                style={{width:'370px', height:'36px'}} 
                                            />
                                            </>
                                            :
                                            <>
                                            <div className='input-content'>
                                            
                                            {editName 
                                                ? 
                                                <>
                                                    
                                                    <Input 
                                                        title = {"name"}
                                                        type = {"text"}
                                                        name = {props.language === 'de' ? "name" : "name"}
                                                        id="name"
                                                        placeholder = {user.name}
                                                        value = {user.name}
                                                        handleChange={handleChange}
                                                        className={nameError.exists ? "is-invalid": ""}
                                                        errorDiv = {nameError.exists ? "text-danger" : "no-danger"}
                                                        errorMsg = {nameError.helperText}
                                                        style={{padding: props.language === "de" ? '8px 76px 8px 16px' : '8px 46px 8px 16px'}}
                                                    />
                                                    {
                                                        lastEdited === 'name' 
                                                            ? 
                                                            <>
                                                            <button 
                                                                style={{position:'relative', right: props.language === "de" ? '75px' : '45px', cursor:'pointer'}} 
                                                                onClick={()=>{
                                                                    handleSubmit(); 
                                                                    // setEditName(false)
                                                                }} 
                                                                className="link"
                                                            >  
                                                                {pageContent.save_button}   
                                                            </button>
                                                            </>
                                                            :
                                                            <>
                                                            <button disabled style={{position:'relative', right: props.language === "de" ? '75px' : '45px', color:'#A5A5A5'}} onClick={()=>{handleSubmit() && setEditName(false)}} className="link">
                                                                {pageContent.save_button}
                                                            </button>
                                                            </>
                                                    }
                                                    
                                                </> 
                                                :
                                                <>
                                                    <h3 style={{margin:'0'}}>{window.localStorage.getItem("name")}</h3>
                                                    <button onClick={() => {setEditName(true); setEditLastName(false) ; setEditEmail(false); setEditUsername(false)}} className='none'>
                                                        <i className='icon ms-1 icon-edit'></i>
                                                    </button>
                                                </>
                                            }
                                        </div>
                                            </>
                                        }

                                        
                                    </div>
                                    <div>
                                        <div className='body_text_small'>
                                            {pageContent.last_name}
                                        </div>
                                        {
                                            isLoadingUser && window.localStorage.getItem("lastname") === null
                                            ?
                                            <>
                                            <Skeleton 
                                                style={{width:'370px', height:'36px'}} 
                                            />
                                            </>
                                            :
                                            <>
                                            <div  className='input-content'>
                                            {editLastName 
                                                ? 
                                                <>
                                                    <Input 
                                                        title = {"lastname"}
                                                        type = {"text"}
                                                        name = {props.language === 'de' ? "Nachname" : "lastname"}
                                                        id = {"lastname"}
                                                        placeholder = {user.lastname}
                                                        value = {user.lastname}
                                                        handleChange={handleChange}
                                                        className={lastNameError.exists ? "is-invalid": ""}
                                                        errorDiv = {lastNameError.exists ? "text-danger" : "no-danger"}
                                                        errorMsg = {lastNameError.helperText}
                                                        style={{padding: props.language === "de" ? '8px 76px 8px 16px' : '8px 46px 8px 16px'}}
                                                    />
                                                    {
                                                        lastEdited === 'lastname' || lastEdited === 'Nachname'
                                                            ? 
                                                            <button style={{position:'relative', right: props.language === "de" ? '75px' : '45px'}} onClick={()=>{handleSubmit()}} className="link">{pageContent.save_button}</button>
                                                            :
                                                            <button disabled style={{position:'relative', right: props.language === "de" ? '75px' : '45px', color:'#A5A5A5'}} onClick={()=>{handleSubmit() && setEditLastName(false)}} className="link">{pageContent.save_button}</button>
                                                    }
                                                </> 
                                                :
                                                <>
                                                    <h3 style={{margin:'0'}}>{window.localStorage.getItem("lastname")}</h3>
                                                    {/* <div className='subtitle primary_dark'>{window.localStorage.getItem("lastname")}</div> */}
                                                    <button onClick={() => {setEditLastName(true); setEditName(false); setEditEmail(false); setEditUsername(false)}} className='none'>
                                                        <i className='icon ms-1 icon-edit'></i>
                                                    </button>
                                                </>
                                            }
                                        </div> 
                                            </>
                                        }
                                          
                                    </div>
                                    <div>
                                        <div className='body_text_small'>
                                            {pageContent.email}
                                        </div>

                                        {
                                            isLoadingUser && window.localStorage.getItem("email") === null
                                            ?
                                            <>
                                            <Skeleton 
                                                style={{width:'370px', height:'36px'}} 
                                            />
                                            </>
                                            :
                                            <>
                                            <div  className='input-content'>
                                            {editEmail 
                                                ? 
                                                <>
                                                    <Input 
                                                        title = {"email"}
                                                        type = {"email"}
                                                        name = {props.language === 'de' ? "Email" : "email"}
                                                        id = {"email"}
                                                        placeholder = {user.email}
                                                        value = {user.email}
                                                        handleChange={handleChange}
                                                        className={emailError.exists ? "is-invalid": ""}
                                                        errorDiv = {emailError.exists ? "text-danger" : "no-danger"}
                                                        errorMsg = {emailError.helperText}
                                                        style={{padding: props.language === "de" ? '8px 76px 8px 16px' : '8px 46px 8px 16px'}}
                                                    />
                                                    {
                                                        lastEdited === 'email' || lastEdited === 'Email'
                                                            ? 
                                                            <button style={{position:'relative', right: props.language === "de" ? '75px' : '45px'}} onClick={()=>{handleSubmit(); setEditEmail(false)}} className="link">{pageContent.save_button}</button>
                                                            :
                                                            <button disabled style={{position:'relative', right: props.language === "de" ? '75px' : '45px', color:'#A5A5A5'}} onClick={()=>{handleSubmit() && setEditEmail(false)}} className="link">{pageContent.save_button}</button>
                                                    }
                                                </> 
                                                :
                                                <>
                                                    <div className='subtitle primary_dark'>{window.localStorage.getItem("email")}</div>
                                                    {/* <button onClick={() => {setEditEmail(true); setEditName(false); setEditLastName(false); setEditUsername(false)}} className='none'> */}
                                                    <button onClick={() => setIsOpenModalChangeEmail(true)} className='none'>
                                                        <i className='icon ms-1 icon-edit'></i>
                                                    </button>
                                                </>
                                            }
                                        </div>
                                            </>
                                        }
                                        
                                    </div>


                                    <div>

                                    <div>
                                        <div className='body_text_small'>
                                            {pageContent.username}
                                        </div>
                                        {
                                            isLoadingUser && window.localStorage.getItem("userName") === null
                                            ?
                                            <>
                                            <Skeleton 
                                                style={{width:'370px', height:'36px'}} 
                                            />
                                            </>
                                            :
                                            <>
                                            <div  className='input-content'>
                                            {editUsername
                                                ? 
                                                <>
                                                    <Input 
                                                        title = {"username"}
                                                        type = {"text"}
                                                        name = {props.language === 'de' ? "Benutzername" : "username"}
                                                        id = {"username"}
                                                        placeholder = {user.username}
                                                        value = {user.username}
                                                        handleChange={handleChange}
                                                        className={userNameError.exists ? "is-invalid": ""}
                                                        errorDiv = {userNameError.exists ? "text-danger" : "no-danger"}
                                                        errorMsg = {userNameError.helperText}
                                                        style={{padding: props.language === "de" ? '8px 76px 8px 16px' : '8px 46px 8px 16px'}}
                                                    />

                                                    {
                                                        lastEdited === 'username' || lastEdited === 'Benutzername'
                                                            ? 
                                                            <button style={{position:'relative', right: props.language === "de" ? '75px' : '45px'}} onClick={()=>{handleSubmit(); }} className="link">{pageContent.save_button}</button>
                                                            :
                                                            <button disabled style={{position:'relative', right: props.language === "de" ? '75px' : '45px', color:'#A5A5A5'}} onClick={()=>{handleSubmit()}} className="link">{pageContent.save_button}</button>
                                                    }
                                                </> 
                                                :
                                                <>
                                                    <div className='subtitle primary_dark'>{window.localStorage.getItem("userName")}</div>
                                                    <button onClick={() => {setEditEmail(false); setEditName(false); setEditLastName(false); setEditUsername(true)}} className='none'>
                                                        <i className='icon ms-1 icon-edit'></i>
                                                    </button>
                                                </>
                                            }
                                            </div>
                                            </>
                                        }
                                        

                                    </div>

                                    <div>
                                        <div className='body_text_small'>
                                            {pageContent.account_type}
                                        </div>
                                        {
                                            isLoadingUser && window.localStorage.getItem("account") === null
                                            ?
                                            <Skeleton 
                                                style={{width:'370px', height:'36px'}} 
                                            />
                                            :
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <div className='subtitle primary_dark'>
                                                {(() => {
                                                    if (window.localStorage.getItem("account") == 0) {
                                                    return (
                                                        <div>{pageContent.basic_account_name}</div>
                                                    )
                                                    } else if (window.localStorage.getItem("account") == 2) {
                                                    return (
                                                        <div>{pageContent.book_account_name}</div>
                                                    )
                                                    } 
                                                })()}
                                                </div> 
                                                {
                                                    window.localStorage.getItem("account") == 2
                                                    ?
                                                    <></>
                                                    :
                                                    <Link
                                                        to={'/upgradeAccount'}
                                                        className='link'
                                                        style={{textDecoration: 'none', marginLeft: '15px'}}
                                                    >
                                                        {/* {
                                                            props.language === "de"
                                                            ?
                                                            <>AKTUALISIERUNG</>
                                                            :
                                                            <>UPGRADE</>
                                                        } */}
                                                        {pageContent.upgrade_account_link}
                                                    </Link>
                                                }
                                            </div>
                                        }
                                        
                                        
                                    </div>
                                    
                                    
                                </div>
                                </div>
                            </div>
                            <div style={{marginTop:'55px'}}>
                                <div >   
                                    <button
                            
                                        className="link"
                                        onClick={() => setIsOpenModalChangePass(true)}
                                    >
                                        {pageContent.change_password_title}
                                    </button>
                                </div>
                                <div >
                                    <Link className={'link'} to="/" onClick={logout}><i className='icon-link icon-logout me-1'></i>{pageContent.logout_button}</Link>
                                </div>
                                <div>
                                    <button 
                        
                                        onClick={() => setIsOpenModalDeleteAccount(true)}
                                        className="link"
                                    >
                                        <i className='icon-link icon-delete'></i>
                                        {pageContent.delete_account_title}
                                    </button>
                                </div>
                            </div>           
                                
                           

                        </div>
                        <div className={classes.divisionLine}></div>
                        
                        <div className={classes.settingsContainer}>
                            <h2 style={{fontSize:'24px', color:'#004F3D'}}>
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>Wählen Sie die Sprache der App</>
                                    :
                                    <>Choose app's language</>
                                } */}
                                {pageContent.choose_app_language_title}
                            </h2>
                            <Select  
                                style={{width:'150px', marginBottom:'15px'}}
                                onSelected={(value) => {
                                    setLanguage(value)
                                }}  
                                selectedOption={ 
                                    (() => {
                                        if (props.language === 'de') {
                                            return (
                                                `${appsLanguages[0]}`
                                            )
                                        } else if (props.language === 'fr') {
                                            return (
                                                'French'
                                            )
                                        } else if (props.language === 'it') {
                                            return (
                                                'Italian'
                                            )
                                        } else if (props.language === 'en') {
                                            return (
                                                `${appsLanguages[1]}`
                                            )
                                        }
                                    })()
                                }
                                options={Object.values(LANGUAGES)} 
                            />
                            {
                                isLoadingUserFeedback
                                ?
                                <>
                                <Skeleton 
                                    style={{width:'300px', height:'400px'}} 
                                />
                                </>
                                :
                                <>
                                {
                                allUserFeedback.length === 0
                                ?
                                <>
                                
                                <section className={classes.SendFeedbackContainer}>
                                    <div className={classes.SendFeedback}>
                                        <h1 className={classes.SendFeedbackTitle}>{pageContent.feedback_title}</h1>
                                        <div className={classes.StarsRaiting}>
                                            <StarsInput 
                                                componentContent={pageContent.rate_app_title}
                                                language={props.language}
                                                rate={feedback.rate}
                                                changeRate={val => {
                                                    setFeedback((prevState) => ({
                                                        ...prevState,
                                                        "rate": `${val}`,
                                                    }))
                                                    setStarsInputError({
                                                        exists: false,
                                                        helperText: null,
                                                    });

                                                }}  
                                                changeRateBack={val => {
                                                    setFeedback((prevState) => ({
                                                        ...prevState,
                                                        "rate": `${val}`,
                                                    }))
                                                    setStarsInputError({
                                                        exists: false,
                                                        helperText: null,
                                                    });
                                                }}
                                                errorDiv = {starsInputError.exists ? "text-danger" : "no-danger"}
                                                errorMsg = {starsInputError.helperText}
                                            />
                                        </div>
                                        <div>
                                            <p style={{fontFamily:"'Roboto'"}}>
                                                {/* {
                                                    props.language === 'de'
                                                    ?
                                                    <>Hinterlassen Sie Ihr Feedback*</>
                                                    :
                                                    <>Leave your feedback*</>
                                                } */}
                                                {pageContent.write_feedback_title}
                                            </p>
                                            <TextArea 
                                                name="comment" 
                                                handleChange={handleChangeComment} 
                                                // handleBlur={commentBlurHandler}
                                                style={{width:'100%', backgroundColor:'#FDFDFD', height:'150px', resize:'none', border:'2px solid #ccc', borderRadius:'15px', padding:'10px'}}
                                                className={feedbackError.exists ? "is-invalid": ""}
                                                errorDiv = {feedbackError.exists ? "text-danger" : "no-danger"}
                                                errorMsg = {feedbackError.helperText}
                                                writtenCharacters ={feedback.comment.length}
                                                language = {props.language}
                                                componentContent={pageContent.number_of_characters}
                                            />
                                            <CheckboxInput
                                                value={consent}
                                                selected={true}
                                                text={pageContent.consent_public_comment}
                                                onChange={(val) => {
                                                    setConsent(!val); 
                                                    if (consent === true) {
                                                        setFeedback((prevState) => ({
                                                            ...prevState,
                                                            "consent": "0",
                                                        }));
                                                    }else{
                                                        setFeedback((prevState) => ({
                                                            ...prevState,
                                                            "consent": "1",
                                                        }));
                                                    }
                                            
                                                }}
                                            />
                                        </div>
                                        <div className={classes.sendButton}>
                                            <button onClick={handleSubmitComment} className={'btn ochre'}>
                                                {/* {
                                                    props.language === 'de'
                                                    ?
                                                    <>KOMMENTAR SENDEN</>
                                                    :
                                                    <>SEND COMMENT</>
                                                } */}
                                                {pageContent.send_comment_button}
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                </>
                                :
                                <>
                                    
                                    <h1 className={classes.SendFeedbackTitle}>{pageContent.feedback_title}</h1>
                                    <h2 style={{fontSize:'12px', fontWeight:'normal'}}>
                                        {/* {
                                            props.language === 'de'
                                            ?
                                            <>Ihr letztes Feedback</>
                                            :
                                            <>Your last feedback</>
                                        } */}
                                        {pageContent.last_feedback_title}
                                    </h2>
                                    {Array.from(Array({...allUserFeedback[allUserFeedback.length - 1]}.stars).keys()).map(i => {
                                        return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z" fill="#F3BF4C"/>
                                        </svg>
                                        
                                    })}
                                   
                                    {/* {Array.from(Array(  window.localStorage.getItem("residual") ).keys()).map(i => {
                                        return <svg width="20" height="19" viewBox="2 2 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.55 13.97L22 9.24ZM12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L12 6.1L13.71 10.14L18.09 10.52L14.77 13.4L15.77 17.68L12 15.4Z" fill="#F3BF4C"/>
                                        </svg>
                                    })} */}
                                    {/* {console.log(window.localStorage.getItem("residual"))} */}
                                    <div style={{width:'300px', fontFamily:'Roboto'}}>{{...allUserFeedback[allUserFeedback.length - 1]}.feedback}</div>
                                    <CheckboxInput
                                        value={
                                            {...allUserFeedback[allUserFeedback.length - 1]}.consent === 1 ? true : false
                                        }
                                        selected={true}
                                        text={props.language === 'de' ? "Ich bin damit einverstanden, dass mein Kommentar veröffentlicht wird" :"I consent to make my comment public"}
                                        disabled={true}
                                    />

                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <svg width="16.8" height="15.6" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.25853 10.0849L7.00016 9.929L6.7418 10.0849L3.6363 11.9593L4.46042 8.4266L4.52899 8.1327L4.30088 7.9351L1.55658 5.5577L5.1691 5.25122L5.46953 5.22573L5.58719 4.94812L7.00016 1.61431L8.41314 4.94812L8.5308 5.22573L8.83123 5.25122L12.4437 5.5577L9.69944 7.9351L9.47134 8.1327L9.5399 8.4266L10.364 11.9593L7.25853 10.0849Z" stroke="#B66A00"/>
                                        </svg>

                                        <Link
                                            to={'/sendFeedback'}
                                            className='link'
                                        
                                        >
                                            {/* {
                                                props.language === 'de'
                                                ?
                                                <>Verlassen Sie es und sehen Sie sich Kommentare an</>
                                                :
                                                <>Leave and see comments</>
                                            } */}
                                            {pageContent.all_comments_link}
                                        </Link>
                                    </div>
                                   
                                </>
                            }
                            </>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter language={props.language} />
        </>
    )
}

const EyeLogo = () => {
    return(
      <svg width="24" height="24" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0125 15.75C5.94249 15.75 1.5325 12.65 0.0225 8.02997C-0.0075 7.92997 -0.0075 7.81997 0.0225 7.71997C1.5325 3.09997 5.94249 0 11.0125 0C16.0825 0 20.4925 3.09997 22.0025 7.71997C22.0325 7.81997 22.0325 7.92997 22.0025 8.02997C20.4925 12.65 16.0825 15.75 11.0125 15.75ZM1.0225 7.87C2.4425 11.99 6.43249 14.75 11.0125 14.75C15.5925 14.75 19.5825 11.99 21.0025 7.87C19.5825 3.75 15.5925 0.98999 11.0125 0.98999C6.43249 0.98999 2.4425 3.75 1.0225 7.87Z" fill="#B66A00"/>
        <path d="M11.0125 12.7999C8.30251 12.7999 6.09253 10.5899 6.09253 7.87994C6.09253 7.59994 6.31253 7.37994 6.59253 7.37994C6.87253 7.37994 7.09253 7.59994 7.09253 7.87994C7.09253 10.0399 8.85251 11.7999 11.0125 11.7999C13.1725 11.7999 14.9326 10.0399 14.9326 7.87994C14.9326 5.71994 13.1725 3.95996 11.0125 3.95996C10.7325 3.95996 10.5125 3.73996 10.5125 3.45996C10.5125 3.17996 10.7325 2.95996 11.0125 2.95996C13.7225 2.95996 15.9326 5.16994 15.9326 7.87994C15.9326 10.5899 13.7225 12.7999 11.0125 12.7999Z" fill="#B66A00"/>
      </svg>
    )
}

const EyeLogoClosed = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0125 19.75C6.94249 19.75 2.5325 16.65 1.0225 12.03C0.9925 11.93 0.9925 11.82 1.0225 11.72C2.5325 7.09997 6.94249 4 12.0125 4C17.0825 4 21.4925 7.09997 23.0025 11.72C23.0325 11.82 23.0325 11.93 23.0025 12.03C21.4925 16.65 17.0825 19.75 12.0125 19.75ZM2.0225 11.87C3.4425 15.99 7.43249 18.75 12.0125 18.75C16.5925 18.75 20.5825 15.99 22.0025 11.87C20.5825 7.75 16.5925 4.98999 12.0125 4.98999C7.43249 4.98999 3.4425 7.75 2.0225 11.87Z" fill="#B66A00"/>
            <path d="M12.0125 16.7899C9.30251 16.7899 7.09253 14.5799 7.09253 11.8699C7.09253 11.5899 7.31253 11.3699 7.59253 11.3699C7.87253 11.3699 8.09253 11.5899 8.09253 11.8699C8.09253 14.0299 9.85251 15.7899 12.0125 15.7899C12.2925 15.7899 12.5125 16.0099 12.5125 16.2899C12.5125 16.5699 12.2925 16.7899 12.0125 16.7899Z" fill="#B66A00"/>
            <path d="M16.4225 12.3799C16.1425 12.3799 15.9225 12.1599 15.9225 11.8799C15.9225 9.71994 14.1624 7.95996 12.0024 7.95996C11.7224 7.95996 11.5024 7.73996 11.5024 7.45996C11.5024 7.17996 11.7224 6.95996 12.0024 6.95996C14.7124 6.95996 16.9225 9.16994 16.9225 11.8799C16.9225 12.1599 16.7025 12.3799 16.4225 12.3799Z" fill="#B66A00"/>
            <path d="M19.1426 19.51C19.0126 19.51 18.8826 19.46 18.7926 19.36L4.53257 5.09995C4.33257 4.89995 4.33257 4.58999 4.53257 4.38999C4.73257 4.18999 5.04259 4.18999 5.24259 4.38999L19.5025 18.65C19.7025 18.85 19.7025 19.16 19.5025 19.36C19.4025 19.46 19.2726 19.51 19.1526 19.51H19.1426Z" fill="#B66A00"/>
        </svg>
    )
}