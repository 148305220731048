import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import classes from './AppHeader.module.css'

import Select from './Select';
import Registration from '../Pages/Registration';




function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export default function AppHeader({
    basicDictionary = false,
    openDictionary = false,
    bookDictionary = false,
    onSelectPreviosSearch = f => f,
    setSearchBarDisplayed = f => f,
    searchBarDisplayed,
    currentPathName,
    currentTerm,
    setCurrentTerm = f => f,
    currentId,
    setReloaded = f => f,
    onClick3 = f => f, 
    search, 
    onClick5 = f => f, 
    onFocus1 = f => f, 
    onBlur1 = f => f, 
    options, 
    onChange1 = f => f, 
    lastVisited, 
    getMeaning = f => f, 
    onChange2 = f => f, 
    ref2, 
    mobile,
    getTermsWithPredictive = f => f,
    displayedSearchBarOptions,
    isLoadingPredictedTerms,
    displayedPredicted,
    predictedTerms,
    currentSearch,
    setCurrentSearch = f => f,
    setLink= f =>f,
    language,
    setLanguage = f => f,
    setTermVisible = f => f,
    setCurrentLetters = f => f,
    sendConsultedTermMetric = f => f,
    termNotSelected,
    setTermNotSelected = f => f,
    // setComeFrom = f => f,
    comeFrom, 
    appLanguage,
    setDictionaryContent = f => f
}) {

    const [pageContent, setPageContent] = useState({})
    const [serachYPosition, setSerachYPosition] = useState()

    const LANGUAGES = {
        "de": `${pageContent.lang_name_de}`,
        "fr": `${pageContent.lang_name_fr}`,
        "it": `${pageContent.lang_name_it}`,
        "en": `${pageContent.lang_name_en}`,
    };

    useEffect(() => {
        if (!(window.location.pathname.startsWith('/profile/') 
            || window.location.pathname.startsWith('/upgradeAccount') 
            || window.location.pathname.startsWith('/sendFeedback') 
            || window.location.pathname.startsWith('/changePassword') 
            || window.location.pathname.startsWith('/confirmChangePassword') 
            || window.location.pathname.startsWith('/upgradeBookAccount'))) {
            setSerachYPosition(document.getElementById('search').getBoundingClientRect().bottom)
        }


        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/application/?locale=${appLanguage}`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => {
                setPageContent(data.data.attributes)
                setDictionaryContent(data.data.attributes)
            })
          
            
    }, [appLanguage])
    
    
   
    // const startTask = () => { 
    //     Notification.requestPermission((permission) => {
    //         navigator.serviceWorker.ready.then(() => {
    //             const notifyMe = permission === 'granted'  
    //             navigator.serviceWorker.controller.postMessage({
    //                 type: 'DO_SLOW_THING',
    //                 notifyMe, 
    //             })
    //         })      
    //     })
    // }

    function showNotification() {
        Notification.requestPermission().then((result) => {
          if (result === "granted") {
            navigator.serviceWorker.ready.then((registration) => {
            setTimeout(() => {
                registration.showNotification("Vibration Sample", {
                    body: "Buzz! Buzz!",
                    icon: "../images/touch/chrome-touch-icon-192x192.png",
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    tag: "vibration-sample",
                    requireInteraction: true,
                    renotify: true,
                  }); 
            }, 5000);
              

            });
          }
        });
    }


    let id = window.localStorage.getItem("id")
    return (
        <>
        <header style={{backgroundColor: comeFrom === 'bookDictionary' || bookDictionary ? '#004f3d' : comeFrom === 'basicDictionary' || basicDictionary ? '#068b77' : '#e0f2f0'}} className={classes.header}>
            <nav  className={classes.navbar}>
                
                {
                    mobile
                    ?
                    <>{
                        searchBarDisplayed 
                        ?
                        <>
                        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                            <div style={{display:'flex', alignItems:'center', marginLeft:'30px'}}>
                            {
                                search === '/dictionary/:id' || search === '/openDictionary/:id' || search === '/basicDictionary/:id'
                                ?
                                <>
                                <div className={classes.search}>
                                    
                                <input
                                    id='search'
                                    placeholder='Search'
                                    onFocus={() => onFocus1()}
                                    onBlur={() => onBlur1()}
                                    onKeyUp={() => {

                                        getTermsWithPredictive(document.getElementById('search').value); 
                                        setCurrentSearch(document.getElementById('search').value)
                                    }}
                                    autocomplete="off"
                                    style={{width: window.innerWidth < 600 ? '185px' : '275px', paddingRight: '75px'}}

                                />  
                                <button onClick={() => {onClick3(document.getElementById('search').value); onClick5(document.getElementById('search').value)}} style={{width:'unset', padding: '0 10px', marginLeft: '5px'}} className='btn ochre'>SEARCH</button>   
                                </div>
                                <>{
                                    options
                                    ?  
                                    <>
                                    
                                    <div ref={ref2} className={classes.searchSelection}  style={{width: mobile ? '100vw' : '400px', height:displayedPredicted?'230px':'200px', backgroundColor: 'rgba(246, 249, 229, 0.95)', position: 'absolute', padding: '15px 30px', borderRadius:mobile?'unset': '10px', right: mobile ? '0' : 'unset', top: mobile ? `calc(${serachYPosition}px + env(safe-area-inset-top))`:'calc(5vh + 17.5px)', boxShadow: '0px 5px 3px rgb(0 0 0 / 25%)'}}>

                                        <Select  
                                            // selectedOption={language}
                                            onSelected={(value) => {
                                                onChange1(getKeyByValue(LANGUAGES, value)); 
                                                setLanguage(value)
                                            }}  
                                            selectedOption={ 
                                                (() => {
                                                    if (language === 'de') {
                                                        return (
                                                            `${pageContent.lang_name_de}`
                                                        )
                                                    } else if (language === 'fr') {
                                                        return (
                                                            `${pageContent.lang_name_fr}`
                                                        )
                                                    } else if (language === 'it') {
                                                        return (
                                                            `${pageContent.lang_name_it}`
                                                        )
                                                    } else if (language === 'en') {
                                                        return (
                                                            `${pageContent.lang_name_en}`
                                                        )
                                                    }
                                                })()
                                            }
                                            options={Object.values(LANGUAGES)} 
                                        />
                                        {
                                            displayedPredicted
                                            ?
                                            <>
                                            <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>   
                                                {/* {
                                                    language === "de"
                                                    ?
                                                    <>Suchen Sie nach Begriffen, die mit beginnen </>
                                                    :
                                                    <>Search for terms starting with </>
                                                } */}
                                                {pageContent.predictive_search_text}

                                                <span style={{fontStyle:'italic'}}>{currentSearch}</span>
                                            </div>
                                            {
                                                isLoadingPredictedTerms
                                                ?
                                                <>
                                                <div style={{display:'flex', justifyContent:'center', padding: '10px 0'}}>
                                                    <div className="loader"></div>
                                                </div>
                                                </>
                                                :
                                                <>
                                                {
                                                    Object.keys(predictedTerms).length===0
                                                    ?
                                                    <div style={{width: '250px', color: '#F33757'}}>
                                                        Sorry we couldn't find any matches. 
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                </>
                                            }
                                            {predictedTerms.map(t => (
                                                
                                                <div>
                                                    
                                                    <Link 
                                                        // id={t.id} 
                                                        onClick={() => {
                                                            sendConsultedTermMetric(t.id)
                                                            console.log(t.id)
                                                            getMeaning(t.id); 
                                                            setTermNotSelected(false)
                                                            setLink([`/dictionary/${t.id}`, t.attributes.text]);
                                                            let currentLetters, currentLetter;
                                                            setTermVisible(t.attributes.text.slice(0, 4));
                                                            currentLetter = t.attributes.text.slice(0, 1).toUpperCase() ;
                                                            console.log(currentLetter)
                                                            
                                                            if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                                currentLetters = ["A", "B", "C"]
                                                            } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                                currentLetters = ["D", "E", "F"]
                                                            } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                                currentLetters = ["G", "H", "I"]
                                                            }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                                currentLetters = ["J", "K", "L"]
                                                            }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                                currentLetters = ["M", "N", "O"]
                                                            }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                                currentLetters = ["P", "Q", "R"]
                                                            }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                                currentLetters = ["S", "T", "U"]
                                                            }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                                currentLetters = ["V", "W", "X"]
                                                            }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                                currentLetters = ["Y", "Z"]
                                                            }
                                                            setCurrentLetters(currentLetters);
                                                            onSelectPreviosSearch(false)
                                                            setTimeout(() => {
                                                                if (document.getElementById(`${t.attributes.text}`) ===  null){
                                                                    setTimeout(() => {
                                                                        document.getElementById(`${t.attributes.text}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                    }, 3000);
                                                                    
                                                                } else {
                                                                    document.getElementById(`${t.attributes.text}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }
                                                            }, 1500);

                                                            
                                                        }} 
                                                        className={'link'} 
                                                        to={openDictionary ? `/openDictionary/${t.id}`: basicDictionary ? `/basicDictionary/${t.id}` : `/dictionary/${t.id}` }
                                                    >
                                                        {
                                                            t.attributes.text
                                                        }
                                                    </Link>
                                                    {
                                                        t.attributes.free === true && (openDictionary || basicDictionary)
                                                        ?
                                                        <span className={classes.openTerm}>book preview</span>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                                
                                            ))}
                                            </>
                                            :
                                            <>
                                            {
                                            isLoadingPredictedTerms
                                            ?
                                            <>
                                            <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>
                                                {/* {
                                                    language === "de"
                                                    ?
                                                    <>Suchen Sie nach Begriffen, die mit beginnen </>
                                                    :
                                                    <>Search for terms starting with </>
                                                } */}
                                                {pageContent.predictive_search_text}
                                                <span style={{fontStyle:'italic'}}>{currentSearch}</span>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', padding: '10px 0'}}>
                                                <div className="loader"></div>
                                            </div>
                                            </>
                                            : 
                                            <></>
                                        }</>
                                        }   

                                        {/* <div>
                                            <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>Recent research</div>
                                            <div>
                                                <Link 
                                                    onClick={() => {
                                                        getMeaning(lastVisited.flat()[2].slice(11));
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                    }} 
                                                    className={'link'} 
                                                    to={`/dictionary/${lastVisited.flat()[2].slice(11)}`}
                                                >
                                                    {lastVisited.flat()[3]}
                                                </Link>
                                            </div>
                                            <div>
                                                <Link 
                                                    onClick={() => {
                                                        getMeaning(lastVisited.flat()[4].slice(11));
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                    }} 
                                                    className={'link'} 
                                                    to={`/dictionary/${lastVisited.flat()[4].slice(11)}`}
                                                >
                                                    {lastVisited.flat()[5]}
                                                </Link>
                                            </div>
                                            <div>
                                                <Link 
                                                    onClick={() => {
                                                        getMeaning(lastVisited.flat()[6].slice(11));
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                    }} 
                                                    className={'link'} 
                                                    to={`/dictionary/${lastVisited.flat()[6].slice(11)}`}
                                                >
                                                    {lastVisited.flat()[7]}
                                                </Link>
                                            </div>
                                            <div>
                                                <Link 
                                                    onClick={() => {
                                                        getMeaning(lastVisited.flat()[8].slice(11));
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                    }} 
                                                    className={'link'} 
                                                    to={`/dictionary/${lastVisited.flat()[8].slice(11)}`}
                                                >
                                                    {lastVisited.flat()[9]}
                                                </Link>
                                            </div>
                                            <div>
                                                <Link 
                                                    onClick={() => {
                                                        getMeaning(lastVisited.flat()[10].slice(11));
                                                        setTermNotSelected(false);
                                                        onSelectPreviosSearch(false)
                                                    }} 
                                                    className={'link'} 
                                                    to={`/dictionary/${lastVisited.flat()[10].slice(11)}`}
                                                >
                                                    {lastVisited.flat()[11]}
                                                </Link>
                                            </div>
            
                                    
                                        </div> */}
                                    </div>
                                
                                    </>
                                    :
                                    null

                                }</>
                                
                                </>
                                :
                                <div style={{width:'350px'}}></div>

                            }
                            
                            </div>
                            <button  onClick={() => setSearchBarDisplayed(false)} style={{ marginRight:'30px', width:'35px', height:'35px'}} className={`none ${classes.toggleSearchBar}`}>
                                <i style={{color:'#FDFDFD'}}  className='icon-nav icon-close'></i>
                            </button>
                        </div>
                        </>
                        :
                        <>
                            <Link
                            to={`/`}
                            className={'navbar-brand'}
                            style={{paddingLeft: '0'}}
                        >
                            {
                                openDictionary
                                ?
                                <>
                                <svg style={{margin: window.innerWidth > 600 ? '0 50px': '0 15px'}}  width={window.innerWidth > 600 ? "200" : "150"} height="56" viewBox="0 0 200 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M197.302 38.2327H157.302V54.2836H197.302V38.2327Z" fill="#CAD051"/>
                                    <path d="M163.665 49.2364V43.28H165.804C166.567 43.28 167.149 43.4182 167.549 43.7018C167.949 43.9855 168.145 44.3927 168.145 44.9236C168.145 45.2291 168.073 45.4982 167.935 45.7091C167.789 45.9273 167.585 46.0873 167.309 46.1891C167.622 46.2691 167.862 46.4218 168.022 46.6473C168.189 46.8727 168.269 47.1418 168.269 47.4618C168.269 48.0436 168.087 48.48 167.716 48.7782C167.345 49.0691 166.8 49.2218 166.073 49.2291H163.658L163.665 49.2364ZM165.098 45.7673H165.847C166.16 45.7673 166.385 45.7091 166.516 45.5927C166.647 45.4764 166.72 45.3164 166.72 45.0982C166.72 44.8436 166.647 44.6691 166.502 44.5527C166.356 44.4436 166.124 44.3855 165.804 44.3855H165.098V45.76V45.7673ZM165.098 46.7055V48.1309H166.036C166.298 48.1309 166.495 48.0727 166.633 47.9491C166.771 47.8327 166.844 47.6655 166.844 47.4473C166.844 46.9527 166.596 46.6982 166.102 46.6982H165.098V46.7055Z" fill="#004F3D"/>
                                    <path d="M175.105 46.7055H172.851V48.1309H175.52V49.2364H171.418V43.28H175.527V44.3855H172.851V45.6436H175.105V46.7055Z" fill="#004F3D"/>
                                    <path d="M183.2 44.3855H181.411V49.2291H179.978V44.3855H178.225V43.28H183.2V44.3855Z" fill="#004F3D"/>
                                    <path d="M189.04 48.1236H187.076L186.735 49.2364H185.2L187.382 43.28H188.735L190.931 49.2364H189.389L189.04 48.1236ZM187.418 47.0182H188.698L188.058 44.9527L187.418 47.0182Z" fill="#004F3D"/>
                                    <path d="M18.7854 29.92H14.5091L13.3964 26.32H7.93454L6.79999 29.92H2.6109L8.21817 13.9709H13.2L18.7854 29.92ZM8.70545 23.4909H12.6182L10.6618 17.04L8.70545 23.4909Z" fill="#068B77"/>
                                    <path d="M36.6182 25.3527C36.3127 26.4655 35.8182 27.3818 35.1418 28.0945C34.4655 28.8073 33.6582 29.3382 32.7273 29.6655C31.7964 29.9927 30.7636 30.16 29.6364 30.16C28.0218 30.16 26.6327 29.8327 25.4546 29.1782C24.2764 28.5236 23.3746 27.5854 22.7491 26.3636C22.1237 25.1418 21.8036 23.6655 21.8036 21.9418C21.8036 20.2182 22.1164 18.7418 22.7491 17.52C23.3746 16.2982 24.2837 15.3527 25.4546 14.7054C26.6327 14.0509 28.0146 13.7236 29.6146 13.7236C30.7273 13.7236 31.7527 13.8836 32.6836 14.1964C33.6146 14.5091 34.4073 14.9891 35.0546 15.6364C35.7018 16.2836 36.16 17.1127 36.4291 18.1236L32.7782 19.6C32.5527 18.6036 32.1891 17.9127 31.68 17.5418C31.1709 17.1709 30.5309 16.9891 29.76 16.9891C28.9891 16.9891 28.3491 17.1709 27.7891 17.5418C27.2364 17.9127 26.8073 18.4654 26.5091 19.2C26.2109 19.9345 26.0582 20.8509 26.0582 21.9418C26.0582 23.0327 26.1964 23.9345 26.4727 24.6764C26.7491 25.4182 27.1637 25.9709 27.7164 26.3418C28.2691 26.7127 28.96 26.8945 29.7818 26.8945C30.5527 26.8945 31.2146 26.6836 31.7527 26.2545C32.2909 25.8254 32.6618 25.1709 32.8509 24.2836L36.6182 25.3454V25.3527Z" fill="#068B77"/>
                                    <path d="M55.4982 25.3527C55.1927 26.4655 54.6982 27.3818 54.0218 28.0945C53.3455 28.8073 52.5382 29.3382 51.6073 29.6655C50.6764 29.9927 49.6437 30.16 48.5164 30.16C46.9018 30.16 45.5127 29.8327 44.3346 29.1782C43.1564 28.5236 42.2546 27.5854 41.6291 26.3636C41.0037 25.1418 40.6837 23.6655 40.6837 21.9418C40.6837 20.2182 40.9964 18.7418 41.6291 17.52C42.2546 16.2982 43.1637 15.3527 44.3346 14.7054C45.5127 14.0509 46.8946 13.7236 48.4946 13.7236C49.6073 13.7236 50.6327 13.8836 51.5637 14.1964C52.4946 14.5091 53.2873 14.9891 53.9346 15.6364C54.5818 16.2836 55.04 17.1127 55.3091 18.1236L51.6582 19.6C51.4327 18.6036 51.0691 17.9127 50.56 17.5418C50.0509 17.1709 49.4109 16.9891 48.64 16.9891C47.8691 16.9891 47.2291 17.1709 46.6691 17.5418C46.1164 17.9127 45.6873 18.4654 45.3891 19.2C45.0909 19.9345 44.9382 20.8509 44.9382 21.9418C44.9382 23.0327 45.0764 23.9345 45.3527 24.6764C45.6291 25.4182 46.0437 25.9709 46.5964 26.3418C47.1491 26.7127 47.84 26.8945 48.6618 26.8945C49.4327 26.8945 50.0946 26.6836 50.6327 26.2545C51.1709 25.8254 51.5418 25.1709 51.7309 24.2836L55.4982 25.3454V25.3527Z" fill="#068B77"/>
                                    <path d="M108.516 13.9636V23.4109C108.516 25.6654 107.92 27.3527 106.713 28.4727C105.513 29.5927 103.738 30.1527 101.382 30.1527C99.0254 30.1527 97.2727 29.5927 96.0654 28.4727C94.8581 27.3527 94.2545 25.6654 94.2545 23.4109V13.9636H98.4363V23.4836C98.4363 24.6254 98.6836 25.4836 99.1709 26.0436C99.6581 26.6109 100.4 26.8873 101.382 26.8873C102.364 26.8873 103.127 26.6036 103.622 26.0436C104.109 25.4836 104.356 24.6254 104.356 23.4836V13.9636H108.516Z" fill="#068B77"/>
                                    <path d="M128.524 13.9636V29.9127H123.905L118.953 21.28L117.745 18.9091H117.724L117.818 21.8545V29.9054H114.145V13.9564H118.764L123.716 22.5818L124.924 24.9527H124.945L124.851 22.0073V13.9564H128.524V13.9636Z" fill="#068B77"/>
                                    <path d="M147.884 13.9636V17.1782H142.684V29.92H138.524V17.1782H133.302V13.9636H147.876H147.884Z" fill="#068B77"/>
                                    <path d="M156.829 13.9636V29.9127H152.669V13.9636H156.829Z" fill="#068B77"/>
                                    <path d="M177.156 13.9636V29.9127H172.538L167.585 21.28L166.378 18.9091H166.356L166.451 21.8545V29.9054H162.778V13.9564H167.396L172.349 22.5818L173.556 24.9527H173.578L173.484 22.0073V13.9564H177.156V13.9636Z" fill="#068B77"/>
                                    <path d="M189.702 30.16C188.284 30.16 187.025 29.8473 185.92 29.2145C184.815 28.5818 183.956 27.6582 183.345 26.4364C182.735 25.2145 182.429 23.7236 182.429 21.9636C182.429 20.2036 182.749 18.7636 183.396 17.5418C184.044 16.32 184.96 15.3745 186.167 14.7127C187.367 14.0509 188.778 13.7236 190.407 13.7236C192.211 13.7236 193.673 14.0509 194.785 14.7054C195.898 15.36 196.756 16.3927 197.375 17.8109L193.535 19.3309C193.324 18.5091 192.953 17.9127 192.422 17.5418C191.891 17.1709 191.229 16.9891 190.444 16.9891C189.658 16.9891 188.975 17.1782 188.415 17.5564C187.847 17.9345 187.425 18.4945 187.135 19.2218C186.844 19.9564 186.698 20.8582 186.698 21.9418C186.698 23.0255 186.851 24.0145 187.149 24.7709C187.447 25.5273 187.891 26.0873 188.48 26.4509C189.069 26.8145 189.796 26.9964 190.669 26.9964C191.135 26.9964 191.564 26.9382 191.949 26.8291C192.335 26.72 192.676 26.5527 192.967 26.3345C193.258 26.1164 193.484 25.8473 193.644 25.5127C193.804 25.1855 193.884 24.7927 193.884 24.3418V24.1018H190.233V21.3236H197.411V29.9273H194.582L194.269 26.1818L194.945 26.7636C194.604 27.8618 194 28.6982 193.12 29.2873C192.24 29.8764 191.113 30.1673 189.724 30.1673L189.702 30.16Z" fill="#068B77"/>
                                    <path d="M81.7163 48.2109L83.1563 43.68L81.7018 39.5854L76.5454 54.0873H79.84L80.8727 50.8436H85.6945L84.7636 48.2109H81.7163Z" fill="#068B77"/>
                                    <path d="M98.1091 46.08H92.2836V48.5964H98.1091V46.08Z" fill="#068B77"/>
                                    <path d="M104.407 51.4836L111.833 40.1964V38.3345H100.545V40.9673H107.978L100.531 52.1818V54.0873H112V51.4836H104.407Z" fill="#068B77"/>
                                    <path d="M81.7454 28.0582C85.9927 25.6291 88.8654 21.0618 88.8654 15.8182C88.8654 8.0291 82.5527 1.71637 74.7636 1.71637C66.9745 1.71637 60.6618 8.0291 60.6618 15.8182C60.6618 23.6073 66.9745 29.92 74.7636 29.92C75.92 29.92 77.04 29.7673 78.1163 29.4982L81.7018 39.5855L83.1563 43.6727L84.7709 48.2109L85.7018 50.8436L86.8509 54.0873H91.1418L81.7454 28.0582ZM74.7636 25.8909C69.2 25.8909 64.6909 21.3818 64.6909 15.8182C64.6909 10.2546 69.2 5.74546 74.7636 5.74546C80.3273 5.74546 84.8363 10.2546 84.8363 15.8182C84.8363 21.3818 80.3273 25.8909 74.7636 25.8909Z" fill="#BD8E16"/>
                                    <path d="M74.7418 25.9854C80.3611 25.9854 84.9164 21.4301 84.9164 15.8109C84.9164 10.1917 80.3611 5.63635 74.7418 5.63635C69.1226 5.63635 64.5673 10.1917 64.5673 15.8109C64.5673 21.4301 69.1226 25.9854 74.7418 25.9854Z" fill="#B3E0D9"/>
                                </svg>
                                </>
                                :
                                <>
                                <svg style={{margin: window.innerWidth > 600 ? '0 50px': '0 15px'}}  width={window.innerWidth > 600 ? "200" : "150"} height="56" viewBox="0 0 200 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M197.302 38.2324H157.302V54.2833H197.302V38.2324Z" fill="#CAD051"/>
                                    <path d="M163.665 49.2366V43.2803H165.804C166.567 43.2803 167.149 43.4185 167.549 43.7021C167.949 43.9857 168.145 44.393 168.145 44.9239C168.145 45.2294 168.073 45.4985 167.935 45.7094C167.789 45.9275 167.585 46.0875 167.309 46.1894C167.622 46.2694 167.862 46.4221 168.022 46.6475C168.189 46.873 168.269 47.1421 168.269 47.4621C168.269 48.0439 168.087 48.4803 167.716 48.7785C167.345 49.0694 166.8 49.2221 166.073 49.2294H163.658L163.665 49.2366ZM165.098 45.7675H165.847C166.16 45.7675 166.385 45.7094 166.516 45.593C166.647 45.4766 166.72 45.3166 166.72 45.0985C166.72 44.8439 166.647 44.6694 166.502 44.553C166.356 44.4439 166.124 44.3857 165.804 44.3857H165.098V45.7603V45.7675ZM165.098 46.7057V48.1312H166.036C166.298 48.1312 166.495 48.073 166.633 47.9494C166.771 47.833 166.844 47.6657 166.844 47.4475C166.844 46.953 166.596 46.6985 166.102 46.6985H165.098V46.7057Z" fill="#004F3D"/>
                                    <path d="M175.105 46.7057H172.851V48.1312H175.52V49.2366H171.418V43.2803H175.527V44.3857H172.851V45.6439H175.105V46.7057Z" fill="#004F3D"/>
                                    <path d="M183.2 44.3857H181.411V49.2294H179.978V44.3857H178.226V43.2803H183.2V44.3857Z" fill="#004F3D"/>
                                    <path d="M189.04 48.1239H187.076L186.735 49.2366H185.2L187.382 43.2803H188.735L190.931 49.2366H189.389L189.04 48.1239ZM187.418 47.0185H188.698L188.058 44.953L187.418 47.0185Z" fill="#004F3D"/>
                                    <path d="M18.7854 29.9198H14.509L13.3963 26.3198H7.93448L6.79993 29.9198H2.61084L8.21811 13.9707H13.1999L18.7854 29.9198ZM8.70539 23.4907H12.6181L10.6617 17.0398L8.70539 23.4907Z" fill="white"/>
                                    <path d="M36.618 25.3527C36.3126 26.4655 35.818 27.3818 35.1416 28.0945C34.4653 28.8073 33.658 29.3382 32.7271 29.6655C31.7962 29.9927 30.7635 30.16 29.6362 30.16C28.0216 30.16 26.6326 29.8327 25.4544 29.1782C24.2762 28.5236 23.3744 27.5854 22.7489 26.3636C22.1235 25.1418 21.8035 23.6655 21.8035 21.9418C21.8035 20.2182 22.1162 18.7418 22.7489 17.52C23.3744 16.2982 24.2835 15.3527 25.4544 14.7054C26.6326 14.0509 28.0144 13.7236 29.6144 13.7236C30.7271 13.7236 31.7526 13.8836 32.6835 14.1964C33.6144 14.5091 34.4071 14.9891 35.0544 15.6364C35.7016 16.2836 36.1598 17.1127 36.4289 18.1236L32.778 19.6C32.5526 18.6036 32.1889 17.9127 31.6798 17.5418C31.1707 17.1709 30.5307 16.9891 29.7598 16.9891C28.9889 16.9891 28.3489 17.1709 27.7889 17.5418C27.2362 17.9127 26.8071 18.4654 26.5089 19.2C26.2107 19.9345 26.058 20.8509 26.058 21.9418C26.058 23.0327 26.1962 23.9345 26.4726 24.6764C26.7489 25.4182 27.1635 25.9709 27.7162 26.3418C28.2689 26.7127 28.9598 26.8945 29.7816 26.8945C30.5526 26.8945 31.2144 26.6836 31.7526 26.2545C32.2907 25.8254 32.6616 25.1709 32.8507 24.2836L36.618 25.3454V25.3527Z" fill="white"/>
                                    <path d="M55.4981 25.3527C55.1927 26.4655 54.6981 27.3818 54.0218 28.0945C53.3454 28.8073 52.5381 29.3382 51.6072 29.6655C50.6763 29.9927 49.6436 30.16 48.5163 30.16C46.9018 30.16 45.5127 29.8327 44.3345 29.1782C43.1563 28.5236 42.2545 27.5854 41.6291 26.3636C41.0036 25.1418 40.6836 23.6655 40.6836 21.9418C40.6836 20.2182 40.9963 18.7418 41.6291 17.52C42.2545 16.2982 43.1636 15.3527 44.3345 14.7054C45.5127 14.0509 46.8945 13.7236 48.4945 13.7236C49.6072 13.7236 50.6327 13.8836 51.5636 14.1964C52.4945 14.5091 53.2872 14.9891 53.9345 15.6364C54.5818 16.2836 55.04 17.1127 55.3091 18.1236L51.6581 19.6C51.4327 18.6036 51.069 17.9127 50.56 17.5418C50.0509 17.1709 49.4109 16.9891 48.64 16.9891C47.869 16.9891 47.229 17.1709 46.669 17.5418C46.1163 17.9127 45.6872 18.4654 45.389 19.2C45.0909 19.9345 44.9381 20.8509 44.9381 21.9418C44.9381 23.0327 45.0763 23.9345 45.3527 24.6764C45.629 25.4182 46.0436 25.9709 46.5963 26.3418C47.149 26.7127 47.84 26.8945 48.6618 26.8945C49.4327 26.8945 50.0945 26.6836 50.6327 26.2545C51.1709 25.8254 51.5418 25.1709 51.7309 24.2836L55.4981 25.3454V25.3527Z" fill="white"/>
                                    <path d="M108.516 13.9639V23.4111C108.516 25.6657 107.92 27.353 106.713 28.473C105.513 29.593 103.738 30.153 101.382 30.153C99.0253 30.153 97.2726 29.593 96.0653 28.473C94.858 27.353 94.2544 25.6657 94.2544 23.4111V13.9639H98.4362V23.4839C98.4362 24.6257 98.6835 25.4839 99.1708 26.0439C99.658 26.6111 100.4 26.8875 101.382 26.8875C102.363 26.8875 103.127 26.6039 103.622 26.0439C104.109 25.4839 104.356 24.6257 104.356 23.4839V13.9639H108.516Z" fill="white"/>
                                    <path d="M128.524 13.9633V29.9124H123.906L118.953 21.2797L117.746 18.9088H117.724L117.818 21.8542V29.9051H114.146V13.9561H118.764L123.716 22.5815L124.924 24.9524H124.946L124.851 22.007V13.9561H128.524V13.9633Z" fill="white"/>
                                    <path d="M147.884 13.9639V17.1784H142.684V29.9202H138.524V17.1784H133.302V13.9639H147.876H147.884Z" fill="white"/>
                                    <path d="M156.829 13.9639V29.913H152.669V13.9639H156.829Z" fill="white"/>
                                    <path d="M177.156 13.9633V29.9124H172.538L167.585 21.2797L166.378 18.9088H166.356L166.451 21.8542V29.9051H162.778V13.9561H167.396L172.349 22.5815L173.556 24.9524H173.578L173.484 22.007V13.9561H177.156V13.9633Z" fill="white"/>
                                    <path d="M189.702 30.16C188.284 30.16 187.025 29.8473 185.92 29.2145C184.814 28.5818 183.956 27.6582 183.345 26.4364C182.734 25.2145 182.429 23.7236 182.429 21.9636C182.429 20.2036 182.749 18.7636 183.396 17.5418C184.044 16.32 184.96 15.3745 186.167 14.7127C187.367 14.0509 188.778 13.7236 190.407 13.7236C192.211 13.7236 193.673 14.0509 194.785 14.7054C195.898 15.36 196.756 16.3927 197.374 17.8109L193.534 19.3309C193.323 18.5091 192.953 17.9127 192.422 17.5418C191.891 17.1709 191.229 16.9891 190.443 16.9891C189.658 16.9891 188.974 17.1782 188.414 17.5564C187.847 17.9345 187.425 18.4945 187.134 19.2218C186.844 19.9564 186.698 20.8582 186.698 21.9418C186.698 23.0255 186.851 24.0145 187.149 24.7709C187.447 25.5273 187.891 26.0873 188.48 26.4509C189.069 26.8145 189.796 26.9964 190.669 26.9964C191.134 26.9964 191.563 26.9382 191.949 26.8291C192.334 26.72 192.676 26.5527 192.967 26.3345C193.258 26.1164 193.484 25.8473 193.644 25.5127C193.804 25.1855 193.884 24.7927 193.884 24.3418V24.1018H190.233V21.3236H197.411V29.9273H194.582L194.269 26.1818L194.945 26.7636C194.603 27.8618 194 28.6982 193.12 29.2873C192.24 29.8764 191.113 30.1673 189.723 30.1673L189.702 30.16Z" fill="white"/>
                                    <path d="M81.7163 48.2114L83.1563 43.6805L81.7018 39.5859L76.5454 54.0878H79.84L80.8727 50.8441H85.6945L84.7636 48.2114H81.7163Z" fill="white"/>
                                    <path d="M98.1091 46.0801H92.2837V48.5964H98.1091V46.0801Z" fill="white"/>
                                    <path d="M104.407 51.4841L111.833 40.1968V38.335H100.546V40.9677H107.978L100.531 52.1822V54.0877H112V51.4841H104.407Z" fill="white"/>
                                    <path d="M81.7455 28.0586C85.9928 25.6295 88.8655 21.0623 88.8655 15.8186C88.8655 8.02952 82.5528 1.7168 74.7637 1.7168C66.9746 1.7168 60.6619 8.02952 60.6619 15.8186C60.6619 23.6077 66.9746 29.9204 74.7637 29.9204C75.92 29.9204 77.04 29.7677 78.1164 29.4986L81.7019 39.5859L83.1564 43.6732L84.771 48.2113L85.7019 50.8441L86.851 54.0877H91.1419L81.7455 28.0586ZM74.7637 25.8913C69.2 25.8913 64.691 21.3822 64.691 15.8186C64.691 10.255 69.2 5.74589 74.7637 5.74589C80.3273 5.74589 84.8364 10.255 84.8364 15.8186C84.8364 21.3822 80.3273 25.8913 74.7637 25.8913Z" fill="#BD8E16"/>
                                    <path d="M74.7419 25.9858C80.3612 25.9858 84.9165 21.4305 84.9165 15.8113C84.9165 10.192 80.3612 5.63672 74.7419 5.63672C69.1227 5.63672 64.5674 10.192 64.5674 15.8113C64.5674 21.4305 69.1227 25.9858 74.7419 25.9858Z" fill="#B3E0D9"/>
                                </svg>
                                </>
                            }
                            
                        </Link>
                            {
                                currentPathName.startsWith("/dictionary/") || currentPathName.startsWith("/openDictionary/") || currentPathName.startsWith("/basicDictionary/")
                                ?
                                <button onClick={() => setSearchBarDisplayed(true)} style={{backgroundColor:'#FDFDFD', marginRight:'30px', width:'40px', height:'40px', borderRadius:'50%'}} className={`none ${classes.toggleSearchBar}`}>
                                    {(() => {
                                        if (language === "de") {
                                            return (
                                                DELogo()
                                            )
                                        } else if (language === "fr") {
                                            return (
                                                FRLogo()
                                            )
                                        } else if (language === "it") {
                                            return (
                                                ITLogo()
                                            )
                                        } else if(language === "en"){
                                            return (
                                                
                                                ENLogo()
                                            )
                                        }
                                    })()}
                                </button>
                                :
                                null
                            }
                            
                            
                        </>
                    }</>
                    :
                    <>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Link
                            to={`/`}
                            className={'navbar-brand'}
                            style={{paddingLeft: '0'}}
                        >
                            {
                                openDictionary
                                ?
                                <>
                                <svg style={{margin: window.innerWidth > 600 ? '0 50px': '0 15px'}}  width={window.innerWidth > 600 ? "200" : "150"} height="56" viewBox="0 0 200 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M197.302 38.2327H157.302V54.2836H197.302V38.2327Z" fill="#CAD051"/>
                                    <path d="M163.665 49.2364V43.28H165.804C166.567 43.28 167.149 43.4182 167.549 43.7018C167.949 43.9855 168.145 44.3927 168.145 44.9236C168.145 45.2291 168.073 45.4982 167.935 45.7091C167.789 45.9273 167.585 46.0873 167.309 46.1891C167.622 46.2691 167.862 46.4218 168.022 46.6473C168.189 46.8727 168.269 47.1418 168.269 47.4618C168.269 48.0436 168.087 48.48 167.716 48.7782C167.345 49.0691 166.8 49.2218 166.073 49.2291H163.658L163.665 49.2364ZM165.098 45.7673H165.847C166.16 45.7673 166.385 45.7091 166.516 45.5927C166.647 45.4764 166.72 45.3164 166.72 45.0982C166.72 44.8436 166.647 44.6691 166.502 44.5527C166.356 44.4436 166.124 44.3855 165.804 44.3855H165.098V45.76V45.7673ZM165.098 46.7055V48.1309H166.036C166.298 48.1309 166.495 48.0727 166.633 47.9491C166.771 47.8327 166.844 47.6655 166.844 47.4473C166.844 46.9527 166.596 46.6982 166.102 46.6982H165.098V46.7055Z" fill="#004F3D"/>
                                    <path d="M175.105 46.7055H172.851V48.1309H175.52V49.2364H171.418V43.28H175.527V44.3855H172.851V45.6436H175.105V46.7055Z" fill="#004F3D"/>
                                    <path d="M183.2 44.3855H181.411V49.2291H179.978V44.3855H178.225V43.28H183.2V44.3855Z" fill="#004F3D"/>
                                    <path d="M189.04 48.1236H187.076L186.735 49.2364H185.2L187.382 43.28H188.735L190.931 49.2364H189.389L189.04 48.1236ZM187.418 47.0182H188.698L188.058 44.9527L187.418 47.0182Z" fill="#004F3D"/>
                                    <path d="M18.7854 29.92H14.5091L13.3964 26.32H7.93454L6.79999 29.92H2.6109L8.21817 13.9709H13.2L18.7854 29.92ZM8.70545 23.4909H12.6182L10.6618 17.04L8.70545 23.4909Z" fill="#068B77"/>
                                    <path d="M36.6182 25.3527C36.3127 26.4655 35.8182 27.3818 35.1418 28.0945C34.4655 28.8073 33.6582 29.3382 32.7273 29.6655C31.7964 29.9927 30.7636 30.16 29.6364 30.16C28.0218 30.16 26.6327 29.8327 25.4546 29.1782C24.2764 28.5236 23.3746 27.5854 22.7491 26.3636C22.1237 25.1418 21.8036 23.6655 21.8036 21.9418C21.8036 20.2182 22.1164 18.7418 22.7491 17.52C23.3746 16.2982 24.2837 15.3527 25.4546 14.7054C26.6327 14.0509 28.0146 13.7236 29.6146 13.7236C30.7273 13.7236 31.7527 13.8836 32.6836 14.1964C33.6146 14.5091 34.4073 14.9891 35.0546 15.6364C35.7018 16.2836 36.16 17.1127 36.4291 18.1236L32.7782 19.6C32.5527 18.6036 32.1891 17.9127 31.68 17.5418C31.1709 17.1709 30.5309 16.9891 29.76 16.9891C28.9891 16.9891 28.3491 17.1709 27.7891 17.5418C27.2364 17.9127 26.8073 18.4654 26.5091 19.2C26.2109 19.9345 26.0582 20.8509 26.0582 21.9418C26.0582 23.0327 26.1964 23.9345 26.4727 24.6764C26.7491 25.4182 27.1637 25.9709 27.7164 26.3418C28.2691 26.7127 28.96 26.8945 29.7818 26.8945C30.5527 26.8945 31.2146 26.6836 31.7527 26.2545C32.2909 25.8254 32.6618 25.1709 32.8509 24.2836L36.6182 25.3454V25.3527Z" fill="#068B77"/>
                                    <path d="M55.4982 25.3527C55.1927 26.4655 54.6982 27.3818 54.0218 28.0945C53.3455 28.8073 52.5382 29.3382 51.6073 29.6655C50.6764 29.9927 49.6437 30.16 48.5164 30.16C46.9018 30.16 45.5127 29.8327 44.3346 29.1782C43.1564 28.5236 42.2546 27.5854 41.6291 26.3636C41.0037 25.1418 40.6837 23.6655 40.6837 21.9418C40.6837 20.2182 40.9964 18.7418 41.6291 17.52C42.2546 16.2982 43.1637 15.3527 44.3346 14.7054C45.5127 14.0509 46.8946 13.7236 48.4946 13.7236C49.6073 13.7236 50.6327 13.8836 51.5637 14.1964C52.4946 14.5091 53.2873 14.9891 53.9346 15.6364C54.5818 16.2836 55.04 17.1127 55.3091 18.1236L51.6582 19.6C51.4327 18.6036 51.0691 17.9127 50.56 17.5418C50.0509 17.1709 49.4109 16.9891 48.64 16.9891C47.8691 16.9891 47.2291 17.1709 46.6691 17.5418C46.1164 17.9127 45.6873 18.4654 45.3891 19.2C45.0909 19.9345 44.9382 20.8509 44.9382 21.9418C44.9382 23.0327 45.0764 23.9345 45.3527 24.6764C45.6291 25.4182 46.0437 25.9709 46.5964 26.3418C47.1491 26.7127 47.84 26.8945 48.6618 26.8945C49.4327 26.8945 50.0946 26.6836 50.6327 26.2545C51.1709 25.8254 51.5418 25.1709 51.7309 24.2836L55.4982 25.3454V25.3527Z" fill="#068B77"/>
                                    <path d="M108.516 13.9636V23.4109C108.516 25.6654 107.92 27.3527 106.713 28.4727C105.513 29.5927 103.738 30.1527 101.382 30.1527C99.0254 30.1527 97.2727 29.5927 96.0654 28.4727C94.8581 27.3527 94.2545 25.6654 94.2545 23.4109V13.9636H98.4363V23.4836C98.4363 24.6254 98.6836 25.4836 99.1709 26.0436C99.6581 26.6109 100.4 26.8873 101.382 26.8873C102.364 26.8873 103.127 26.6036 103.622 26.0436C104.109 25.4836 104.356 24.6254 104.356 23.4836V13.9636H108.516Z" fill="#068B77"/>
                                    <path d="M128.524 13.9636V29.9127H123.905L118.953 21.28L117.745 18.9091H117.724L117.818 21.8545V29.9054H114.145V13.9564H118.764L123.716 22.5818L124.924 24.9527H124.945L124.851 22.0073V13.9564H128.524V13.9636Z" fill="#068B77"/>
                                    <path d="M147.884 13.9636V17.1782H142.684V29.92H138.524V17.1782H133.302V13.9636H147.876H147.884Z" fill="#068B77"/>
                                    <path d="M156.829 13.9636V29.9127H152.669V13.9636H156.829Z" fill="#068B77"/>
                                    <path d="M177.156 13.9636V29.9127H172.538L167.585 21.28L166.378 18.9091H166.356L166.451 21.8545V29.9054H162.778V13.9564H167.396L172.349 22.5818L173.556 24.9527H173.578L173.484 22.0073V13.9564H177.156V13.9636Z" fill="#068B77"/>
                                    <path d="M189.702 30.16C188.284 30.16 187.025 29.8473 185.92 29.2145C184.815 28.5818 183.956 27.6582 183.345 26.4364C182.735 25.2145 182.429 23.7236 182.429 21.9636C182.429 20.2036 182.749 18.7636 183.396 17.5418C184.044 16.32 184.96 15.3745 186.167 14.7127C187.367 14.0509 188.778 13.7236 190.407 13.7236C192.211 13.7236 193.673 14.0509 194.785 14.7054C195.898 15.36 196.756 16.3927 197.375 17.8109L193.535 19.3309C193.324 18.5091 192.953 17.9127 192.422 17.5418C191.891 17.1709 191.229 16.9891 190.444 16.9891C189.658 16.9891 188.975 17.1782 188.415 17.5564C187.847 17.9345 187.425 18.4945 187.135 19.2218C186.844 19.9564 186.698 20.8582 186.698 21.9418C186.698 23.0255 186.851 24.0145 187.149 24.7709C187.447 25.5273 187.891 26.0873 188.48 26.4509C189.069 26.8145 189.796 26.9964 190.669 26.9964C191.135 26.9964 191.564 26.9382 191.949 26.8291C192.335 26.72 192.676 26.5527 192.967 26.3345C193.258 26.1164 193.484 25.8473 193.644 25.5127C193.804 25.1855 193.884 24.7927 193.884 24.3418V24.1018H190.233V21.3236H197.411V29.9273H194.582L194.269 26.1818L194.945 26.7636C194.604 27.8618 194 28.6982 193.12 29.2873C192.24 29.8764 191.113 30.1673 189.724 30.1673L189.702 30.16Z" fill="#068B77"/>
                                    <path d="M81.7163 48.2109L83.1563 43.68L81.7018 39.5854L76.5454 54.0873H79.84L80.8727 50.8436H85.6945L84.7636 48.2109H81.7163Z" fill="#068B77"/>
                                    <path d="M98.1091 46.08H92.2836V48.5964H98.1091V46.08Z" fill="#068B77"/>
                                    <path d="M104.407 51.4836L111.833 40.1964V38.3345H100.545V40.9673H107.978L100.531 52.1818V54.0873H112V51.4836H104.407Z" fill="#068B77"/>
                                    <path d="M81.7454 28.0582C85.9927 25.6291 88.8654 21.0618 88.8654 15.8182C88.8654 8.0291 82.5527 1.71637 74.7636 1.71637C66.9745 1.71637 60.6618 8.0291 60.6618 15.8182C60.6618 23.6073 66.9745 29.92 74.7636 29.92C75.92 29.92 77.04 29.7673 78.1163 29.4982L81.7018 39.5855L83.1563 43.6727L84.7709 48.2109L85.7018 50.8436L86.8509 54.0873H91.1418L81.7454 28.0582ZM74.7636 25.8909C69.2 25.8909 64.6909 21.3818 64.6909 15.8182C64.6909 10.2546 69.2 5.74546 74.7636 5.74546C80.3273 5.74546 84.8363 10.2546 84.8363 15.8182C84.8363 21.3818 80.3273 25.8909 74.7636 25.8909Z" fill="#BD8E16"/>
                                    <path d="M74.7418 25.9854C80.3611 25.9854 84.9164 21.4301 84.9164 15.8109C84.9164 10.1917 80.3611 5.63635 74.7418 5.63635C69.1226 5.63635 64.5673 10.1917 64.5673 15.8109C64.5673 21.4301 69.1226 25.9854 74.7418 25.9854Z" fill="#B3E0D9"/>
                                </svg>
                                </>
                                :
                                <>
                                <svg style={{margin: window.innerWidth > 600 ? '0 50px': '0 15px'}}  width={window.innerWidth > 600 ? "200" : "150"} height="56" viewBox="0 0 200 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M197.302 38.2324H157.302V54.2833H197.302V38.2324Z" fill="#CAD051"/>
                                    <path d="M163.665 49.2366V43.2803H165.804C166.567 43.2803 167.149 43.4185 167.549 43.7021C167.949 43.9857 168.145 44.393 168.145 44.9239C168.145 45.2294 168.073 45.4985 167.935 45.7094C167.789 45.9275 167.585 46.0875 167.309 46.1894C167.622 46.2694 167.862 46.4221 168.022 46.6475C168.189 46.873 168.269 47.1421 168.269 47.4621C168.269 48.0439 168.087 48.4803 167.716 48.7785C167.345 49.0694 166.8 49.2221 166.073 49.2294H163.658L163.665 49.2366ZM165.098 45.7675H165.847C166.16 45.7675 166.385 45.7094 166.516 45.593C166.647 45.4766 166.72 45.3166 166.72 45.0985C166.72 44.8439 166.647 44.6694 166.502 44.553C166.356 44.4439 166.124 44.3857 165.804 44.3857H165.098V45.7603V45.7675ZM165.098 46.7057V48.1312H166.036C166.298 48.1312 166.495 48.073 166.633 47.9494C166.771 47.833 166.844 47.6657 166.844 47.4475C166.844 46.953 166.596 46.6985 166.102 46.6985H165.098V46.7057Z" fill="#004F3D"/>
                                    <path d="M175.105 46.7057H172.851V48.1312H175.52V49.2366H171.418V43.2803H175.527V44.3857H172.851V45.6439H175.105V46.7057Z" fill="#004F3D"/>
                                    <path d="M183.2 44.3857H181.411V49.2294H179.978V44.3857H178.226V43.2803H183.2V44.3857Z" fill="#004F3D"/>
                                    <path d="M189.04 48.1239H187.076L186.735 49.2366H185.2L187.382 43.2803H188.735L190.931 49.2366H189.389L189.04 48.1239ZM187.418 47.0185H188.698L188.058 44.953L187.418 47.0185Z" fill="#004F3D"/>
                                    <path d="M18.7854 29.9198H14.509L13.3963 26.3198H7.93448L6.79993 29.9198H2.61084L8.21811 13.9707H13.1999L18.7854 29.9198ZM8.70539 23.4907H12.6181L10.6617 17.0398L8.70539 23.4907Z" fill="white"/>
                                    <path d="M36.618 25.3527C36.3126 26.4655 35.818 27.3818 35.1416 28.0945C34.4653 28.8073 33.658 29.3382 32.7271 29.6655C31.7962 29.9927 30.7635 30.16 29.6362 30.16C28.0216 30.16 26.6326 29.8327 25.4544 29.1782C24.2762 28.5236 23.3744 27.5854 22.7489 26.3636C22.1235 25.1418 21.8035 23.6655 21.8035 21.9418C21.8035 20.2182 22.1162 18.7418 22.7489 17.52C23.3744 16.2982 24.2835 15.3527 25.4544 14.7054C26.6326 14.0509 28.0144 13.7236 29.6144 13.7236C30.7271 13.7236 31.7526 13.8836 32.6835 14.1964C33.6144 14.5091 34.4071 14.9891 35.0544 15.6364C35.7016 16.2836 36.1598 17.1127 36.4289 18.1236L32.778 19.6C32.5526 18.6036 32.1889 17.9127 31.6798 17.5418C31.1707 17.1709 30.5307 16.9891 29.7598 16.9891C28.9889 16.9891 28.3489 17.1709 27.7889 17.5418C27.2362 17.9127 26.8071 18.4654 26.5089 19.2C26.2107 19.9345 26.058 20.8509 26.058 21.9418C26.058 23.0327 26.1962 23.9345 26.4726 24.6764C26.7489 25.4182 27.1635 25.9709 27.7162 26.3418C28.2689 26.7127 28.9598 26.8945 29.7816 26.8945C30.5526 26.8945 31.2144 26.6836 31.7526 26.2545C32.2907 25.8254 32.6616 25.1709 32.8507 24.2836L36.618 25.3454V25.3527Z" fill="white"/>
                                    <path d="M55.4981 25.3527C55.1927 26.4655 54.6981 27.3818 54.0218 28.0945C53.3454 28.8073 52.5381 29.3382 51.6072 29.6655C50.6763 29.9927 49.6436 30.16 48.5163 30.16C46.9018 30.16 45.5127 29.8327 44.3345 29.1782C43.1563 28.5236 42.2545 27.5854 41.6291 26.3636C41.0036 25.1418 40.6836 23.6655 40.6836 21.9418C40.6836 20.2182 40.9963 18.7418 41.6291 17.52C42.2545 16.2982 43.1636 15.3527 44.3345 14.7054C45.5127 14.0509 46.8945 13.7236 48.4945 13.7236C49.6072 13.7236 50.6327 13.8836 51.5636 14.1964C52.4945 14.5091 53.2872 14.9891 53.9345 15.6364C54.5818 16.2836 55.04 17.1127 55.3091 18.1236L51.6581 19.6C51.4327 18.6036 51.069 17.9127 50.56 17.5418C50.0509 17.1709 49.4109 16.9891 48.64 16.9891C47.869 16.9891 47.229 17.1709 46.669 17.5418C46.1163 17.9127 45.6872 18.4654 45.389 19.2C45.0909 19.9345 44.9381 20.8509 44.9381 21.9418C44.9381 23.0327 45.0763 23.9345 45.3527 24.6764C45.629 25.4182 46.0436 25.9709 46.5963 26.3418C47.149 26.7127 47.84 26.8945 48.6618 26.8945C49.4327 26.8945 50.0945 26.6836 50.6327 26.2545C51.1709 25.8254 51.5418 25.1709 51.7309 24.2836L55.4981 25.3454V25.3527Z" fill="white"/>
                                    <path d="M108.516 13.9639V23.4111C108.516 25.6657 107.92 27.353 106.713 28.473C105.513 29.593 103.738 30.153 101.382 30.153C99.0253 30.153 97.2726 29.593 96.0653 28.473C94.858 27.353 94.2544 25.6657 94.2544 23.4111V13.9639H98.4362V23.4839C98.4362 24.6257 98.6835 25.4839 99.1708 26.0439C99.658 26.6111 100.4 26.8875 101.382 26.8875C102.363 26.8875 103.127 26.6039 103.622 26.0439C104.109 25.4839 104.356 24.6257 104.356 23.4839V13.9639H108.516Z" fill="white"/>
                                    <path d="M128.524 13.9633V29.9124H123.906L118.953 21.2797L117.746 18.9088H117.724L117.818 21.8542V29.9051H114.146V13.9561H118.764L123.716 22.5815L124.924 24.9524H124.946L124.851 22.007V13.9561H128.524V13.9633Z" fill="white"/>
                                    <path d="M147.884 13.9639V17.1784H142.684V29.9202H138.524V17.1784H133.302V13.9639H147.876H147.884Z" fill="white"/>
                                    <path d="M156.829 13.9639V29.913H152.669V13.9639H156.829Z" fill="white"/>
                                    <path d="M177.156 13.9633V29.9124H172.538L167.585 21.2797L166.378 18.9088H166.356L166.451 21.8542V29.9051H162.778V13.9561H167.396L172.349 22.5815L173.556 24.9524H173.578L173.484 22.007V13.9561H177.156V13.9633Z" fill="white"/>
                                    <path d="M189.702 30.16C188.284 30.16 187.025 29.8473 185.92 29.2145C184.814 28.5818 183.956 27.6582 183.345 26.4364C182.734 25.2145 182.429 23.7236 182.429 21.9636C182.429 20.2036 182.749 18.7636 183.396 17.5418C184.044 16.32 184.96 15.3745 186.167 14.7127C187.367 14.0509 188.778 13.7236 190.407 13.7236C192.211 13.7236 193.673 14.0509 194.785 14.7054C195.898 15.36 196.756 16.3927 197.374 17.8109L193.534 19.3309C193.323 18.5091 192.953 17.9127 192.422 17.5418C191.891 17.1709 191.229 16.9891 190.443 16.9891C189.658 16.9891 188.974 17.1782 188.414 17.5564C187.847 17.9345 187.425 18.4945 187.134 19.2218C186.844 19.9564 186.698 20.8582 186.698 21.9418C186.698 23.0255 186.851 24.0145 187.149 24.7709C187.447 25.5273 187.891 26.0873 188.48 26.4509C189.069 26.8145 189.796 26.9964 190.669 26.9964C191.134 26.9964 191.563 26.9382 191.949 26.8291C192.334 26.72 192.676 26.5527 192.967 26.3345C193.258 26.1164 193.484 25.8473 193.644 25.5127C193.804 25.1855 193.884 24.7927 193.884 24.3418V24.1018H190.233V21.3236H197.411V29.9273H194.582L194.269 26.1818L194.945 26.7636C194.603 27.8618 194 28.6982 193.12 29.2873C192.24 29.8764 191.113 30.1673 189.723 30.1673L189.702 30.16Z" fill="white"/>
                                    <path d="M81.7163 48.2114L83.1563 43.6805L81.7018 39.5859L76.5454 54.0878H79.84L80.8727 50.8441H85.6945L84.7636 48.2114H81.7163Z" fill="white"/>
                                    <path d="M98.1091 46.0801H92.2837V48.5964H98.1091V46.0801Z" fill="white"/>
                                    <path d="M104.407 51.4841L111.833 40.1968V38.335H100.546V40.9677H107.978L100.531 52.1822V54.0877H112V51.4841H104.407Z" fill="white"/>
                                    <path d="M81.7455 28.0586C85.9928 25.6295 88.8655 21.0623 88.8655 15.8186C88.8655 8.02952 82.5528 1.7168 74.7637 1.7168C66.9746 1.7168 60.6619 8.02952 60.6619 15.8186C60.6619 23.6077 66.9746 29.9204 74.7637 29.9204C75.92 29.9204 77.04 29.7677 78.1164 29.4986L81.7019 39.5859L83.1564 43.6732L84.771 48.2113L85.7019 50.8441L86.851 54.0877H91.1419L81.7455 28.0586ZM74.7637 25.8913C69.2 25.8913 64.691 21.3822 64.691 15.8186C64.691 10.255 69.2 5.74589 74.7637 5.74589C80.3273 5.74589 84.8364 10.255 84.8364 15.8186C84.8364 21.3822 80.3273 25.8913 74.7637 25.8913Z" fill="#BD8E16"/>
                                    <path d="M74.7419 25.9858C80.3612 25.9858 84.9165 21.4305 84.9165 15.8113C84.9165 10.192 80.3612 5.63672 74.7419 5.63672C69.1227 5.63672 64.5674 10.192 64.5674 15.8113C64.5674 21.4305 69.1227 25.9858 74.7419 25.9858Z" fill="#B3E0D9"/>
                                </svg>
                                </>
                            }
                            
                        </Link>
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                        {
                            search === '/dictionary/:id' || search === '/openDictionary/:id' || search === '/basicDictionary/:id'
                            ?
                            <>
                            <div style={{width:'350px'}} className={classes.search}>
                                <div style={{display:'grid'}}>
                                    <div>
                                    <div style={{color: openDictionary?'#BD8F16':basicDictionary?'white':'white'}} className={classes.searchLanguage} >
                                        {pageContent.Currently_searching_in_lang}
                                        {
                                            language === 'de' 
                                            ? 
                                            <div style={{display: 'flex'}}>
                                                <span style={{fontWeight: '600'}}> &nbsp;{pageContent.lang_name_de}</span> 
                                                <svg style={{width:'25px', marginLeft:'5px'}} xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" viewBox="0 0 640 480">
                                                    <path fill="#ffce00" d="M0 320h640v160H0z"/>
                                                    <path fill="#000001" d="M0 0h640v160H0z"/>
                                                    <path fill="red" d="M0 160h640v160H0z"/>
                                                </svg>
                                            </div>
                                            : 
                                            null 
                                        }
                                        {
                                            language === 'fr' 
                                            ? 
                                            <div style={{display: 'flex'}}>
                                                <span style={{fontWeight: '600'}}> &nbsp;{pageContent.lang_name_fr}</span> 
                                                <svg style={{width:'25px', marginLeft:'5px'}} xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 640 480">
                                                    <path fill="#fff" d="M0 0h640v480H0z"/>
                                                    <path fill="#000091" d="M0 0h213.3v480H0z"/>
                                                    <path fill="#e1000f" d="M426.7 0H640v480H426.7z"/>
                                                </svg>
                                            </div>
                                            : 
                                            null 
                                        }
                                        {
                                            language === 'it' 
                                            ? 
                                            <div style={{display: 'flex'}}>
                                                <span style={{fontWeight: '600'}}> &nbsp;{pageContent.lang_name_it}</span> 
                                                <svg style={{width:'25px', marginLeft:'5px'}} xmlns="http://www.w3.org/2000/svg" id="flag-icons-it" viewBox="0 0 640 480">
                                                    <g  fill-rule="evenodd" stroke-width="1pt">
                                                        <path fill="#fff" d="M0 0h640v480H0z"/>
                                                        <path fill="#009246" d="M0 0h213.3v480H0z"/>
                                                        <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            : 
                                            null 
                                        }
                                        {
                                            language === 'en' ?
                                            <div style={{display: 'flex'}}>
                                                <span style={{fontWeight: '600'}}> &nbsp;{pageContent.lang_name_en}</span> 
                                                <svg  style={{width:'25px', marginLeft:'5px'}} xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
                                                    <path fill="#012169" d="M0 0h640v480H0z"/>
                                                    <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"/>
                                                    <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"/>
                                                    <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z"/>
                                                    <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z"/>
                                                </svg>
                                            </div>
                                            : 
                                            null 
                                        }
                                    </div>
                                    </div>
                                    <input
                                        id='search'
                                        placeholder={`${pageContent.search_button}`}
                                        onFocus={() => onFocus1()}
                                        onBlur={() => onBlur1()}
                                        onKeyUp={() => {
                                            getTermsWithPredictive(document.getElementById('search').value); 
                                            setCurrentSearch(document.getElementById('search').value);
                                            
                                        }}
                                        autocomplete="off"
                                        style={{width: window.innerWidth < 600 ? '185px' : '275px', paddingRight: '75px'}}

                                    /> 
                                </div>
                                 
                            <button onClick={() => {onClick3(document.getElementById('search').value); onClick5(document.getElementById('search').value)}} style={{width:'unset', padding: '0 10px', marginLeft: '5px'}} className='btn ochre'>{pageContent.search_button}</button>   
                            </div>
                            <>{
                                options
                                ?  
                                <>
                                
                                <div ref={ref2} className={classes.searchSelection}  style={{width: mobile ? '100vw' : '400px', height:displayedPredicted?'230px':'200px', backgroundColor: 'rgba(246, 249, 229, 0.95)', position: 'absolute', padding: '15px 30px', borderRadius:mobile?'unset': '10px', right: mobile ? '0' : 'unset', top:`${serachYPosition}px`, boxShadow: '0px 5px 3px rgb(0 0 0 / 25%)'}}>

                                    <Select  
                                        pageContent={pageContent}
                                        // selectedOption={language}
                                        // openDictionary
                                        disabled={openDictionary?true:false}
                                        onSelected={(value) => {
                                            onChange1(getKeyByValue(LANGUAGES, value)); 
                                            setLanguage(value)
                                            setReloaded(false)
                                            

                                        }}  
                                        selectedOption={ 
                                            (() => {
                                                if (language === 'de') {
                                                    return (
                                                        `${pageContent.lang_name_de}`
                                                    )
                                                } else if (language === 'fr') {
                                                    return (
                                                        `${pageContent.lang_name_fr}`
                                                    )
                                                } else if (language === 'it') {
                                                    return (
                                                        `${pageContent.lang_name_it}`
                                                    )
                                                } else if (language === 'en') {
                                                    return (
                                                        `${pageContent.lang_name_en}`
                                                    )
                                                }
                                            })()
                                        }
                                        options={Object.values(LANGUAGES)} 
                                    />
                                    {
                                        displayedPredicted
                                        ?
                                        <>
                                        <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>
                                            {/* {
                                                language === "de"
                                                ?
                                                <>Suchen Sie nach Begriffen, die mit beginnen </>
                                                :
                                                <>Search for terms starting with </>
                                            } */}
                                            <>{pageContent.predictive_search_text} </>
                                            <span style={{fontStyle:'italic'}}>{currentSearch}</span>
                                        </div>
                                        {
                                            isLoadingPredictedTerms
                                            ?
                                            <div style={{display:'flex', justifyContent:'center', padding: '10px 0'}}>
                                                <div className="loader"></div>
                                            </div>
                                            :
                                            <>
                                            {
                                                Object.keys(predictedTerms).length===0
                                                ?
                                                <div style={{width: '250px', color: '#F33757', fontFamily:"'Roboto', sans-serif"}}>
                                                    
                                                    {/* {
                                                        language === "de"
                                                        ?
                                                        <>Leider konnten wir keine Übereinstimmungen finden.</>
                                                        :
                                                        <>Sorry we couldn't find any matches.</>
                                                    } */}
                                                    {pageContent.no_matches_header}
                                                </div>
                                                :
                                                <></>
                                            }
                                            {predictedTerms.map(t => (
                                            
                                            <div style={{display:'flex', alignItems: 'center', marginBottom:'5px'}}>
                                                <Link 
                                                    id={t.id} 
                                                    onClick={() => {
                                                        sendConsultedTermMetric(t.id)
                                                        getMeaning(t.id); 
                                                        
                                                        setLink([`/dictionary/${t.id}`, t.attributes.text, t.attributes.locale]);
                                                        setTermNotSelected(false)
                                                        let currentLetters, currentLetter;
                                                        setTermVisible(t.attributes.text.slice(0, 4));
                                                        currentLetter = t.attributes.text.slice(0, 1).toUpperCase() ;
                                                        console.log(t.attributes.text)
                                                      
                                                        
                                                        if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                            currentLetters = ["A", "B", "C"]
                                                        } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                            currentLetters = ["D", "E", "F"]
                                                        } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                            currentLetters = ["G", "H", "I"]
                                                        }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                            currentLetters = ["J", "K", "L"]
                                                        }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                            currentLetters = ["M", "N", "O"]
                                                        }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                            currentLetters = ["P", "Q", "R"]
                                                        }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                            currentLetters = ["S", "T", "U"]
                                                        }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                            currentLetters = ["V", "W", "X"]
                                                        }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                            currentLetters = ["Y", "Z"]
                                                        }
                                                        setCurrentLetters(currentLetters);
                                                        setReloaded(false)
                                                        
                                                        
                                                        setTimeout(() => {
                                                            if (document.getElementById(`${t.attributes.text}`) ===  null){
                                                                setTimeout(() => {
                                                                    document.getElementById(`${t.attributes.text}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }, 3000);
                                                                
                                                            } else {
                                                                document.getElementById(`${t.attributes.text}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                            }
                                                        }, 1500);
                                                        
                                                        
                                                    }} 
                                                    className={'link'} 
                                                    to={openDictionary ? `/openDictionary/${t.id}`: basicDictionary ? `/basicDictionary/${t.id}` : `/dictionary/${t.id}` }
                                                >
                                                    {/* {console.log(t.attributes)} */}
                                                    {
                                                        
                                                        t.attributes.text
                                                    
                                                    }
                                    
                                                </Link>
                                                {
                                                    t.attributes.free === true && (openDictionary || basicDictionary)
                                                    ?
                                                    <span className={classes.openTerm}>book preview</span>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            
                                            ))}
                                            </>
                                        }
                                        
                                        </>
                                        :
                                        
                                        <>
                                        {
                                            isLoadingPredictedTerms
                                            ?
                                            <>
                                            <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>
                                                {/* {
                                                    language === "de"
                                                    ?
                                                    <>Suchen Sie nach Begriffen, die mit beginnen </>
                                                    :
                                                    <>Search for terms starting with </>
                                                } */}
                                                {pageContent.predictive_search_text}
                                                <span style={{fontStyle:'italic'}}>{currentSearch}</span>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', padding: '10px 0'}}>
                                                <div className="loader"></div>
                                            </div>
                                            </>
                                            : 
                                            <></>
                                        }
                                        </>
                                    }   

                                    {/* <div>
                                        
                                        {
                                            lastVisited[3] !== ""
                                            ?
                                            <>
                                            <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>Recent research</div>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Link 
                                                    onClick={() => {
                                                        openDictionary ? getMeaning(lastVisited.flat()[3].slice(15)) : basicDictionary ? getMeaning(lastVisited.flat()[3].slice(16)) : getMeaning(lastVisited.flat()[3].slice(11))
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                        setTermVisible(lastVisited.flat()[4].slice(0, 4));

                                                        let currentLetters, currentLetter;

                                                        currentLetter = lastVisited.flat()[4].slice(0, 1).toUpperCase() ;
                                                      
                                                        
                                                        if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                            currentLetters = ["A", "B", "C"]
                                                        } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                            currentLetters = ["D", "E", "F"]
                                                        } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                            currentLetters = ["G", "H", "I"]
                                                        }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                            currentLetters = ["J", "K", "L"]
                                                        }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                            currentLetters = ["M", "N", "O"]
                                                        }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                            currentLetters = ["P", "Q", "R"]
                                                        }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                            currentLetters = ["S", "T", "U"]
                                                        }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                            currentLetters = ["V", "W", "X"]
                                                        }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                            currentLetters = ["Y", "Z"]
                                                        }
                                                        setCurrentLetters(currentLetters);
                                                        setReloaded(false)
                                                        setTimeout(() => {
                                                            if (document.getElementById(`${lastVisited.flat()[4]}`) ===  null){
                                                                setTimeout(() => {
                                                                    document.getElementById(`${lastVisited.flat()[4]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }, 3000);
                                                                
                                                            } else {
                                                                document.getElementById(`${lastVisited.flat()[4]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                            }
                                                        }, 1500);
                                                    }} 
                                                    className={'link'} 
                                                    to={openDictionary ? `/openDictionary/${lastVisited.flat()[3].slice(15)}`: basicDictionary ? `/basicDictionary/${lastVisited.flat()[3].slice(16)}` : `/dictionary/${lastVisited.flat()[3].slice(11)}` }
                                                >
                                                    {lastVisited.flat()[4]}
                                                    
                                                </Link>
                                                <span className={classes.languageOfSearchedTerm}>{lastVisited[5]}</span>
                                            </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {
                                            lastVisited[6] !== ""
                                            ?
                                            <>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Link 
                                                    onClick={() => {
                                                        openDictionary ? getMeaning(lastVisited.flat()[6].slice(15)) : basicDictionary ? getMeaning(lastVisited.flat()[6].slice(16)) : getMeaning(lastVisited.flat()[6].slice(11))
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                        setTermVisible(lastVisited.flat()[7].slice(0, 4));

                                                        let currentLetters, currentLetter;

                                                        currentLetter = lastVisited.flat()[7].slice(0, 1).toUpperCase() ;
                                                      
                                                        
                                                        if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                            currentLetters = ["A", "B", "C"]
                                                        } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                            currentLetters = ["D", "E", "F"]
                                                        } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                            currentLetters = ["G", "H", "I"]
                                                        }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                            currentLetters = ["J", "K", "L"]
                                                        }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                            currentLetters = ["M", "N", "O"]
                                                        }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                            currentLetters = ["P", "Q", "R"]
                                                        }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                            currentLetters = ["S", "T", "U"]
                                                        }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                            currentLetters = ["V", "W", "X"]
                                                        }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                            currentLetters = ["Y", "Z"]
                                                        }
                                                        setCurrentLetters(currentLetters);
                                                        setReloaded(false)
                                                        setTimeout(() => {
                                                            if (document.getElementById(`${lastVisited.flat()[7]}`) ===  null){
                                                                setTimeout(() => {
                                                                    document.getElementById(`${lastVisited.flat()[7]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }, 3000);
                                                                
                                                            } else {
                                                                document.getElementById(`${lastVisited.flat()[7]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                            }
                                                        }, 1500);
                                                    }} 
                                                    className={'link'} 
                                                    to={openDictionary ? `/openDictionary/${lastVisited.flat()[6].slice(15)}`: basicDictionary ? `/basicDictionary/${lastVisited.flat()[6].slice(16)}` : `/dictionary/${lastVisited.flat()[6].slice(11)}` }
                                                >
                                                    {lastVisited.flat()[7]}
                                                </Link>
                                                <span className={classes.languageOfSearchedTerm}>{lastVisited[8]}</span>
                                            </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {
                                            lastVisited[9] !== ""
                                            ?
                                            <>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Link 
                                                    onClick={() => {
                                                        openDictionary ? getMeaning(lastVisited.flat()[9].slice(15)) : basicDictionary ? getMeaning(lastVisited.flat()[9].slice(16)) : getMeaning(lastVisited.flat()[9].slice(11))
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                        setTermVisible(lastVisited.flat()[10].slice(0, 4));
                                                        let currentLetters, currentLetter;

                                                        currentLetter = lastVisited.flat()[10].slice(0, 1).toUpperCase() ;
                                                      
                                                        
                                                        if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                            currentLetters = ["A", "B", "C"]
                                                        } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                            currentLetters = ["D", "E", "F"]
                                                        } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                            currentLetters = ["G", "H", "I"]
                                                        }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                            currentLetters = ["J", "K", "L"]
                                                        }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                            currentLetters = ["M", "N", "O"]
                                                        }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                            currentLetters = ["P", "Q", "R"]
                                                        }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                            currentLetters = ["S", "T", "U"]
                                                        }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                            currentLetters = ["V", "W", "X"]
                                                        }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                            currentLetters = ["Y", "Z"]
                                                        }
                                                        setCurrentLetters(currentLetters);
                                                        setReloaded(false)
                                                        setTimeout(() => {
                                                            if (document.getElementById(`${lastVisited.flat()[10]}`) ===  null){
                                                                setTimeout(() => {
                                                                    document.getElementById(`${lastVisited.flat()[10]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }, 3000);
                                                                
                                                            } else {
                                                                document.getElementById(`${lastVisited.flat()[10]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                            }
                                                        }, 1500);
                                                    }} 
                                                    className={'link'} 
                                                    to={openDictionary ? `/openDictionary/${lastVisited.flat()[9].slice(15)}`: basicDictionary ? `/basicDictionary/${lastVisited.flat()[9].slice(16)}` : `/dictionary/${lastVisited.flat()[9].slice(11)}` }
                                                >
                                                    {lastVisited.flat()[10]}
                                                </Link>
                                                <span className={classes.languageOfSearchedTerm}>{lastVisited[11]}</span>
                                            </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {
                                            lastVisited[12] !== ""
                                            ?
                                            <>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Link 
                                                    onClick={() => {
                                                        openDictionary ? getMeaning(lastVisited.flat()[12].slice(15)) : basicDictionary ? getMeaning(lastVisited.flat()[12].slice(16)) : getMeaning(lastVisited.flat()[12].slice(11))
                                                        setTermNotSelected(false)
                                                        onSelectPreviosSearch(false)
                                                        setTermVisible(lastVisited.flat()[13].slice(0, 4));
                                                        let currentLetters, currentLetter;

                                                        currentLetter = lastVisited.flat()[13].slice(0, 1).toUpperCase() ;
                                                      
                                                        
                                                        if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                            currentLetters = ["A", "B", "C"]
                                                        } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                            currentLetters = ["D", "E", "F"]
                                                        } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                            currentLetters = ["G", "H", "I"]
                                                        }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                            currentLetters = ["J", "K", "L"]
                                                        }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                            currentLetters = ["M", "N", "O"]
                                                        }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                            currentLetters = ["P", "Q", "R"]
                                                        }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                            currentLetters = ["S", "T", "U"]
                                                        }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                            currentLetters = ["V", "W", "X"]
                                                        }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                            currentLetters = ["Y", "Z"]
                                                        }
                                                        setCurrentLetters(currentLetters);
                                                        setReloaded(false)
                                                        setTimeout(() => {
                                                            if (document.getElementById(`${lastVisited.flat()[13]}`) ===  null){
                                                                setTimeout(() => {
                                                                    document.getElementById(`${lastVisited.flat()[13]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }, 3000);
                                                                
                                                            } else {
                                                                document.getElementById(`${lastVisited.flat()[13]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                            }
                                                        }, 1500);
                                                    }} 
                                                    className={'link'} 
                                                    to={openDictionary ? `/openDictionary/${lastVisited.flat()[12].slice(15)}`: basicDictionary ? `/basicDictionary/${lastVisited.flat()[12].slice(16)}` : `/dictionary/${lastVisited.flat()[12].slice(11)}` }
                                                >
                                                    {lastVisited.flat()[13]}
                                                </Link>
                                                <span className={classes.languageOfSearchedTerm}>{lastVisited[14]}</span>
                                            </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {
                                            lastVisited[15] !== ""
                                            ?
                                            <>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Link 
                                                    onClick={() => {
                                                        openDictionary ? getMeaning(lastVisited.flat()[15].slice(15)) : basicDictionary ? getMeaning(lastVisited.flat()[15].slice(16)) : getMeaning(lastVisited.flat()[15].slice(11))
                                                        setTermNotSelected(false);
                                                        onSelectPreviosSearch(false)
                                                        setTermVisible(lastVisited.flat()[16].slice(0, 4));
                                                        let currentLetters, currentLetter;

                                                        currentLetter = lastVisited.flat()[16].slice(0, 1).toUpperCase() ;
                                                      
                                                        
                                                        if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C'){
                                                            currentLetters = ["A", "B", "C"]
                                                        } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F') {
                                                            currentLetters = ["D", "E", "F"]
                                                        } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I') {
                                                            currentLetters = ["G", "H", "I"]
                                                        }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L') {
                                                            currentLetters = ["J", "K", "L"]
                                                        }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O') {
                                                            currentLetters = ["M", "N", "O"]
                                                        }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R') {
                                                            currentLetters = ["P", "Q", "R"]
                                                        }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U') {
                                                            currentLetters = ["S", "T", "U"]
                                                        }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X') {
                                                            currentLetters = ["V", "W", "X"]
                                                        }else if (currentLetter === 'Y' ||  currentLetter==='Z') {
                                                            currentLetters = ["Y", "Z"]
                                                        }
                                                        setCurrentLetters(currentLetters);
                                                        setReloaded(false)
                                                        setTimeout(() => {
                                                            if (document.getElementById(`${lastVisited.flat()[16]}`) ===  null){
                                                                setTimeout(() => {
                                                                    document.getElementById(`${lastVisited.flat()[16]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                                }, 3000);
                                                                
                                                            } else {
                                                                document.getElementById(`${lastVisited.flat()[16]}`).parentElement.parentElement.parentElement.scrollIntoView({behavior: "smooth"})
                                                            }
                                                        }, 1500);
                                                    }} 
                                                    className={'link'} 
                                                    to={openDictionary ? `/openDictionary/${lastVisited.flat()[15].slice(15)}`: basicDictionary ? `/basicDictionary/${lastVisited.flat()[15].slice(16)}` : `/dictionary/${lastVisited.flat()[15].slice(11)}` }
                                                >
                                                    {lastVisited.flat()[16]}
                                                </Link>
                                                <span className={classes.languageOfSearchedTerm}>{lastVisited[17]}</span>
                                            </div>
                                            </>
                                            :
                                            <></>
                                        }
                                    </div> */}
                                </div>
                            
                                </>
                                :
                                <>
                                
                                {
                                    isLoadingPredictedTerms
                                    ?
                                    <>
                                    <div  style={{color: '#004F3D', fontFamily: 'Work Sans', padding: '5px 0'}}>
                                        {/* {
                                            language === "de"
                                            ?
                                            <>Suchen Sie nach Begriffen, die mit beginnen </>
                                            :
                                            <>Search for terms starting with </>
                                        } */}
                                        {pageContent.predictive_search_text}
                                        <span style={{fontStyle:'italic'}}>{currentSearch}</span>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center', padding: '10px 0'}}>
                                        <div className="loader"></div>
                                    </div>
                                    </>
                                    : 
                                    <></>
                                }
                                </>

                            }</>
                            
                            </>
                            :
                            <div style={{width:'350px'}}></div>

                        }
                        
                    </div>
                    </>
                }
                
                <div className={classes.navbarLinksContainer}>
                    <div className={`${classes.navbarLinks} `}> 
                        {/* <div
                            className={`${classes.navLink} `}
                            style={{borderBottom: bookDictionary ? '3px solid #dde295': comeFrom === 'basicDictionary' ? '3px solid #068b77': basicDictionary ? '3px solid #dde295':''}}
                        > */}
                            {(() => {
                                if (openDictionary) {
                                    return (
                                        <></>
                                    )
                                } else if (basicDictionary || window.localStorage.getItem("account") === "0") {
                                    return (
                                        <>
                                        <div
                                            className={`${classes.navLink} `}
                                            style={{borderBottom: bookDictionary ? '3px solid #dde295': comeFrom === 'basicDictionary' ? '3px solid #068b77': basicDictionary ? '3px solid #dde295':''}}
                                        >
                                            <Link 
                                                to={`/basicDictionary/${window.localStorage.getItem("termId")}`}
                                                exact={false}    
                                            >
                                                <i 
                                                    style={{
                                                        color: false 
                                                        ? 
                                                        '#dde295' 
                                                        : 
                                                        currentPathName.startsWith("/profile") 
                                                        || currentPathName.startsWith("/changePassword") 
                                                        || currentPathName.startsWith('/confirmChangePassword') 
                                                        || currentPathName.startsWith("/upgradeAccount") 
                                                        || currentPathName.startsWith("/upgradeBookAccount") 
                                                        || currentPathName.startsWith("/sendFeedback") 
                                                        ? '#fdfdfd' : '#dde295'
                                                    }} 
                                                    className='icon-nav icon-dictionary'
                                                >
                                                </i>
                                                <div 
                                                    style={{
                                                        color: false 
                                                        ? 
                                                        '#dde295' 
                                                        : 
                                                        currentPathName.startsWith("/profile") 
                                                        || currentPathName.startsWith("/changePassword") 
                                                        || currentPathName.startsWith('/confirmChangePassword') 
                                                        || currentPathName.startsWith("/upgradeAccount") 
                                                        || currentPathName.startsWith("/upgradeBookAccount") 
                                                        || currentPathName.startsWith("/sendFeedback") 
                                                        ? '#fdfdfd' 
                                                        : '#dde295'
                                                    }}
                                                >
                                                    {pageContent.dictionary_title}
                                                </div>
                                            </Link> 
                                        </div>
                                        </>


                                    )
                                } else {
                                    return (
                                        <>
                                        <div
                                            className={`${classes.navLink} `}
                                            style={{borderBottom: bookDictionary ? '3px solid #dde295': comeFrom === 'basicDictionary' ? '3px solid #068b77': basicDictionary ? '3px solid #dde295':'3px solid #004f3d'}}
                                        >

                                            <Link 
                                                to={`/dictionary/${window.localStorage.getItem("termId")}`}
                                                exact={false}    
                                                
                                            >
                                                <i 
                                                    style={{
                                                        color:bookDictionary 
                                                        ? 
                                                        '#dde295' 
                                                        : 
                                                        currentPathName.startsWith("/profile") 
                                                        || currentPathName.startsWith("/changePassword") 
                                                        || currentPathName.startsWith('/confirmChangePassword') 
                                                        || currentPathName.startsWith("/upgradeAccount") 
                                                        || currentPathName.startsWith("/upgradeBookAccount") 
                                                        || currentPathName.startsWith("/sendFeedback") 
                                                        ? 
                                                        '#fdfdfd' 
                                                        : 
                                                        ''
                                                    }} 
                                                    className='icon-nav icon-dictionary'
                                                >
                                                </i>
                                                <div 
                                                    style={{
                                                        color:bookDictionary 
                                                        ? 
                                                        '#dde295' 
                                                        : 
                                                        currentPathName.startsWith("/profile") 
                                                        || currentPathName.startsWith("/changePassword") 
                                                        || currentPathName.startsWith('/confirmChangePassword') 
                                                        || currentPathName.startsWith("/upgradeAccount") 
                                                        || currentPathName.startsWith("/upgradeBookAccount") 
                                                        || currentPathName.startsWith("/sendFeedback") 
                                                        ? 
                                                        '#fdfdfd' 
                                                        : 
                                                        ''
                                                    }}
                                                >
                                                    {pageContent.dictionary_title}
                                                </div>
                                            </Link> 
                                        </div>

                                        </>
                                    )
                                }
                            })()}
                            
                        {/* </div> */}
                        
                            {
                                openDictionary
                                ?
                                <>


                                <div style={{display:'flex', width:'100%', justifyContent:'space-evenly', height:'70%', alignItems:'center'}}>
                                    <Link 
                                        to={`/login`}  
                                        className={'btn ochre size-auto'} 
                                    >
                                        <i  className='icon-nav icon-signin'/>  
                                        {pageContent.login_button}
                                    </Link>
                                    <Link 
                                        to={`/registration`} 
                                        className={'btn transparent size-auto'}
                                        style={{color:'#BD8F16', border:'1px solid #BD8F16'}}
                                    > 
                                        <i style={{color:'#BD8F16'}} className='icon-nav icon-register'/> 
                                        {pageContent.register_button}
                                    </Link>
                                </div>
                                </>

                                :
                                <>
                                {
                                    basicDictionary
                                    ?
                                    <>
                                    <div
                                        className={`
                                            ${classes.navLink} 
                                            ${currentPathName.startsWith("/profile/") 
                                                || currentPathName.startsWith("/changePassword") 
                                                || currentPathName.startsWith("/confirmChangePassword") 
                                                || currentPathName.startsWith("/upgradeAccount") 
                                                || currentPathName.startsWith("/sendFeedback") 
                                                || currentPathName.startsWith("/upgradeBookAccount") 
                                                || currentPathName.startsWith("/sendFeedback") 
                                                ? 
                                                classes.active 
                                                : 
                                                null}`
                                            } 
                                        style={{borderBottom: bookDictionary ? '3px solid #068b77': comeFrom === 'basicDictionary' ? '3px solid #dde295': basicDictionary ? '3px solid #068b77':'', width: openDictionary?'100%':''}}
                                    >

                                        {/* {'serviceWorker' in navigator && ( */}
                                        <Link
                                            to={`/profile/${id}`}
                                            exact={false}
                                            onClick={() => {
                                                // showNotification();
                                                window.localStorage.setItem("comeFrom", "basicDictionary")
                                                
                                            }}
                                            >
                                            <i 
                                                style={{
                                                    color:bookDictionary  
                                                    ? 
                                                    '#fdfdfd' 
                                                    : 
                                                    currentPathName.startsWith("/profile") 
                                                    || currentPathName.startsWith("/changePassword") 
                                                    || currentPathName.startsWith('/confirmChangePassword') 
                                                    || currentPathName.startsWith("/upgradeAccount") 
                                                    || currentPathName.startsWith("/upgradeBookAccount") 
                                                    || currentPathName.startsWith("/sendFeedback") 
                                                    ? 
                                                    '#dde295'
                                                    :
                                                    '#fdfdfd'
                                                }} 
                                                className='icon-nav icon-profile'
                                            >
                                            </i>
                                            <div 
                                                style={{
                                                    color:bookDictionary  
                                                    ? 
                                                    '#fdfdfd' 
                                                    : 
                                                    currentPathName.startsWith("/profile") 
                                                    || currentPathName.startsWith("/changePassword") 
                                                    || currentPathName.startsWith('/confirmChangePassword') 
                                                    || currentPathName.startsWith("/upgradeAccount") 
                                                    || currentPathName.startsWith("/upgradeBookAccount") 
                                                    || currentPathName.startsWith("/sendFeedback") 
                                                    ? 
                                                    '#dde295'
                                                    :
                                                    '#fdfdfd'
                                                }} 
                                            >
                                                {pageContent.profile_title}
                                            </div> 
                                        </Link>
                                        {/* )} */}
                                    </div>

                                    </>
                                    :
                                    <>
                                     <div
                                        className={`
                                            ${classes.navLink} 
                                            ${currentPathName.startsWith("/profile") 
                                                || currentPathName.startsWith("/changePassword") 
                                                || currentPathName.startsWith("/confirmChangePassword") 
                                                || currentPathName.startsWith("/upgradeAccount") 
                                                || currentPathName.startsWith("/sendFeedback") 
                                                || currentPathName.startsWith("/upgradeBookAccount") 
                                                || currentPathName.startsWith("/sendFeedback")
                                                ? 
                                                classes.active 
                                                : 
                                                null}`
                                            } 
                                        style={{borderBottom: bookDictionary ? '3px solid #004f3d': comeFrom === 'basicDictionary' ? '3px solid #dde295': false ? '3px solid red':'3px solid #dde295', width: openDictionary?'100%':''}}
                                    >

                                        {/* {'serviceWorker' in navigator && ( */}
                                        <Link
                                            to={`/profile/${id}`}
                                            exact={false}
                                            onClick={() => {
                                                // showNotification();
                                                if (window.localStorage.getItem("account") == 2) {
                                                    window.localStorage.setItem("comeFrom", "bookDictionary")
                                                }
                                            }}
                                            >
                                            <i 
                                                style={{
                                                    color:bookDictionary 
                                                    ? 
                                                    '#fdfdfd' 
                                                    : 
                                                    currentPathName.startsWith("/profile") 
                                                    || currentPathName.startsWith("/changePassword") 
                                                    || currentPathName.startsWith('/confirmChangePassword') 
                                                    || currentPathName.startsWith("/upgradeAccount") 
                                                    || currentPathName.startsWith("/upgradeBookAccount") 
                                                    || currentPathName.startsWith("/sendFeedback") 
                                                    ? '#dde295'
                                                    :
                                                    ''
                                                }} 
                                                className='icon-nav icon-profile'
                                            >
                                            </i>
                                            <div 
                                                style={{
                                                    color:bookDictionary 
                                                    ? 
                                                    '#fdfdfd' 
                                                    : 
                                                    currentPathName.startsWith("/profile") 
                                                    || currentPathName.startsWith("/changePassword") 
                                                    || currentPathName.startsWith('/confirmChangePassword') 
                                                    || currentPathName.startsWith("/upgradeAccount") 
                                                    || currentPathName.startsWith("/upgradeBookAccount") 
                                                    || currentPathName.startsWith("/sendFeedback") 
                                                    ? 
                                                    '#dde295' 
                                                    : 
                                                    ''
                                                }}
                                            >
                                                {pageContent.profile_title}
                                            </div> 
                                        </Link>
                                        {/* )} */}
                                    </div>

                                    </>
                                }
                                
                                </>
                            }
                            
                            
                        {/* </div> */}
                        <div
                            className={`${classes.navLink}`}
                            style={{color:openDictionary?'#BD8F16':basicDictionary?'white':'white', borderBottom:openDictionary?'3px #E0F2F0 solid':basicDictionary || comeFrom === 'basicDictionary' ?'3px #068b77 solid':comeFrom === 'bookDictionary' ?'3px #004f3d solid':'3px #004f3d solid' }}
                        >
                            
                            <Link
                                to={`/`}
                                exact={true}
                            >
                                <i style={{color:openDictionary?'#BD8F16':basicDictionary?'white':'white'}} className='icon-nav icon-website'></i>
                                <div 
                                    style={{
                                        color: 
                                        bookDictionary 
                                        ? 
                                        'white' 
                                        : 
                                        basicDictionary 
                                        || currentPathName.startsWith("/profile") 
                                        || currentPathName.startsWith("/changePassword") 
                                        || currentPathName.startsWith('/confirmChangePassword') 
                                        || currentPathName.startsWith("/upgradeAccount") 
                                        || currentPathName.startsWith("/upgradeBookAccount") 
                                        || currentPathName.startsWith("/sendFeedback") 
                                        ? 
                                        '#fdfdfd' 
                                        : 
                                        ''
                                    }} 
                                >
                                    {pageContent.website_title}
                                </div> 
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
            <div  className={classes.ResponsiveNavbarContainer}>
                <nav style={{position:'fixed', width:'100vw', bottom:'0'}} className={''}>
                    <div className='position-absolute app-nav-box'>
                        <div style={{justifyContent:openDictionary?'space-evenly':'space-between'}} className={`${classes.navbarLinks} `}> 
                            <div className={`${classes.navLink} ${currentPathName.startsWith("/dictionary/") || currentPathName.startsWith("/openDictionary/") || currentPathName.startsWith("/basicDictionary/") ? classes.active : null}`}>
                                <Link 
                                    to={openDictionary ? `/openDictionary/${window.localStorage.getItem("termId")}`: basicDictionary ? `/basicDictionary/${window.localStorage.getItem("termId")}` : `/dictionary/${window.localStorage.getItem("termId")}` }
                                    exact={false}
                                >
                                    <i className='icon-dictionary'></i>
                                    <span>
                                        {pageContent.dictionary_title}
                                    </span>
                                </Link>      
                            </div>
                            {
                                openDictionary
                                ?
                                <>
                                    
                                </>
                                :
                                <>
                                <div 
                                    className={
                                        `${classes.navLink} 
                                        ${currentPathName.startsWith("/profile/") 
                                            || currentPathName.startsWith("/changePassword")
                                            || currentPathName.startsWith('/confirmChangePassword') 
                                            || currentPathName.startsWith("/upgradeAccount") 
                                            || currentPathName.startsWith("/sendFeedback") 
                                            || currentPathName.startsWith("/upgradeBookAccount") 
                                            || currentPathName.startsWith("/sendFeedback") 
                                            ? 
                                            classes.active 
                                            : 
                                            null
                                        }`} 
                                >
                                    <Link 
                                        to={`/profile/${id}`}
                                        onClick={() => {
                                            // showNotification();
                                            if (window.localStorage.getItem("account") == 2) {
                                                window.localStorage.setItem("comeFrom", "bookDictionary")
                                            } else {
                                                window.localStorage.setItem("comeFrom", "basicDictionary")
                                            }
                                        }}
                                        exact={true}
                                    >
                                        <i className='icon-profile'></i>
                                        <span>
                                            {pageContent.profile_title}
                                        </span>
                                    </Link>
                                </div>
                                </>
                            }
                            
                            <div className={`${classes.navLink}`}>
                                <Link
                                    to={`/`}
                                    exact={true}
                                >
                                    <i className='icon-website'></i>
                                    <span>{pageContent.website_link}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            
        </header>
        </>
    )
}

const DELogo = () => {
    return(
        <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.47 19.93C5.25 19.93 1.01 15.68 1 10.47C1 5.25 5.25 1 10.47 1C15.69 1 19.94 5.25 19.94 10.47C19.94 15.69 15.69 19.94 10.47 19.94V19.93ZM10.47 1.99001C5.8 1.99001 2 5.79001 2 10.46C2 15.13 5.8 18.92 10.47 18.92C15.14 18.92 18.94 15.12 18.94 10.45C18.94 5.78001 15.14 1.98001 10.47 1.98001V1.99001Z" fill="#B66A00"/>
            <path d="M22.35 23.22C22.22 23.22 22.09 23.17 22 23.07L16.43 17.5C16.23 17.3 16.23 16.99 16.43 16.79C16.63 16.59 16.94 16.59 17.14 16.79L22.71 22.36C22.91 22.56 22.91 22.87 22.71 23.07C22.61 23.17 22.48 23.22 22.36 23.22H22.35Z" fill="#B66A00"/>
            <path d="M6.62451 15H4.63135L4.64404 14.1367H6.62451C7.28467 14.1367 7.84326 13.995 8.30029 13.7114C8.75732 13.4237 9.10433 13.0238 9.34131 12.5117C9.58252 11.9954 9.70312 11.3966 9.70312 10.7153V10.0361C9.70312 9.50293 9.6333 9.02686 9.49365 8.60791C9.35824 8.18896 9.16146 7.83138 8.90332 7.53516C8.64518 7.23893 8.33203 7.01465 7.96387 6.8623C7.5957 6.70573 7.17887 6.62744 6.71338 6.62744H4.59326V5.75781H6.71338C7.31006 5.75781 7.85384 5.85726 8.34473 6.05615C8.83561 6.25505 9.2609 6.54281 9.62061 6.91943C9.98031 7.29606 10.2554 7.74886 10.4458 8.27783C10.6405 8.80257 10.7378 9.3929 10.7378 10.0488V10.7153C10.7378 11.3713 10.6405 11.9637 10.4458 12.4927C10.2554 13.0174 9.97819 13.4681 9.61426 13.8447C9.25456 14.2171 8.8208 14.5028 8.31299 14.7017C7.80941 14.9006 7.24658 15 6.62451 15ZM5.1582 5.75781V15H4.11084V5.75781H5.1582Z" fill="#B66A00"/>
            <path d="M16.9316 14.1367V15H11.917V14.1367H16.9316ZM12.1582 5.75781V15H11.1108V5.75781H12.1582ZM16.2842 9.80127V10.6646H11.917V9.80127H16.2842ZM16.8809 5.75781V6.62744H11.917V5.75781H16.8809Z" fill="#B66A00"/>
        </svg>
    )
}

const FRLogo = () => {
    return(
        <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.47 19.93C5.25 19.93 1.01 15.68 1 10.47C1 5.25 5.25 1 10.47 1C15.69 1 19.94 5.25 19.94 10.47C19.94 15.69 15.69 19.94 10.47 19.94V19.93ZM10.47 1.99001C5.8 1.99001 2 5.79001 2 10.46C2 15.13 5.8 18.92 10.47 18.92C15.14 18.92 18.94 15.12 18.94 10.45C18.94 5.78001 15.14 1.98001 10.47 1.98001V1.99001Z" fill="#B66A00"/>
            <path d="M22.35 23.22C22.22 23.22 22.09 23.17 22 23.07L16.43 17.5C16.23 17.3 16.23 16.99 16.43 16.79C16.63 16.59 16.94 16.59 17.14 16.79L22.71 22.36C22.91 22.56 22.91 22.87 22.71 23.07C22.61 23.17 22.48 23.22 22.36 23.22H22.35Z" fill="#B66A00"/>
            <path d="M5.1582 5.75781V15H4.11084V5.75781H5.1582ZM9.20801 9.95361V10.8232H4.89795V9.95361H9.20801ZM9.83643 5.75781V6.62744H4.89795V5.75781H9.83643Z" fill="#B66A00"/>
            <path d="M11.1045 5.75781H14.145C14.8179 5.75781 15.3913 5.86361 15.8652 6.0752C16.3392 6.28255 16.7031 6.59147 16.957 7.00195C17.2109 7.41243 17.3379 7.91602 17.3379 8.5127C17.3379 8.9401 17.249 9.32943 17.0713 9.68066C16.8978 10.0319 16.6502 10.3281 16.3286 10.5693C16.007 10.8105 15.6283 10.984 15.1924 11.0898L14.8813 11.2104H11.8726L11.8599 10.3472H14.2339C14.6909 10.3472 15.0718 10.2646 15.3765 10.0996C15.6812 9.93034 15.9097 9.70605 16.062 9.42676C16.2186 9.14746 16.2969 8.84277 16.2969 8.5127C16.2969 8.1276 16.2186 7.79541 16.062 7.51611C15.9054 7.23258 15.6663 7.01465 15.3447 6.8623C15.0273 6.70573 14.6274 6.62744 14.145 6.62744H12.1519V15H11.1045V5.75781ZM16.627 15L14.3228 10.8359L15.4272 10.8232L17.7441 14.9238V15H16.627Z" fill="#B66A00"/>
        </svg>
    )
}

const ITLogo = () => {
    return(
        <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.47 19.93C5.25 19.93 1.01 15.68 1 10.47C1 5.25 5.25 1 10.47 1C15.69 1 19.94 5.25 19.94 10.47C19.94 15.69 15.69 19.94 10.47 19.94V19.93ZM10.47 1.99001C5.8 1.99001 2 5.79001 2 10.46C2 15.13 5.8 18.92 10.47 18.92C15.14 18.92 18.94 15.12 18.94 10.45C18.94 5.78001 15.14 1.98001 10.47 1.98001V1.99001Z" fill="#B66A00"/>
            <path d="M22.35 23.22C22.22 23.22 22.09 23.17 22 23.07L16.43 17.5C16.23 17.3 16.23 16.99 16.43 16.79C16.63 16.59 16.94 16.59 17.14 16.79L22.71 22.36C22.91 22.56 22.91 22.87 22.71 23.07C22.61 23.17 22.48 23.22 22.36 23.22H22.35Z" fill="#B66A00"/>
            <path d="M7.28516 5.75781V15H6.23145V5.75781H7.28516Z" fill="#B66A00"/>
            <path d="M13.3989 5.75781V15H12.3706V5.75781H13.3989ZM16.4521 5.75781V6.62744H9.32373V5.75781H16.4521Z" fill="#B66A00"/>
        </svg>
    )
}

const ENLogo = () => {
    return(
        <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.47 19.93C5.25 19.93 1.01 15.68 1 10.47C1 5.25 5.25 1 10.47 1C15.69 1 19.94 5.25 19.94 10.47C19.94 15.69 15.69 19.94 10.47 19.94V19.93ZM10.47 1.99001C5.8 1.99001 2 5.79001 2 10.46C2 15.13 5.8 18.92 10.47 18.92C15.14 18.92 18.94 15.12 18.94 10.45C18.94 5.78001 15.14 1.98001 10.47 1.98001V1.99001Z" fill="#B66A00"/>
            <path d="M22.35 23.22C22.22 23.22 22.09 23.17 22 23.07L16.43 17.5C16.23 17.3 16.23 16.99 16.43 16.79C16.63 16.59 16.94 16.59 17.14 16.79L22.71 22.36C22.91 22.56 22.91 22.87 22.71 23.07C22.61 23.17 22.48 23.22 22.36 23.22H22.35Z" fill="#B66A00"/>
            <path d="M8.93164 14.1367V15H3.91699V14.1367H8.93164ZM4.1582 5.75781V15H3.11084V5.75781H4.1582ZM8.28418 9.80127V10.6646H3.91699V9.80127H8.28418ZM8.88086 5.75781V6.62744H3.91699V5.75781H8.88086Z" fill="#B66A00"/>
            <path d="M17.1313 5.75781V15H16.0776L11.1646 7.56689V15H10.1108V5.75781H11.1646L16.0903 13.2036V5.75781H17.1313Z" fill="#B66A00"/>
        </svg>
    )
}