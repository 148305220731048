import React, { useEffect, useState } from "react";
import classes from './SendFeedback.module.css';
import AppHeader from '../Components/AppHeader';
import AppFooter from '../Components/AppFooter';
import CheckboxInput from '../Components/CheckboxInput'
import StarsInput from "../Components/StarsInput";
import TextArea from "../Components/TextArea";
import FeedbackToast from "../Components/FeedbackToast";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function SendFeedback(props) {

    const [isLoadingUserFeedback, setIsLoadingUserFeedback] = useState(false)
    const [feedback, setFeedback] = useState({
        iduser: window.localStorage.getItem("id"),
        comment: "", 
        rate: "0",
        consent: "1",

    })
    const initError = {
        exists: false,
        helperText: null,
    };
    const [starsInputError, setStarsInputError] = useState(initError);
    const [feedbackError, setFeedbackError] = useState(initError);
    const [consent, setConsent] = useState(true);
    const [toastProperties, setToastProperties] = useState([]);
    const [feedbackToastVisible, setFeedbackToastVisible] = useState(false)
    const [allUserFeedback, setAllUserFeedback] = useState([])
    const [comeFrom, setComeFrom] = useState(window.localStorage.getItem("comeFrom"))

    useEffect(() => {
        
        if (window.location.pathname.startsWith('/sendFeedback')) {
            document.getElementById("body").style.overflowY = "hidden";  
        } else {
            document.getElementById("body").style.overflowY = "scroll";
        }
        setIsLoadingUserFeedback(true)
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/commentsCMS/`, { 
            method: 'GET' 
        })
        .then(data => data.json())
        .then(data => {
            
            Object.values(data.users).map(val => {
                if(val.id_user == window.localStorage.getItem("id")) {
                    setAllUserFeedback((prevState) => {
                        return ([
                            ...prevState,
                            val
                        ])
                    })
                }
            })
            setIsLoadingUserFeedback(false)
            
            
        })
    }, [])


    function showNotification() {
        Notification.requestPermission().then((result) => {
          if (result === "granted") {
            navigator.serviceWorker.ready.then((registration) => {
            // setTimeout(() => {
                registration.showNotification("Feedback sended", {
                    body: "Thank for your feedback",
                    icon: "../images/touch/chrome-touch-icon-192x192.png",
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    tag: "vibration-sample",
                    requireInteraction: true,
                    renotify: true,
                  }); 
            // }, 1000);
              

            });
          }
        });
    }

    const handleChangeComment = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;

        setFeedback((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmitComment = () => {

        if (feedback.rate === "0" && feedback.comment.length > 10) {
            setStarsInputError({
                exists: true,
                helperText: props.language === 'de' ? "Wählen Sie eine Anzahl Sterne" : "Choose a number of stars",
            });
        } 
        else if (feedback.comment.length < 10 && feedback.rate !== "0") {
            setFeedbackError({
                exists: true,
                helperText: props.language === 'de' ? "Der Kommentar muss mehr als 10 Zeichen lang sein": "The comment have to be more than 10 characters long",
            });
        }
        else if (feedback.comment.length < 10 && feedback.rate === "0") {
            setStarsInputError({
                exists: true,
                helperText: props.language=== 'de'? "Wählen Sie eine Anzahl Sterne": "Choose a number of stars",
            });
            setFeedbackError({
                exists: true,
                helperText: props.language === 'de'? "Der Kommentar muss mehr als 10 Zeichen lang sein" : "The comment have to be more than 10 characters long",
            });
        }
        else {
            setStarsInputError({
                exists: false,
                helperText: null,
            });
            setFeedbackError({
                exists: false,
                helperText: null,
            });
            const requestOptions = {
                method: 'POST', 
                body: JSON.stringify(feedback),
       
            }
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/savecomment/`, requestOptions)
                .then((data) => {
                    console.log(data)
                    
                    if (data.status === 200) {
                        setToastProperties({
                            description: `YOUR COMMENT HAS BEEN SENT`,
                            borderColor: '#8DD037',
                            icon: 'icon-success'
                        })
                        setFeedbackToastVisible(true)
                        setInterval(() => {
                            setFeedbackToastVisible(false)
                            window.location.reload()
                        }, 5000)
                    } else if (data.status !== 200) {
                        setToastProperties({
                            description: `THERE IS A PROBLEM, TRY AGAIN LATER`,
                            borderColor: '#f33757',
                            icon: 'icon-warning'
                        })
                        setFeedbackToastVisible(true)
                        setInterval(() => {
                            setFeedbackToastVisible(false)
                            window.location.reload()
                        }, 5000)
                    }
                })
        
        }

        
     
    }

    return(
        <>
            <AppHeader 
                currentPathName={props.location.pathname}
                appLanguage={props.language}
                comeFrom={comeFrom}
            />
            <FeedbackToast 
                position="top-right" 
                toastList={toastProperties}
                visible={feedbackToastVisible}
            />
            <section className={classes.SendFeedbackContainer}>
       
                <div className={classes.SendFeedback}>
                    <h1 className={classes.SendFeedbackTitle}>  
                        {
                            props.language === 'de'
                            ?
                            <>Ihr Feedback ist uns wichtig</>
                            :
                            <>Your feedback is important to us</>
                        }
                    </h1>
                    <div className={classes.StarsRaiting}>
                        <StarsInput 
                            language={props.language}
                            rate={feedback.rate}
                            changeRate={val => {
                                setFeedback((prevState) => ({
                                    ...prevState,
                                    "rate": `${val}`,
                                }))
                                setStarsInputError({
                                    exists: false,
                                    helperText: null,
                                });

                            }}  
                            changeRateBack={val => {
                                setFeedback((prevState) => ({
                                    ...prevState,
                                    "rate": `${val}`,
                                }))
                                setStarsInputError({
                                    exists: false,
                                    helperText: null,
                                });
                            }}
                            errorDiv = {starsInputError.exists ? "text-danger" : "no-danger"}
                            errorMsg = {starsInputError.helperText}
                        />
                    </div>
                    <div>
                        <p>
                            {
                                props.language === 'de' 
                                ?
                                <>Hinterlassen Sie Ihr Feedback*</>
                                :
                                <>Leave your feedback*</>
                            }
                        </p>
                        <TextArea 
                            language={props.language}
                            name="comment" 
                            handleChange={handleChangeComment} 
                            // handleBlur={commentBlurHandler}
                            style={{width:'100%', backgroundColor:'#FDFDFD', height:'150px', resize:'none', border:'2px solid #ccc', borderRadius:'15px', padding:'10px',}}
                            className={feedbackError.exists ? "is-invalid": ""}
                            errorDiv = {feedbackError.exists ? "text-danger" : "no-danger"}
                            errorMsg = {feedbackError.helperText}
                            writtenCharacters ={feedback.comment.length}
                        />

                        {/* <textarea name="comment" onChange={handleChangeComment} style={{width:'100%', backgroundColor:'#FDFDFD', height:'150px', resize:'none', border:'2px solid #ccc', borderRadius:'15px'}}/> */}
                        <CheckboxInput
                            
                            value={consent}
                            selected={true}
                            text={props.language === 'de'? "Ich bin damit einverstanden, dass mein Kommentar veröffentlicht wird" : "I consent to make my comment public"}
                            onChange={(val) => {
                                setConsent(!val); 
                                if (consent === true) {
                                    setFeedback((prevState) => ({
                                        ...prevState,
                                        "consent": "0",
                                    }));
                                }else{
                                    setFeedback((prevState) => ({
                                        ...prevState,
                                        "consent": "1",
                                    }));
                                }
                        
                            }}
                        />
                    </div>
                    <div className={classes.sendButton}>
                        <button onClick={handleSubmitComment} className={'btn ochre'}>
                            
                            {
                                props.language === 'de'
                                ?
                                <>KOMMENTAR SENDEN</>
                                :
                                <>SEND COMMENT</>
                            }
                        </button>
                    </div>
                </div>
                <div className={classes.prevFeedback} >
                    <h1 style={{margin:'0'}} className={classes.SendFeedbackTitle}>
                        
                        {
                            props.language === 'de'
                            ?
                            <>Alle meine Kommentare</>
                            :
                            <>All my comments</>
                        }
                    </h1>
                    <div style={{height:'70vh', overflowY:'auto', fontFamily:'Roboto'}}>
                    {
                        isLoadingUserFeedback
                        ?
                        <>
                        <SkeletonTheme
                                baseColor="#E1E2E1"
                                highlightColor="#FDFDFD"
                                borderRadius="10"
                                duration={2}
                            >
                                {[1,2,3,4,5].map(() => (
                                    <div style={{width:'unset', margin:'50px 50px 50px 0px'}} className='box_term'>
                                    <Skeleton  />
                                    <Skeleton  />
                                    <Skeleton />
                                    <Skeleton  />
                                    </div>
                                ))}
                                
                                
                            </SkeletonTheme>
                        </>
                        :
                        <>
                        {allUserFeedback.toReversed().map(f => 
                        <div style={{margin:'50px 50px 50px 0'}}>
                            {Array.from(Array(f.stars).keys()).map(i => {
                                return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z" fill="#F3BF4C"/>
                                </svg>
                                
                            })}
                            <div style={{width:'unset'}}>{f.feedback}</div>
                            <CheckboxInput
                                value={
                                    f.consent === 1 ? true : false
                                }
                                selected={true}
                                text={props.language === 'de'? "Ich bin damit einverstanden, dass mein Kommentar veröffentlicht wird" : "I consent to make my comment public"}
                                disabled={true}
                            />
                            <hr/>
                        </div>
                    )}
                        </>
                    }
                    
                    </div>
                </div>
            </section>

            <AppFooter language={props.language} />
        </>
    )
}