import React, {useEffect, useState} from "react";
import classes from "./UpgradeBookAccount.module.css"
import AppHeader from "../Components/AppHeader";
import AppFooter from '../Components/AppFooter';
import RadioInput from '../Components/RadioInput'
import Input from "../Components/Input";
import Toast from "../Components/Toast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

            

export default function UpgradeBookAccount(props) {
   


    const [mobile, setMobile] = useState(false)
    const [selected, setSelected] = useState('type');
    const [code, setCode] = useState('')

    const [toastVisible, setToastVisible] = useState(false)
    const [toastProperties, setToastProperties] = useState([])

    const initError = {
        exists: false,
        helperText: null,
    };
    const [codeError, setCodeError] = useState(initError);
    const [enteredCodeTouched, setEnteredCodeTouched] = useState(false);
    const [comeFrom, setComeFrom] = useState(window.localStorage.getItem("comeFrom"))

    useEffect(() => {

        if (!code && enteredCodeTouched) {
            setCodeError({
              exists: true,
              helperText: "Write the Number series of the book",
            });
        } else {
            setCodeError({
              exists: false,
              helperText: null,
            });
        }
    }, [])
    const userBlurHandler = (e) => {
        setEnteredCodeTouched(true);
    };

    const handleChange = (evt) => {
        let value = evt.target.value;

        setCode(value);
        
    };

    const updateBookAccount = () => {
        let accountInfo = {
            id: window.localStorage.getItem("id"),
            token: window.localStorage.getItem("jwt"),
            code: code
        }
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/validatebookcode/`, {
            method: "POST",
            body: JSON.stringify(accountInfo),
        })
        .then((response) => {response.json(), console.log(response.status)
            if(response.status === 403){
                setToastVisible(true)
                setInterval(() => {
                    setToastVisible(false)
                }, 15000)
                setToastProperties({
                    description: props.language === 'de' ? `IHRE SITZUNG IST ABGELAUFEN. BITTE MELDEN SIE SICH AB UND ERNEUT AN, UM DIE GEWÜNSCHTEN ÄNDERUNGEN VORZUNEHMEN.`: `YOUR SESSION EXPIRED, PLEASE LOGOUT AND LOGIN AGAIN TO MAKE THE DESIRED CHANGES.`,
                    borderColor: '#f33757',
                    icon: 'icon-warning'
                })
            } else if (response.status === 401) {
                setCodeError({
                    exists: true,
                    helperText: props.language === "de" ? "Falscher Code": "Wrong code",
                });
            } else if(response.status === 200) {
                props.history.push({
                    pathname: "/dictionary/0",
                });
            }
        })
    }


    return(
        <>
        <AppHeader 
            currentPathName={props.location.pathname}
            mobile = {mobile}
            comeFrom={comeFrom}
            appLanguage={props.language}
        />
        <Toast
            toastList={toastProperties}
            position="top-right" 
            visible={toastVisible}
            language={props.language}
        />
        <section className={classes.upgradeBookAccountContainer}>
            <canvas id="confetti"></canvas>
            <div style={{maxWidth:'1400px', margin:'auto'}} >
            <Link
                className={'none'}
                to={`/upgradeAccount`}
            >
                <i style={{position:'absolute', transform: 'rotateY(180deg)'}} class="icon icon-arrow"></i>
            </Link>
            <div className={classes.upgradeBookAccountAbout}>
                <h1 className={classes.upgradeBookAccountAboutTitle}>
                    {
                        props.language === "de"
                        ?
                        <>Premium-Zugang mit Buch</>
                        :
                        <>Premium access with book</>
                    }
                </h1>
                <p className={classes.upgradeBookAccountAboutText}>
                    {
                        props.language === "de"
                        ?
                        <>Bitte scannen Sie den im Buch bereitgestellten Code oder fügen Sie die Zahlenreihe ein</>
                        :
                        <>Please scan the code provided in the book or insert the number series</>
                    }
                </p>
            </div>
            <div className={classes.options}>
                {/* <div>
                    <RadioInput 
                        value={'scan'}
                        selected={selected}
                        text="Scan the code"
                        onChange={(val) => {setSelected(val);}} 
                    />
                    <button
                        className={'btn ochre'}
                        disabled
                    >
                        <svg style={{marginRight:'15px'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.513509 6.34672C0.326842 6.34672 0.180176 6.20005 0.180176 6.01339V0.840047C0.180176 0.653381 0.326842 0.506714 0.513509 0.506714H5.68681C5.87348 0.506714 6.02014 0.653381 6.02014 0.840047C6.02014 1.02671 5.87348 1.17338 5.68681 1.17338H0.846842V6.01339C0.846842 6.20005 0.700176 6.34672 0.513509 6.34672Z" fill="#FDFDFD"/>
                            <path d="M15.1268 6.34672C14.9401 6.34672 14.7935 6.20005 14.7935 6.01339V1.17338H9.95345C9.76678 1.17338 9.62012 1.02671 9.62012 0.840047C9.62012 0.653381 9.76678 0.506714 9.95345 0.506714H15.1268C15.3135 0.506714 15.4601 0.653381 15.4601 0.840047V6.01339C15.4601 6.20005 15.3135 6.34672 15.1268 6.34672Z" fill="#FDFDFD"/>
                            <path d="M5.69368 15.7067H0.520345C0.333678 15.7067 0.187012 15.56 0.187012 15.3734V10.2C0.187012 10.0134 0.333678 9.8667 0.520345 9.8667C0.707012 9.8667 0.853678 10.0134 0.853678 10.2V15.04H5.69368C5.88035 15.04 6.02702 15.1867 6.02702 15.3734C6.02702 15.56 5.88035 15.7067 5.69368 15.7067Z" fill="#FDFDFD"/>
                            <path d="M15.1268 15.7067H9.95345C9.76678 15.7067 9.62012 15.56 9.62012 15.3734C9.62012 15.1867 9.76678 15.04 9.95345 15.04H14.7935V10.2C14.7935 10.0134 14.9401 9.8667 15.1268 9.8667C15.3135 9.8667 15.4601 10.0134 15.4601 10.2V15.3734C15.4601 15.56 15.3135 15.7067 15.1268 15.7067Z" fill="#FDFDFD"/>
                        </svg>

                        SCAN CODE
                    </button>
                </div> */}
               
                <div style={{display:'grid', justifyItems:'center'}}>
                    {/* <RadioInput 
                        value={'type'}
                        selected={selected}
                        text="Or insert the number series"
                        onChange={(val) => {setSelected(val);}}
                    /> */}
                    {/* <input type="text" onChange={handleChange}/> */}
                    <Input 
                                                            
                        title = {"Number series"}
                        type = {"text"}
                        // name = {"txt"}
                        placeholder = {props.language === "de" ? "Zahlenreihe": "Number series"}
                        // value={credentials.txt}
                        handleChange={handleChange}
                        handleBlur={userBlurHandler}
                        className={codeError.exists ? "is-invalid": ""}
                        errorDiv = {codeError.exists ? "text-danger" : "no-danger"}
                        errorMsg = {codeError.helperText}
                    />
                    
                    <button onClick={updateBookAccount} style={{marginTop:'15px'}} className={'btn ochre'}>
                        {
                            props.language === "de"
                            ?
                            <>Aktualisierung</>
                            :
                            <>Upgrade</>
                        }
                    </button>
                </div>
                
            </div>
            <div style={{textAlign:'center'}}>
                <a className="link">
                    {
                        props.language === "de"
                        ?
                        <>Ich habe das Buch nicht</>
                        :
                        <>I DON'T HAVE THE BOOK</>
                    }
                </a>
            </div>
            </div>
            
        </section>
        <AppFooter language={props.language} />
        </>
    )
}